import http from "../../../../services/http-common";

class AdministrationService {
  // eslint-disable-next-line class-methods-use-this
  saveMaterial(data: any, endPoint: string, nameProp: string,typeData:string) {
    let obj = {
      [nameProp]: data.name,
    };

    if (data.deadline !== "" && data.cost_Rework === "") {
      obj = { ...obj, days_Deadline: data.deadline };
    }
    if (data.cost_Rework !== "") {
      obj = { ...obj, deadline_Rework: data.deadline };
      obj = { ...obj, cost_Rework: data.cost_Rework };
    }

    if (data.phone_Company !== "" && data.whatsapp_Company !== "" && data.initials_Company !== "") {
      obj = {
        ...obj,
        phone_Company: data.phone_Company,
        whatsapp_Company: data.whatsapp_Company,
        initials_Company: data.initials_Company,
      };
    }
    if(typeData){
      obj = { ...obj, type_Message: typeData };
    }
    return http.post(`${endPoint}`, obj);
  }

  async delMaterial(endPoint: string, id: any) {
    return http.delete(`${endPoint}/delete/${id}`);
  }

  async changeIps(data: any, update?: boolean) {
    if (update) {
      const values = [
        { ip: data.number_Ip1, id: data.id_Ip1 },
        { ip: data.number_Ip2, id: data.id_Ip2 },
        { ip: data.number_Ip3, id: data.id_Ip3 },
        { ip: data.number_Ip4, id: data.id_Ip4 },
      ];

      values.forEach((item: any, index: number) => {
        return http.put(`ip/update/${item.id}`, {
          number_Ip: item.ip,
          idCompany_Ip: index === 0 ? "4" : index === 1 ? "3" : "2",
        });
      });
    } else {
      const values = [data.number_Ip1, data.number_Ip2, data.number_Ip3, data.number_Ip4];
      values.forEach((item: any, index: number) => {
        return http.post(`ip/`, {
          number_Ip: item,
          idCompany_Ip: index === 0 ? "4" : index === 1 ? "3" : "2",
        });
      });
    }
  }

  getIp() {
    return http.get(`ip/find`);
  }

  // eslint-disable-next-line class-methods-use-this
  getAll(endPoint: string, typeData?: string) {
    if(typeData)
    {
      console.log('fiuahiusfh')
      return http.get(`${endPoint}/type/${typeData}`);
    }
    return http.get(`${endPoint}/find`);
  }

  getCompany(id:any) {
    return http.get(`/company/${id}`);
  }
  editCompany(id:any,data:any) {
    console.log(data)
    const send = 
      {
        name_Company: data.name_Company ? data.name_Company : '',
        initials_Company: data.initials_Company ? data.initials_Company : '',
        phone_Company: data.phone_Company ? data.phone_Company.replace(/[^0-9]/g, "") : '',
        whatsapp_Company: data.whatsapp_Company ? data.whatsapp_Company.replace(/[^0-9]/g, "") : '',
      }
      console.log(send)
    return http.put(`/company/update/${id}`,send);
  }
}

export default new AdministrationService();
