import * as Yup from "yup";
import { Icon } from "@iconify/react";
import InputMask from "react-input-mask";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import AdministrationDialog from "./AdministrationDialog";
import { fCurrency } from "../../../../utils/formatNumber";
import AlertMessage from "../../../../components/alert/alert";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import edit2Outline from "@iconify/icons-eva/edit-2-outline";
import administrationService from "../services/administrationService";
import plusCircleOutline from "@iconify/icons-eva/plus-circle-outline";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Stack,
  TextField,
  CardActions,
  CardContent,
  Typography,
  Modal,
  Card,
  Grid,
  ListItemIcon,
  Box,
} from "@mui/material";
import CompanyModal from "./CompanyModal";

// import clientService from '../services/clientService';

interface ModalProps {
  title: string;
  open: boolean;
  modalData: any;
  setModalData: any;
  handler: () => void;
  confirmButtonText: string;
}

export default function AdministrationModal(props: ModalProps) {
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [selected, setSelected]: any = useState([]);

  const [isDeleting, setDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [editId, setEditId] = useState();
  const [isAdd, setIsAdd] = useState(false);
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("Nome do produto é obrigatório"),
    deadline: Yup.number().required("O prazo é obrigatório"),
    cost_Rework: Yup.number().required("O custo de retrabalho é obrigatório"),
    phone_Company: Yup.string().required("O custo de retrabalho é obrigatório"),
    whatsapp_Company: Yup.string().required(
      "O custo de retrabalho é obrigatório"
    ),
    initials_Company: Yup.string().required("O prazo é obrigatório"),
  });
  var formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      name: "",
      deadline: "",
      cost_Rework: "",
      initials_Company: "",
      phone_Company: "",
      whatsapp_Company: "",
    },
    onSubmit: () => {},
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: RegisterSchema,
  };

  const formik = useFormik(formikValues);

  const addMaterial = () => {
    administrationService
      .saveMaterial(
        values,
        props.modalData.endPoint,
        props.modalData.nameProp,
        props.modalData.type_Message
      )
      .then(() => {
        resetForm();
        setIsAdd(false);
        setSuccess(true);
        administrationService
          .getAll(props.modalData.endPoint, props.modalData.type_Message)
          .then((data: any) => {
            setSelected(data.data);
          })
          .catch((error: any) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });
  };

  const { errors, touched, values, handleSubmit, getFieldProps, resetForm } =
    formik;

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  useEffect(() => {
    setSelected([]);
    if (props.modalData || updateFlag) {
      administrationService
        .getAll(props.modalData.endPoint, props.modalData.type_Message)
        .then((data: any) => {
          setSelected(data.data);
          setUpdateFlag(false);
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }, [props.modalData, updateFlag]);

  const deleteMaterial = () => {
    administrationService
      .delMaterial(props.modalData.endPoint, deleteId)
      .then(() => {
        setSuccess(true);
        setDeleting(false);
        administrationService
          .getAll(props.modalData.endPoint, props.modalData.type_Message)
          .then((data: any) => {
            setSelected(data.data);
          })
          .catch((error: any) => {
            console.error(error);
          });
      })
      .catch((error) => {
        setDeleting(false);
        console.error(error);
        setError(true);
      });
  };

  const closeModal = () => {
    props.handler();
  };

  function deadline(deadline: number, deadlineRework: number) {
    if (deadline || deadlineRework) {
      let str = "dias";
      if (deadline === 1 || deadlineRework === 1) str = "dia";
      if (props.modalData.days_Deadline || props.modalData?.deadline_Rework)
        return (
          <Typography variant="h6" component="h2">
            Prazo - {deadline}
            {deadlineRework} {str}
          </Typography>
        );
    }
  }

  function deadlineInput(touched: any, errors: any) {
    if (props.modalData?.days_Deadline || props.modalData?.deadline_Rework)
      return (
        <TextField
          fullWidth
          type="number"
          color="secondary"
          variant="standard"
          label="Prazo (numero)"
          {...getFieldProps("deadline")}
          error={Boolean(touched.deadline && errors.deadline)}
          helperText={touched.deadline && errors.deadline}
        />
      );
  }

  function reworkCost(touched: any, errors: any) {
    if (props.modalData?.cost_Rework)
      return (
        <TextField
          fullWidth
          type="number"
          color="secondary"
          variant="standard"
          {...getFieldProps("cost_Rework")}
          label="Custo de retrabalho (numero em reais)"
          error={Boolean(touched.cost_Rework && errors.cost_Rework)}
          helperText={touched.cost_Rework && errors.cost_Rework}
        />
      );
  }
  function reworkText(cost: number) {
    let format;

    if (props.modalData?.cost_Rework) {
      format = fCurrency(cost);
      return (
        <Typography variant="h6" component="h2">
          Custo - {format}
        </Typography>
      );
    }
  }

  function companyForms(touched: any, errors: any) {
    if (
      props.modalData?.phone_Company &&
      props.modalData?.whatsapp_Company &&
      props.modalData?.initials_Company
    )
      return (
        <>
          <InputMask {...getFieldProps("phone_Company")} mask="(99) 9999-9999">
            {() => (
              <TextField
                fullWidth
                type="text"
                color="secondary"
                variant="standard"
                label="Telefone da empresa"
                {...getFieldProps("phone_Company")}
                error={Boolean(touched.phone_Company && errors.phone_Company)}
                helperText={touched.phone_Company && errors.phone_Company}
              />
            )}
          </InputMask>
          <InputMask
            {...getFieldProps("whatsapp_Company")}
            mask="(99)9 9999-9999"
          >
            {() => (
              <TextField
                type="text"
                fullWidth={true}
                color="secondary"
                variant="standard"
                label="Whatsapp da empresa"
                {...getFieldProps("whatsapp_Company")}
                error={Boolean(
                  touched.whatsapp_Company && errors.whatsapp_Company
                )}
                helperText={touched.whatsapp_Company && errors.whatsapp_Company}
              />
            )}
          </InputMask>
          <TextField
            fullWidth
            type="text"
            color="secondary"
            variant="standard"
            label="Letras iniciais"
            {...getFieldProps("initials_Company")}
            inputProps={{ style: { textTransform: "uppercase" } }}
            error={Boolean(touched.initials_Company && errors.initials_Company)}
            helperText={touched.initials_Company && errors.initials_Company}
          />
        </>
      );
  }
  function companyText(phone: string, whatsapp: string, initials: string) {
    if (
      props.modalData?.phone_Company &&
      props.modalData?.whatsapp_Company &&
      props.modalData?.initials_Company
    ) {
      return (
        <>
          <Typography variant="h6" component="h2">
            Telefone - {phone}
          </Typography>
          <Typography variant="h6" component="h2">
            Whatsapp - {whatsapp}
          </Typography>
          <Typography variant="h6" component="h2">
            Iniciais da empresa - {initials}
          </Typography>
        </>
      );
    }
  }

  return (
    <>
      <CompanyModal
        open={companyModalOpen}
        id={editId}
        handler={() => {
          setUpdateFlag(true);
          setCompanyModalOpen(false);
        }}
      />
      <AdministrationDialog
        id={props.modalData?.id}
        open={isDeleting}
        title="Remover Item"
        action={() => deleteMaterial()}
        cancelButtonText="Cancelar"
        confirmButtonText="Remover"
        handler={() => setDeleting(false)}
        description="Tem certeza que deseja remover este item?"
      />
      <AdministrationDialog
        id={props.modalData?.id}
        open={isAdd}
        title="Adicionar Item"
        action={() => addMaterial()}
        cancelButtonText="Cancelar"
        confirmButtonText="Adicionar"
        handler={() => setIsAdd(false)}
        description="Tem certeza que deseja adicionar este item?"
      />
      <Modal
        open={props.open}
        onClose={() => {
          formik.resetForm();
          props.handler();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card
          sx={{ width: ["90vw", "30vw"], maxHeight: "90vh", overflow: "auto" }}
        >
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <CardContent sx={{ paddingBottom: "0px" }}>
                <Typography variant="h4" color="#004A78">
                  {props.modalData?.name}
                </Typography>
                <Typography variant="body1" marginBottom={2} component="h2">
                  Você tem {selected.length} {props.modalData?.nameWrite}{" "}
                  cadastrados
                </Typography>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" color="#004A78">
                    Adicionar novo
                  </Typography>
                  <Box
                    style={{
                      marginBottom: 5,
                      borderRadius: "8px",
                      padding: "0 24px 20px 30px",
                      border: "1px solid #004A78",
                    }}
                  >
                    <Grid item container alignItems="center">
                      <Grid item xs={9}>
                        <TextField
                          fullWidth
                          type="text"
                          label={
                            props.modalData?.nameWrite
                              .charAt(0)
                              .toUpperCase(1) +
                            props.modalData?.nameWrite.slice(1)
                          }
                          color="secondary"
                          variant="standard"
                          {...getFieldProps("name")}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                        />
                        {deadlineInput(touched, errors)}
                        {reworkCost(touched, errors)}
                        {companyForms(touched, errors)}
                      </Grid>
                      <Grid item xs={2}>
                        {" "}
                      </Grid>
                      <Grid item xs={1} sx={{ paddingTop: 4 }}>
                        <ListItemIcon onClick={() => setIsAdd(true)}>
                          <Icon
                            icon={plusCircleOutline}
                            width={24}
                            height={24}
                          />
                        </ListItemIcon>
                      </Grid>
                    </Grid>
                  </Box>
                  <Typography variant="h6" color="#004A78" gutterBottom>
                    Lista de adicionados
                  </Typography>
                  <Box
                    style={{
                      height: "30vh",
                      padding: "10px",
                      overflow: "visible",
                      overflowY: "scroll",
                    }}
                  >
                    {selected.map((row: any) => {
                      const {
                        id,
                        name,
                        days_Deadline,
                        deadline_Rework,
                        cost_Rework,
                        phone_Company,
                        whatsapp_Company,
                        initials_Company,
                      } = row;
                      return (
                        <Box
                          key={id}
                          style={{
                            padding: "10px",
                            marginBottom: 5,
                            borderRadius: "8px",
                            border: "1px solid #004A78",
                            backgroundColor: "#afccde",
                          }}
                        >
                          <Grid container>
                            <Grid item container alignItems="center">
                              <Grid item xs={11}>
                                <Typography
                                  variant="h6"
                                  component="h2"
                                  key={id}
                                >
                                  {name}
                                </Typography>
                                {reworkText(cost_Rework)}
                                {deadline(days_Deadline, deadline_Rework)}
                                {companyText(
                                  phone_Company,
                                  whatsapp_Company,
                                  initials_Company
                                )}
                              </Grid>
                              <Grid item xs={1} sx={{ paddingTop: 0.7 }}>
                                <ListItemIcon
                                  onClick={() => {
                                    if (
                                      props.modalData?.phone_Company &&
                                      props.modalData?.whatsapp_Company &&
                                      props.modalData?.initials_Company
                                    ) {
                                      setCompanyModalOpen(true);
                                      setEditId(id);
                                    } else {
                                      setDeleting(true);
                                      setDeleteId(id);
                                    }
                                  }}
                                >
                                  <Icon
                                    icon={
                                      props.modalData?.phone_Company &&
                                      props.modalData?.whatsapp_Company &&
                                      props.modalData?.initials_Company
                                        ? edit2Outline
                                        : trash2Outline
                                    }
                                    width={24}
                                    height={24}
                                  />
                                </ListItemIcon>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })}
                  </Box>
                </Stack>
              </CardContent>
              <CardActions>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  onClick={closeModal}
                  sx={{
                    m: 1,
                    height: "48px",
                    borderRadius: "30px",
                    backgroundColor: "#CDCDCD",
                    color: "#004A78",
                    "&:hover": {
                      bgcolor: "#004A78",
                      color: "#FEFEFE",
                    },
                  }}
                  autoFocus
                >
                  Fechar
                </LoadingButton>
              </CardActions>
            </Form>
          </FormikProvider>
        </Card>
      </Modal>
      {showSuccess && (
        <AlertMessage open={true} message="Material atualizado com sucesso!" />
      )}
      {showError && (
        <AlertMessage open={true} message="Erro ao efetuar atualização" />
      )}
    </>
  );
}
