import { useState, useEffect } from "react";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Card,
  Grid,
  Radio,
  TextField,
  CardHeader,
  Container,
  CardContent,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

import ProfilePhoto from "../../../components/ProfilePhoto";

export const COMPANIES = [
  { name: "PA - Porto Alegre", value: "PA" },
  { name: "NE - Fortaleza", value: "NE" },
  { name: "SP - São Paulo", value: "SP" },
];

export default function ProfileForm(props: any) {
  const [imageFire, setImageFire] = useState("");

  let formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      // User Data
      name_Customer: props.data?.name_Customer || "",
      cpf_PrivatePerson: props.data?.cpf_PrivatePerson || "",
      cnpj_JuridicalPerson:
        props.data?.cnpj_JuridicalPerson?.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
          "$1.$2.$3/$4-$5"
        ) || "",
      contact_Customer: props.data?.contact_Customer || "",
      email_User: props.data?.email_User || "",
      whatsapp_Customer:
        props.data?.whatsapp_Customer?.replace(
          /^(\d{2})(\d{1})(\d{4})(\d{4})$/,
          "($1)$2 $3-$4"
        ) || "",
      municipalRegistration_JuridicalPerson:
        props.data?.municipalRegistration_JuridicalPerson || "",
      stateRegistration_JuridicalPerson:
        props.data?.stateRegistration_JuridicalPerson || "",

      // Address
      zipcode:
        props.data?.address.zipcode?.replace(/^(\d{5})(\d{3})$/, "$1-$2") || "",
      street: props.data?.address.street || "",
      number: props.data?.address.number || "",
      complement: props.data?.address.complement || "",
      neighbourhood: props.data?.address.neighbourhood || "",
      city: props.data?.address.city || "",
      state: props.data?.address.state || "",

      billingPlace_Customer: props.data?.billingPlace_Customer || "",
      pictureFilename_User: props.data?.pictureFilename_User || "",
      pictureFilepath_User: props.data?.pictureFilepath_User || "",
    },
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    onSubmit: () => {},
  };

  const formik = useFormik(formikValues);
  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    setImageFire(values.pictureFilepath_Customer);
  }, [values.pictureFilepath_Customer]);

  return (
    <Card>
      <CardHeader
        sx={{
          color: "#fff",
          padding: "50px 30px",
          background: "#004A78",
        }}
      />
      <CardContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Container>
              <ProfilePhoto
                imageFire={imageFire}
                setFileFire={(value: any[]) => {}}
                setImageFire={(value: any) => setImageFire(value)}
              />
              <Grid
                container
                columnSpacing={3}
                rowSpacing={1}
                sx={{ paddingY: "12px" }}
              >
                <Grid item xs={12} md={props.data?.cpf_PrivatePerson ? 4 : 6}>
                  <Typography variant="h4">
                    {props.data?.name_Customer}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={props.data?.cpf_PrivatePerson ? 4 : 6}>
                  <Typography variant="h4">
                    Código: {props.data?.initials_Customer}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{ mt: [0, -1] }}
                  xs={12}
                  md={props.data?.cpf_PrivatePerson ? 4 : 6}
                >
                  <Typography variant="h4" gutterBottom>
                    Pagamento:{" "}
                    {props.data?.prepaid_Customer !== undefined
                      ? props.data?.prepaid_Customer
                        ? "Pré-Pago"
                        : "Pós-Pago"
                      : "Não Informado"}
                  </Typography>
                </Grid>
                {!props.data?.cpf_PrivatePerson ? (
                  <Grid item sx={{ mt: -1 }} xs={12} md={4}>
                    <Typography variant="h4" gutterBottom>
                      Simples Nacional:{" "}
                      {props.data?.singleNationalChooser_Customer
                        ? "Sim"
                        : "Não" || "Não"}
                    </Typography>
                  </Grid>
                ) : undefined}
              </Grid>
              <Grid container columnSpacing={3} rowSpacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    fullWidth
                    type="text"
                    color="secondary"
                    variant="outlined"
                    {...getFieldProps("name_Customer")}
                    helperText={touched.name_Customer && errors.name_Customer}
                    error={Boolean(
                      touched.name_Customer && errors.name_Customer
                    )}
                    label={
                      !props.data?.cpf_PrivatePerson
                        ? "Razão Social"
                        : "Nome Completo"
                    }
                  />
                </Grid>
                {props.data?.cpf_PrivatePerson ? (
                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled
                      fullWidth
                      type="text"
                      color="secondary"
                      variant="outlined"
                      label="CPF"
                      {...getFieldProps("cpf_PrivatePerson")}
                      helperText={
                        touched.cpf_PrivatePerson && errors.cpf_PrivatePerson
                      }
                      error={Boolean(
                        touched.cpf_PrivatePerson && errors.cpf_PrivatePerson
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled
                      fullWidth
                      type="text"
                      color="secondary"
                      variant="outlined"
                      label="CNPJ"
                      {...getFieldProps("cnpj_JuridicalPerson")}
                      helperText={
                        touched.cnpj_JuridicalPerson &&
                        errors.cnpj_JuridicalPerson
                      }
                      error={Boolean(
                        touched.cnpj_JuridicalPerson &&
                          errors.cnpj_JuridicalPerson
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    fullWidth
                    type="text"
                    color="secondary"
                    variant="outlined"
                    label="Email"
                    {...getFieldProps("email_User")}
                    helperText={touched.email_User && errors.email_User}
                    error={Boolean(touched.email_User && errors.email_User)}
                  />
                </Grid>
                {!props.data?.cpf_PrivatePerson ? (
                  <Grid item xs={12} md={6}>
                    <TextField
                      disabled
                      fullWidth
                      type="text"
                      color="secondary"
                      variant="outlined"
                      label="Nome do Contato"
                      {...getFieldProps("contact_Customer")}
                      helperText={
                        touched.contact_Customer && errors.contact_Customer
                      }
                      error={Boolean(
                        touched.contact_Customer && errors.contact_Customer
                      )}
                    />
                  </Grid>
                ) : undefined}
                <Grid item xs={12} md={6}>
                  <TextField
                    disabled
                    fullWidth
                    type="text"
                    color="secondary"
                    variant="outlined"
                    label="Whatsapp"
                    {...getFieldProps("whatsapp_Customer")}
                    helperText={
                      touched.whatsapp_Customer && errors.whatsapp_Customer
                    }
                    error={Boolean(
                      touched.whatsapp_Customer && errors.whatsapp_Customer
                    )}
                  />
                </Grid>
                {!props.data?.cpf_PrivatePerson ? (
                  <>
                    <Grid item xs={12} md={3}>
                      <TextField
                        disabled
                        fullWidth
                        type="text"
                        color="secondary"
                        variant="outlined"
                        label="Incrição Municipal"
                        {...getFieldProps(
                          "municipalRegistration_JuridicalPerson"
                        )}
                        helperText={
                          touched.municipalRegistration_JuridicalPerson &&
                          errors.municipalRegistration_JuridicalPerson
                        }
                        error={Boolean(
                          touched.municipalRegistration_JuridicalPerson &&
                            errors.municipalRegistration_JuridicalPerson
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        disabled
                        fullWidth
                        type="text"
                        color="secondary"
                        variant="outlined"
                        label="Incrição Estadual"
                        {...getFieldProps("stateRegistration_JuridicalPerson")}
                        helperText={
                          touched.stateRegistration_JuridicalPerson &&
                          errors.stateRegistration_JuridicalPerson
                        }
                        error={Boolean(
                          touched.stateRegistration_JuridicalPerson &&
                            errors.stateRegistration_JuridicalPerson
                        )}
                      />
                    </Grid>
                  </>
                ) : undefined}
                <Grid item xs={12} md={6}>
                  <Grid container columnSpacing={2} rowSpacing={2}>
                    <Grid item xs={12} md={12}>
                      <Typography variant="subtitle1" gutterBottom>
                        Endereço
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        disabled
                        fullWidth
                        type="text"
                        label="CEP"
                        color="secondary"
                        variant="outlined"
                        {...getFieldProps("zipcode")}
                        error={Boolean(touched.zipcode && errors.zipcode)}
                        helperText={touched.zipcode && errors.zipcode}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled
                        fullWidth
                        type="text"
                        label="Cidade"
                        {...getFieldProps("city")}
                        variant="outlined"
                        color="secondary"
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        disabled
                        fullWidth
                        type="text"
                        label="Estado"
                        {...getFieldProps("state")}
                        variant="outlined"
                        color="secondary"
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                      />
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <TextField
                        disabled
                        fullWidth
                        type="text"
                        label="Logradouro"
                        {...getFieldProps("street")}
                        variant="outlined"
                        color="secondary"
                        error={Boolean(touched.street && errors.street)}
                        helperText={touched.street && errors.street}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <TextField
                        disabled
                        fullWidth
                        type="text"
                        label="Núm."
                        {...getFieldProps("number")}
                        variant="outlined"
                        color="secondary"
                        error={Boolean(touched.number && errors.number)}
                        helperText={touched.number && errors.number}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled
                        fullWidth
                        type="text"
                        label="Complemento"
                        {...getFieldProps("complement")}
                        variant="outlined"
                        color="secondary"
                        error={Boolean(touched.complement && errors.complement)}
                        helperText={touched.complement && errors.complement}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        disabled
                        fullWidth
                        type="text"
                        label="Bairro"
                        {...getFieldProps("neighbourhood")}
                        variant="outlined"
                        color="secondary"
                        error={Boolean(
                          touched.neighbourhood && errors.neighbourhood
                        )}
                        helperText={
                          touched.neighbourhood && errors.neighbourhood
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Unidade Opto
                  </Typography>
                  <RadioGroup {...getFieldProps("billingPlace_Customer")}>
                    {COMPANIES.map((item) => (
                      <FormControlLabel
                        disabled
                        key={item.value}
                        label={item.name}
                        value={item.value}
                        control={<Radio />}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
              </Grid>
            </Container>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  );
}
