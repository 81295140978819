/* eslint-disable camelcase */
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import plusFill from "@iconify/icons-eva/plus-fill";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
import pricetagsOutline from "@iconify/icons-eva/pricetags-outline";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../../../components/Page";
import Scrollbar from "../../../components/Scrollbar";
import OrdersListHead from "../components/OrdersListHead";
import OrdersListToolbar from "../components/OrdersListToolbar";
import OrderModal from "../components/OrderModal";
import orderService from "../services/orderService";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "id_Order", label: "Código de Pedido", alignRight: false },
  { id: "date_Order", label: "Data da OS", alignRight: false },
  { id: "name_Lens", label: "Produto", alignRight: false },
  { id: "lensesAmount_Order", label: "Qtde (Par)" },
  { id: "whatsapp_Company", label: "Contato", alignRight: false },
  { id: "status_Order", label: "Status da OS", alignRight: false },
  { id: "idCompany_Order", label: "Pedir Etiquetas" },
  { id: "", label: "Detalhes" },
];

// ----------------------------------------------------------------------

export default function Services() {
  const parse: any = localStorage.getItem("user");
  const user = JSON.parse(parse);
  const navigate = useNavigate();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [data, setData]: any = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [modalOpen, setModalOpen] = useState(false);
  const [notFirstQuery, setNotFirstQuery] = useState(false);
  const [queryStartEnd, setQueryStartEnd] = useState([
    "0",
    rowsPerPage.toString(),
  ]);

  const [params, setParams]: any = useState({
    value: "",
    startDate: undefined,
    endDate: undefined,
  });

  useEffect(() => {
    orderService
      .getAll(queryStartEnd[0], queryStartEnd[1], params)
      .then((data: any) => setData(data.data))
      .catch((error: any) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStartEnd, params.startDate, params.endDate, params.initialCompany]);

  useEffect(() => {
    if (window.location.pathname === "/dashboard/cadastrar") {
      setModalOpen(true);
    }
  }, [location]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        notFirstQuery
          ? orderService
              .getAll(queryStartEnd[0], queryStartEnd[1], params)
              .then((data: any) => setData(data.data))
              .catch((error: any) => console.error(error))
          : setNotFirstQuery(true),
      500
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.value]);

  const handleChangePage = (event: any, newPage: any) => {
    const finalPage = newPage > 0 ? page + 1 : page - 1;
    setQueryStartEnd([
      ((finalPage + 1) * rowsPerPage - rowsPerPage).toString(),
      rowsPerPage.toString(),
    ]);
    setPage(finalPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const finalRowsPerPage = parseInt(event.target.value);
    setQueryStartEnd([
      ((page + 1) * finalRowsPerPage - finalRowsPerPage).toString(),
      finalRowsPerPage.toString(),
    ]);
    setRowsPerPage(finalRowsPerPage);
    setPage(0);
  };

  function callWhatsapp(number: any, text?: boolean) {
    let url;
    if (text) {
      if (user.initials)
        url = `https://api.whatsapp.com/send?phone=55${number}&text=Ol%C3%A1,%20gostaria%20de%20solicitar%20mais%20etiquetas!%20Meu%20c%C3%B3digo%20de%20cliente%20%C3%A9:%20*${user.initials}*`;
      else
        url = `https://api.whatsapp.com/send?phone=55${number}&text=Ol%C3%A1,%20gostaria%20de%20solicitar%20mais%20etiquetas!`;
    } else url = `https://api.whatsapp.com/send?phone=55${number}`;

    window.open(url, "_blank");
  }

  const emptyRows = page > 0 ? Math.max(0, rowsPerPage - data.length) : 0;

  return (
    <Page title="Meus pedidos | Optools">
      <Container>
        <OrderModal
          open={modalOpen}
          handler={() => setModalOpen(false)}
          confirmButtonText="Cadastrar nova OS"
          title="Deseja cadastrar uma nova Ordem de Serviço?"
        />
        <Stack direction="row" alignItems="center">
          <Typography variant="h4">Ordem de serviço</Typography>
        </Stack>

        <Stack direction={["column", "row"]} justifyContent="space-between">
          <OrdersListToolbar
            filterName={params.value}
            filterReset={() =>
              setParams({
                value: "",
                startDate: undefined,
                endDate: undefined,
              })
            }
            onFilterName={(value: string) => {
              setPage(0);
              setQueryStartEnd(["0", rowsPerPage.toString()]);
              setParams({ ...params, value: value });
            }}
            onFilterDate={(startDate: string, endDate: string) => {
              setPage(0);
              setQueryStartEnd(["0", rowsPerPage.toString()]);
              setParams({
                ...params,
                startDate: startDate.length ? startDate : undefined,
                endDate: endDate.length ? endDate : undefined,
              });
            }}
          />

          <Button
            sx={{
              height: "48px",
              lineHeight: "18px",
              borderRadius: "40px",
              background: "#004A78",
              marginTop: [0, "22px"],
              marginBottom: [3, 0],
              "&:hover": {
                bgcolor: "#226C9A",
              },
            }}
            color="secondary"
            variant="contained"
            onClick={() => setModalOpen(true)}
            startIcon={<Icon icon={plusFill} />}
          >
            Cadastrar nova OS
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <OrdersListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {data.length ? (
                    data
                      .slice(0, rowsPerPage)
                      .map((row: any, index: number) => {
                        const {
                          id_Order,
                          name_Lens,
                          date_Order,
                          whatsapp_SP,
                          status_Order,
                          code_OrderCode,
                          whatsapp_Company,
                          lensesAmount_Order,
                        } = row;

                        return (
                          <TableRow
                            hover
                            tabIndex={-1}
                            key={id_Order}
                            role="checkbox"
                          >
                            <TableCell align="left">
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ color: "#004A78", fontSize: "14px" }}
                              >
                                {code_OrderCode}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="body1"
                                noWrap
                                sx={{ color: "#707070", fontSize: "14px" }}
                              >
                                {date_Order.split(" ")[0] ||
                                  "Sem data para exibir"}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ color: "#004A78", fontSize: "14px" }}
                              >
                                {name_Lens || "Não cadastrado"}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{
                                  color: "#004A78",
                                  fontSize: "14px",
                                  ml: 4,
                                }}
                              >
                                {lensesAmount_Order / 2 || "Não cadastrada"}
                              </Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ padding: "11px" }}>
                              <Button
                                color="primary"
                                disabled={whatsapp_Company ? false : true}
                                onClick={() => callWhatsapp(whatsapp_Company)}
                              >
                                <WhatsAppIcon />
                              </Button>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                noWrap
                                variant="subtitle2"
                                sx={{ color: "#004A78", fontSize: "14px" }}
                              >
                                {status_Order}
                              </Typography>
                            </TableCell>
                            <TableCell align="left" sx={{ padding: "11px" }}>
                              <Button
                                sx={{
                                  padding: 0,
                                  minWidth: "36px",
                                  height: "36px",
                                  borderRadius: "18px",
                                  marginLeft: "18%",
                                }}
                                color="secondary"
                                variant="contained"
                                onClick={() => callWhatsapp(whatsapp_SP, true)}
                              >
                                <Icon width="20px" icon={pricetagsOutline} />
                              </Button>
                            </TableCell>
                            <TableCell align="left" sx={{ padding: "11px" }}>
                              <Button
                                sx={{
                                  padding: 0,
                                  minWidth: "36px",
                                  height: "36px",
                                  borderRadius: "18px",
                                  marginLeft: "12%",
                                }}
                                color="secondary"
                                variant="contained"
                                onClick={async () => {
                                  const info = await orderService.getById(
                                    data[index].id_Order
                                  );
                                  const actions = await orderService.getActions(
                                    data[index].id_Order
                                  );
                                  navigate("/dashboard/pedidos/cadastrar", {
                                    state: {
                                      ...info.data,
                                      actions: actions.data,
                                    },
                                  });
                                }}
                              >
                                <Icon width="20px" icon={plusFill} />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                        <Stack
                          width="100%"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Icon
                            icon={inboxOutline}
                            width="56px"
                            color="#909090"
                          />
                          <Typography
                            mt={1}
                            variant="body1"
                            noWrap
                            sx={{ color: "#909090" }}
                          >
                            Nenhum item encontrado...
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                  {emptyRows > 0 ? (
                    <TableRow style={{ height: 59 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  ) : undefined}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            page={0}
            component="div"
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[20, 40, 60]}
            labelRowsPerPage="Linhas por página:"
            backIconButtonProps={{ disabled: !page }}
            nextIconButtonProps={{
              disabled:
                (page + 1) * rowsPerPage >= data[0]?.total_Order ||
                data.length < rowsPerPage,
            }}
            labelDisplayedRows={(rowsInfo) => {
              const totalPages = Math.ceil(
                rowsInfo.count / rowsPerPage
              ).toString();
              return (
                <>
                  {`Página: ${page + 1}${
                    !params.value.length && !params.prepaid && !params.situation
                      ? `/${totalPages === "0" ? "1" : totalPages}`
                      : ""
                  }`}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {!params.value.length && !params.prepaid && !params.situation
                    ? `Total: ${rowsInfo.count}`
                    : ""}
                </>
              );
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={data?.length ? data[0].total_Order : 0}
          />
        </Card>
      </Container>
    </Page>
  );
}
