// material
//import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
//import arrowDown from "@iconify/icons-eva/arrow-ios-downward-outline";
import {
  Grid,
  Card,
  Container,
  //Accordion,
  Typography,
  CardContent,
  //AccordionSummary,
  //AccordionDetails,
  Button,
} from "@mui/material";
// components
import Page from "../components/Page";
import YoutubeEmbed from "../components/YoutubeEmbed";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [selected, setSelected] = useState(0);
  const [tutorials, setTutorials]: any = useState([]);
  const parse: any = localStorage.getItem("user");
  const user = JSON.parse(parse);

  useEffect(() => {
    if (user.role === "Vendedor")
      setTutorials([{ name: "Geral", link: "UCRzpL2sqhk" }]);
    else if (user.role === "Financeiro")
      setTutorials([{ name: "Geral", link: "ftJA5QhGQH8" }]);
    else if (user.role === "Comercial")
      setTutorials([
        { name: "Geral", link: "bG0UyK4WaUU" },
        { name: "Clientes, Etiquetas e Administração", link: "yRuIJRSiYfU" },
        {
          name: "Relatórios, Cadastro de Clientes e Grupos",
          link: "I7gb9IMVyZ0",
        },
      ]);
    else if (user.role === "Operacional")
      setTutorials([
        { name: "Listagem de Lentes", link: "KhodAt7n7ec" },
        { name: "Pesquisa com QRCodes", link: "vW2x4wy4xEc" },
      ]);
    else if (user.role === "Cliente") {
      if (user.prepaid)
        setTutorials([
          { name: "Geral", link: "Bsrpc3Np9Rw" },
          { name: "Cadastro de Pedidos(Lentes)", link: "Pu4Xe1WGlOE" },
          { name: "Acompanhamento de Pedidos(Ações)", link: "RxAD20FnSc0" },
          { name: "Relatórios", link: "9ve0pFsf7O8" },
        ]);
      else
        setTutorials([
          { name: "Geral", link: "Bsrpc3Np9Rw" },
          { name: "Cadastro de Pedidos(Lentes)", link: "Pu4Xe1WGlOE" },
          { name: "Acompanhamento de Pedidos(Ações)", link: "RxAD20FnSc0" },
          { name: "Relatórios", link: "Xv5c0x2lTes" },
        ]);
    } else
      setTutorials([
        { name: "Geral Vendedor", link: "UCRzpL2sqhk" },
        { name: "Geral Financeiro", link: "ftJA5QhGQH8" },
        { name: "Geral Comercial", link: "bG0UyK4WaUU" },
        { name: "Clientes, Etiquetas e Administração", link: "yRuIJRSiYfU" },
        {
          name: "Relatórios, Cadastro de Clientes e Grupos",
          link: "I7gb9IMVyZ0",
        },
        { name: "Listagem de Lentes", link: "KhodAt7n7ec" },
        { name: "Pesquisa com QRCodes", link: "vW2x4wy4xEc" },
        { name: "Geral", link: "Bsrpc3Np9Rw" },
        { name: "Cadastro de Pedidos(Lentes)", link: "Pu4Xe1WGlOE" },
        { name: "Acompanhamento de Pedidos(Ações)", link: "RxAD20FnSc0" },
        { name: "Relatórios Pré-Pago", link: "9ve0pFsf7O8" },
        { name: "Relatórios Pós-Pago", link: "Xv5c0x2lTes" },
      ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Início | Optools">
      <Container maxWidth="lg">
        <Card sx={{ mt: [0, 4] }}>
          <CardContent sx={{ px: [2, 6] }}>
            <Typography variant="h4" color="secondary" sx={{ pb: 3 }}>
              Olá, seja bem vindo!
            </Typography>
            {/* <Typography variant="h5" color="secondary" gutterBottom>
              Atualizações:
            </Typography> */}
            <Grid container columnSpacing={[0, 5]}>
              {/* <Grid item xs={12} md={6} mb={[2, 0]}>
                <Accordion
                  sx={{
                    border: "1px solid #dadada",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<Icon icon={arrowDown} color="#FFF" />}
                    aria-controls="feature1-content"
                    id="feature1-header"
                  >
                    <Typography variant="subtitle1">Nova Ação para Pedido ("Continuar Processos")</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                      blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    border: "1px solid #dadada",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<Icon icon={arrowDown} color="#FFF" />}
                    aria-controls="feature1-content"
                    id="feature1-header"
                  >
                    <Typography variant="subtitle1">Lorem ipsum dolor sit amet</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                      blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} md={6}>
                <Accordion
                  sx={{
                    border: "1px solid #dadada",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<Icon icon={arrowDown} color="#FFF" />}
                    aria-controls="feature1-content"
                    id="feature1-header"
                  >
                    <Typography variant="subtitle1">Consectetur adipiscing elit</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                      blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    border: "1px solid #dadada",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<Icon icon={arrowDown} color="#FFF" />}
                    aria-controls="feature1-content"
                    id="feature1-header"
                  >
                    <Typography variant="subtitle1">Sit amet blandit leo lobortis eget</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                      blandit leo lobortis eget.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid> mt={4} */}
              <Grid item xs={12} md={12}>
                <Typography variant="h5" color="secondary">
                  TUTORIAIS:
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} mt={1}>
                <YoutubeEmbed
                  embedId={tutorials[selected]?.link || "yRuIJRSiYfU"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                mt={1}
                maxHeight={["auto", "420px"]}
                overflow="auto"
                paddingRight={[0, 2]}
              >
                {tutorials.map((item: any, index: number) => (
                  <Button
                    key={index}
                    fullWidth
                    color="secondary"
                    sx={{
                      mb: 2,
                      height: "48px",
                      paddingX: "32px",
                      lineHeight: "16px",
                      borderRadius: "4px",
                      color: selected === index ? "#FFF" : "#004A78",
                      backgroundColor:
                        selected === index ? "#004A78" : "#CDCDCD",
                      "&:hover": {
                        bgcolor: "#004A78",
                        color: "#FEFEFE",
                      },
                    }}
                    onClick={() => setSelected(index)}
                  >
                    {item.name}
                  </Button>
                ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
