/* eslint-disable no-const-assign */
import { useState, useEffect } from "react";
import { Stack, Typography, Container } from "@mui/material";

import Page from "../../../components/Page";
import ProfileForm from "../components/ProfileForm";
import profileService from "../services/profileService";
import AlertMessage from "../../../components/alert/alert";

export default function Profile() {
  const [data, setData]: any = useState();
  const [showError, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showSuccess, setSuccess] = useState(false);

  const getData = async () => {
    const profileData = await profileService.getData();
    setData(profileData.data);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  return (
    <Page title="Clientes - Cadastro | Optools">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h3" gutterBottom>
            Meu Perfil
          </Typography>
        </Stack>

        <ProfileForm
          data={data}
          setValid={(stateBol: boolean) => setIsValid(stateBol)}
          isValid={isValid}
        />
      </Container>
      {showSuccess && (
        <AlertMessage open={true} message="Cliente registrado com sucesso!" />
      )}
      {showError && (
        <AlertMessage open={true} message="Erro ao efetuar registro" />
      )}
    </Page>
  );
}
