import "simplebar/src/simplebar.css";

// react
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// basics
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SignedProvider } from "./contexts/client";
// import * as serviceWorker from './serviceWorker';

//==================================================

ReactDOM.render(
  <HelmetProvider>
    <SignedProvider>
      <BrowserRouter basename={""}>
        <App />
      </BrowserRouter>
    </SignedProvider>
  </HelmetProvider>,
  document.getElementById("root")
);

reportWebVitals();
