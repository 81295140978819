import { Icon } from "@iconify/react";

// Material Icons

// Eva Icons
import carOutline from "@iconify/icons-eva/car-outline";
import gridOutline from "@iconify/icons-eva/grid-outline";
import homeOutline from "@iconify/icons-eva/home-outline";
import searchOutline from "@iconify/icons-eva/search-outline";
import peopleOutline from "@iconify/icons-eva/people-outline";
import personOutline from "@iconify/icons-eva/person-outline";
import fileTextOutline from "@iconify/icons-eva/file-text-outline";
import pricetagOutline from "@iconify/icons-eva/pricetags-outline";
import personAddOutline from "@iconify/icons-eva/person-add-outline";
import creditCardOutline from "@iconify/icons-eva/credit-card-outline";
import keyPadFill from "@iconify/icons-eva/keypad-fill";

import layersOutline from "@iconify/icons-eva/layers-outline";

import options2OutLine from "@iconify/icons-eva/options-2-outline";
// ----------------------------------------------------------------------

const getIcon = (name: any) => <Icon icon={name} width={22} height={22} />;

function changeSideBar(token: string | null): any {
  var sidebarConfig: Array<any> = [
    {
      title: "início",
      path: "/dashboard/app",
      icon: getIcon(homeOutline),
    },
  ];
  if (token) {
    const user = JSON.parse(token);
    if (user.role === "Cliente") {
      sidebarConfig[0].title = "VIDEOS";
      sidebarConfig.push(
        {
          title: "CADASTRAR O.S",
          path: "/dashboard/cadastrar",
          icon: getIcon(keyPadFill),
        },
        {
          title: "MINHAS OS's",
          path: "/dashboard/pedidos",
          icon: getIcon(carOutline),
        },
        {
          title: "RELATÓRIOS",
          path: "/dashboard/relatorio",
          icon: getIcon(fileTextOutline),
        },
        {
          title: "MEUS DADOS",
          path: "/dashboard/dados",
          icon: getIcon(personOutline),
        },
 
      );
    } else if (user.role === "Operacional") {
      sidebarConfig.push(
        {
          title: "pedidos",
          path: "/dashboard/operacional",
          icon: getIcon(carOutline),
        },
        {
          title: "buscar",
          path: "/dashboard/buscar",
          icon: getIcon(searchOutline),
        }
      );
    } else if (user.role === "Vendedor") {
      sidebarConfig.push({
        title: "clientes",
        path: "/dashboard/clientes",
        icon: getIcon(personAddOutline),
      });
    }else if (user.role === "Financeiro") {
      sidebarConfig.push({
        title: "financeiro",
        path: "/dashboard/financeiro",
        icon: getIcon(creditCardOutline),
      },);
    } else if (user.role === "Comercial") {
      sidebarConfig.push(
        {
          title: "clientes",
          path: "/dashboard/clientes",
          icon: getIcon(personAddOutline),
        },
        {
          title: "etiquetas",
          path: "/dashboard/etiquetas",
          icon: getIcon(pricetagOutline),
        },
        {
          title: "produtos",
          path: "/dashboard/produtos",
          icon: getIcon(gridOutline),
        },
        {
          title: "administração",
          path: "/dashboard/administracao",
          icon: getIcon(options2OutLine),
        },
        {
          title: "Grupos",
          path: "/dashboard/grupos",
          icon: getIcon(layersOutline),
        }
      );
    } else {
      sidebarConfig = [
        // Menu items for administrators
        {
          title: "início",
          path: "/dashboard/app",
          icon: getIcon(homeOutline),
        },
        {
          title: "colaboradores",
          path: "/dashboard/colaboradores",
          icon: getIcon(peopleOutline),
        },
        {
          title: "clientes",
          path: "/dashboard/clientes",
          icon: getIcon(personAddOutline),
        },
        {
          title: "etiquetas",
          path: "/dashboard/etiquetas",
          icon: getIcon(pricetagOutline),
        },
        {
          title: "administração",
          path: "/dashboard/administracao",
          icon: getIcon(options2OutLine),
        },
        {
          title: "financeiro",
          path: "/dashboard/financeiro",
          icon: getIcon(creditCardOutline),
        },
        {
          title: "produtos",
          path: "/dashboard/produtos",
          icon: getIcon(gridOutline),
        },
        {
          title: "Grupos",
          path: "/dashboard/grupos",
          icon: getIcon(layersOutline),
        },
      ];
    }
  }

  return sidebarConfig;
}

export default changeSideBar;
