// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";
// components
import firebase from "firebase/app";
import firebaseConfig from "./utils/firebase";
import ScrollToTop from "./components/ScrollToTop";
//=================================================

function App() {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  return (
    <ThemeConfig>
      <ScrollToTop />
      <Router />
    </ThemeConfig>
  );
}

export default App;
