import { Icon } from "@iconify/react";
import { useState, useEffect, useRef } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreHorizontalFill from "@iconify/icons-eva/more-horizontal-fill";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import ProductDialog from "./ProductDialog";

import productService from "../services/productService";

// ----------------------------------------------------------------------

export default function ProductOptions(props: any) {
  const ref = useRef(null);
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  useEffect(() => {
    productService
      .getById(props.id)
      .then((res) => {
        setData({ ...res.data, id_Lens: props.id });
      })
      .catch((err) => {
        console.error(err);
      });
  }, [props.id]);

  const edit = () => {
    props.setModalData(data);
    setIsOpen(false);
    props.setModalOpen(true);
  };

  const deleteLens = () => {
    productService
      .deleteLens(props.id)
      .then(() => {
        props.setSuccess(true);
        setDeleting(false);
      })
      .catch((error) => {
        console.error(error);
        props.setError(true);
      });
  };

  return (
    <>
      <ProductDialog
        id={props.id}
        open={isDeleting}
        title="Remover Produto"
        action={() => deleteLens()}
        cancelButtonText="Cancelar"
        confirmButtonText="Remover"
        handler={() => setDeleting(false)}
        description="Tem certeza que deseja remover este produto?"
      />
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreHorizontalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={() => setDeleting(true)}
        >
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Deletar"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem onClick={edit} sx={{ color: "text.secondary" }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Editar"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
