/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { Icon } from "@iconify/react";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
import {
  Card,
  Stack,
  Typography,
  CardHeader,
  CardContent,
} from "@mui/material";

import {
  Timeline,
  TimelineDot,
  TimelineItem,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineOppositeContent,
} from "@mui/lab";

export default function ActionCard(props: any) {
  return (
    <>
      <Card sx={{ marginTop: "32px" }}>
        <CardHeader
          sx={{
            background: "#004A78",
            color: "#fff",
            paddingTop: "10px",
            paddingLeft: "30px",
            paddingBottom: "10px",
          }}
          title="Ações"
        />
        <CardContent>
          {!!props.actions[0]?.date ? (
            <Timeline sx={{ ml: "-80%" }}>
              {props.actions.map((item: any, index: number) => {
                let dateTime = item.date.split(" ");

                return (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent>
                      <Typography color="textSecondary">
                        {dateTime[1].split(":").slice(0, -1).join(":")}
                      </Typography>
                      <Typography lineHeight={1} color="textSecondary">
                        {dateTime[0].replace("/20", "/")}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          width: "28px",
                          height: "28px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        color="secondary"
                      >
                        <Typography>{index + 1}</Typography>
                      </TimelineDot>
                      {index + 1 !== props.actions.length && (
                        <TimelineConnector />
                      )}
                    </TimelineSeparator>
                    <TimelineContent sx={{ pt: 0.2 }}>
                      <Typography variant="h5">{item.type}</Typography>
                      <Typography variant="body1" lineHeight="12px">
                        {item.description}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          ) : (
            <Stack
              paddingY="12px"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Icon icon={inboxOutline} width="56px" color="#909090" />
              <Typography
                mt={1}
                variant="body1"
                noWrap
                sx={{ color: "#909090" }}
              >
                Nada registrado...
              </Typography>
            </Stack>
          )}
        </CardContent>
      </Card>
    </>
  );
}
