import { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// material
import { styled } from "@mui/system";
import {
  Box,
  Stack,
  Toolbar,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  float: "left",
  width: "100%",
  display: "flex",
  padding: theme.spacing(0, 0, 0, 0),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }: any) => ({
  height: 48,
  float: "left",
  borderRadius: "40px",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 250, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const DatePicker = styled(OutlinedInput)(({ theme }: any) => ({
  height: 48,
  float: "left",
  borderRadius: "40px",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 192, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

OrdersListToolbar.propTypes = {
  filterReset: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterDate: PropTypes.func,
};

export default function OrdersListToolbar({
  filterName,
  filterReset,
  onFilterName,
  onFilterDate,
}: any) {
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");

  return (
    <RootStyle
      sx={{
        height: ["", 72],
        flexDirection: ["column", "row"],
        marginBottom: [2, 3],
      }}
    >
      <SearchStyle
        sx={{
          width: ["100%", 256],
          margin: ["10px 0", "24px 8px auto -24px"],
        }}
        type="text"
        value={filterName}
        placeholder="Pesquisar..."
        inputProps={{ style: { textTransform: "uppercase" } }}
        onChange={(element: any) =>
          onFilterName(element.target.value?.toUpperCase())
        }
        startAdornment={
          <InputAdornment position="start">
            <Box
              component={Icon}
              icon={searchFill}
              sx={{ color: "text.disabled" }}
            />
          </InputAdornment>
        }
      />

      <Stack sx={{ width: ["100%", 198] }}>
        <Typography
          variant="subtitle2"
          color="secondary"
          sx={{ marginLeft: ["8px", "18px"] }}
        >
          Data inicial:
        </Typography>
        <DatePicker
          sx={{
            width: ["100%", 198],
            marginLeft: [0, "4px"],
          }}
          type="date"
          value={date1}
          onChange={(event: any) => {
            const value = event.target.value;

            setDate1(value);
            onFilterDate(
              value.split("-").reverse().join("/"),
              date2.split("-").reverse().join("/")
            );
          }}
        />
      </Stack>
      <Stack sx={{ width: ["100%", 198], marginTop: ["10px", 0] }}>
        <Typography
          variant="subtitle2"
          color="secondary"
          sx={{ marginLeft: ["8px", "30px"] }}
        >
          Data final:
        </Typography>
        <DatePicker
          sx={{
            width: ["100%", 198],
            marginLeft: [0, "16px"],
          }}
          type="date"
          value={date2}
          onChange={(event: any) => {
            const value = event.target.value;

            setDate2(value);
            onFilterDate(
              date1.split("-").reverse().join("/"),
              value.split("-").reverse().join("/")
            );
          }}
        />
      </Stack>

      <Button
        sx={{ ml: [0, 2], mt: [1, 2] }}
        color="inherit"
        onClick={() => {
          setDate1("");
          setDate2("");
          filterReset();
        }}
      >
        <HighlightOffIcon sx={{ color: "#A9A9A9" }} />
        <Typography variant="body2" color="#878787" sx={{ ml: "3px" }}>
          LIMPAR FILTROS
        </Typography>
      </Button>
    </RootStyle>
  );
}
