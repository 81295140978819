/* eslint-disable camelcase */
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
import calendarOutline from "@iconify/icons-eva/calendar-outline";
import downloadOutline from "@iconify/icons-eva/download-outline";
// material
import {
  Grid,
  Card,
  Stack,
  Avatar,
  Button,
  Container,
  Typography,
  CardActions,
  CardContent,
  IconButton,
  TablePagination,
} from "@mui/material";
// components
import Page from "../../../../components/Page";
import ClientsListToolbar from "../components/FinancialListToolbar";

import FinancialModal from "../components/FinancialModal";
import financialService from "../services/financialService";
import AlertMessage from "../../../../components/alert/alert";

export default function Financial() {
  const [page, setPage] = useState(0);
  const [data, setData]: any = useState([]);
  const [showError, setError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [notFirstQuery, setNotFirstQuery] = useState(false);
  const [queryStartEnd, setQueryStartEnd] = useState([
    "0",
    rowsPerPage.toString(),
  ]);

  const [params, setParams]: any = useState({
    startDate: undefined,
    endDate: undefined,
  });

  useEffect(() => {
    if (!modalOpen || showSuccess)
      financialService
        .getAll(queryStartEnd[0], queryStartEnd[1], params)
        .then((data: any) => setData(data.data))
        .catch((error: any) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen, showSuccess, queryStartEnd]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        notFirstQuery
          ? financialService
              .getAll(queryStartEnd[0], queryStartEnd[1], params)
              .then((data: any) => setData(data.data))
              .catch((error: any) => console.error(error))
          : setNotFirstQuery(true),
      500
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  const handleChangePage = (event: any, newPage: any) => {
    const finalPage = newPage > 0 ? page + 1 : page - 1;
    setQueryStartEnd([
      ((finalPage + 1) * rowsPerPage - rowsPerPage).toString(),
      rowsPerPage.toString(),
    ]);
    setPage(finalPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const finalRowsPerPage = parseInt(event.target.value);
    setQueryStartEnd([
      ((page + 1) * finalRowsPerPage - finalRowsPerPage).toString(),
      finalRowsPerPage.toString(),
    ]);
    setRowsPerPage(finalRowsPerPage);
    setPage(0);
  };

  return (
    <Page title="Financeiro | Optools">
      <FinancialModal
        open={modalOpen}
        title="Números das Notas"
        confirmButtonText="Gerar"
        setError={() => setError(true)}
        handler={() => setModalOpen(false)}
        setSuccess={() => setSuccess(true)}
      />
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            Financeiro
          </Typography>
        </Stack>

        <Stack direction={["column", "row"]} justifyContent="space-between">
          <ClientsListToolbar
            onFilterDate={(startDate: string, endDate: string) => {
              setPage(0);
              setQueryStartEnd(["0", rowsPerPage.toString()]);
              setParams({
                startDate: startDate.length ? startDate : undefined,
                endDate: endDate.length ? endDate : undefined,
              });
            }}
          />

          <Button
            sx={{
              height: "48px",
              borderRadius: "40px",
              background: "#004A78",
              marginTop: [0, "36px"],
              marginBottom: [3, 0],
              "&:hover": {
                bgcolor: "#226C9A",
              },
            }}
            variant="contained"
            onClick={() => setModalOpen(true)}
            color="secondary"
          >
            Gerar Fechamento
          </Button>
        </Stack>

        <Grid container spacing={4}>
          {data.length ? (
            data.slice(0, rowsPerPage).map((row: any) => {
              const {
                id_Billing,
                serverRegisterDate_Billing,
                filepath_Billing,
              } = row;
              const registerTime = serverRegisterDate_Billing.split(" ");

              return (
                <Grid key={id_Billing} item xs={12} sm={6} md={4} lg={3}>
                  <Card sx={{ justifyContent: "center", alignItems: "center" }}>
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        paddingBottom: "5px",
                      }}
                    >
                      <Avatar
                        src=""
                        sx={{
                          width: "128px",
                          height: "128px",
                          backgroundColor: "#008AC7",
                          opacity: "50%",
                        }}
                      >
                        <Icon icon={calendarOutline} width={56} />
                      </Avatar>

                      <Typography
                        mt={1}
                        variant="h4"
                        noWrap
                        sx={{ color: "#303972" }}
                      >
                        {registerTime[0]}
                      </Typography>

                      <Typography variant="h5" noWrap sx={{ color: "#303972" }}>
                        {registerTime[1]}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "12px",
                        mt: -1,
                      }}
                    >
                      <IconButton
                        color="primary"
                        onClick={() => {
                          window.open(filepath_Billing, "_blank");
                        }}
                        sx={{
                          marginRight: "8px",
                          background: "#004A78",
                          color: "#FFF",
                          "&:hover": {
                            bgcolor: "#115B89",
                          },
                        }}
                      >
                        <Icon icon={downloadOutline} />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })
          ) : (
            <Stack
              mt={12}
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Icon icon={inboxOutline} width="56px" color="#909090" />
              <Typography
                mt={1}
                variant="body1"
                noWrap
                sx={{ color: "#909090" }}
              >
                Nenhum item encontrado...
              </Typography>
            </Stack>
          )}
        </Grid>
        <TablePagination
          page={0}
          component="div"
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[8, 16, 24]}
          backIconButtonProps={{ disabled: !page }}
          labelRowsPerPage="Linhas por página:"
          nextIconButtonProps={{
            disabled:
              (page + 1) * rowsPerPage >= data[0]?.total_Billing ||
              data.length < rowsPerPage,
          }}
          labelDisplayedRows={(rowsInfo) => {
            const totalPages = Math.ceil(
              rowsInfo.count / rowsPerPage
            ).toString();
            return (
              <>
                {`Página: ${page + 1}${
                  !params.startDate && !params.endDate
                    ? `/${totalPages === "0" ? "1" : totalPages}`
                    : ""
                }`}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {!params.startDate && !params.endDate
                  ? `Total: ${rowsInfo.count}`
                  : ""}
              </>
            );
          }}
          onRowsPerPageChange={handleChangeRowsPerPage}
          count={data?.length ? data[0].total_Billing : 0}
        />
        {showSuccess && (
          <AlertMessage open={true} message="Sucesso ao gerar fechamento!" />
        )}
        {showError && (
          <AlertMessage open={true} message="Erro ao gerar fechamento" />
        )}
      </Container>
    </Page>
  );
}
