import http from "../../../../services/http-common";

class TagService {
  getAll(offset: string, limit: string, params: any): any {
    return http.get(`customer/qrcode/find/${offset}/${limit}`, {
      params: params,
    });
  }
}

export default new TagService();
