import { Icon } from "@iconify/react";
import ClientDialog from "./ClientDialog";
import { useNavigate } from "react-router-dom";
import editFill from "@iconify/icons-eva/edit-fill";
import lockFill from "@iconify/icons-eva/lock-fill";
import { useState, useEffect, useRef } from "react";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreHorizontalFill from "@iconify/icons-eva/more-horizontal-fill";
import {
  Menu,
  Button,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import clientService from "../services/clientService";
import AlertMessage from "../../../../components/alert/alert";
import PasswordModalPage from "./PasswordModal";

// ----------------------------------------------------------------------

export default function ClientOptions(props: any) {
  const navigate = useNavigate();

  const ref = useRef(null);
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [urlPass, setUrlPass] = useState("");

  useEffect(() => {
    clientService
      .getById(props.id)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [props.id]);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  const alertRemove = () => {
    setIsDeleted(true);
    setIsOpen(false);
  };

  const removeAlert = () => {
    setIsDeleted(false);
    clientService
      .removeClient(props.id)
      .then((res) => {
        setSuccess(true);
        props.setUpdateData(!props.updateData);
      })
      .catch((err) => {
        setError(true);
        console.error(err);
      });
  };

  const edit = () => {
    navigate("/dashboard/clientes/cadastrar", {
      state: { ...data, id_User: props.id },
    });
  };

  const passwordRecovery = () => {
    clientService
      .passwordRecover(props.email)
      .then((res) => {
        setUrlPass(res.data.url);
        setModalOpen(true);
        setIsOpen(false);
      })
      .catch((err) => {
        console.error(err);
        setIsOpen(false);
      });
  };

  return (
    <>
      <PasswordModalPage
        open={modalOpen}
        url={urlPass}
        whatsapp={props.whatsapp}
        title="Número de páginas"
        confirmButtonText="Copiar"
        handler={() => setModalOpen(false)}
      />
      {!props?.sellerUser ? (
        <IconButton ref={ref} onClick={() => setIsOpen(true)}>
          <Icon icon={moreHorizontalFill} width={20} height={20} />
        </IconButton>
      ) : (
        <Button
          onClick={edit}
          color="secondary"
          disabled={props?.disabled}
          sx={{ color: "text.secondary", marginLeft: -1.5 }}
        >
          <Icon icon={editFill} width={24} height={24} />
        </Button>
      )}

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem sx={{ color: "text.secondary" }} onClick={alertRemove}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Deletar"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem onClick={edit} sx={{ color: "text.secondary" }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Editar"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem onClick={passwordRecovery} sx={{ color: "text.secondary" }}>
          <ListItemIcon>
            <Icon icon={lockFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Redefinir Senha"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>

      <ClientDialog
        open={isDeleted}
        id={props.id}
        title="Remover Cliente"
        description="Tem certeza que deseja remover este cliente?"
        cancelButtonText="Cancelar"
        confirmButtonText="Remover"
        action={() => removeAlert()}
        handler={() => setIsDeleted(false)}
      />

      {showSuccess && (
        <AlertMessage open={true} message="Sucesso ao remover cliente!" />
      )}
      {showError && (
        <AlertMessage open={true} message="Erro ao efetuar remoção" />
      )}
    </>
  );
}
