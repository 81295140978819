/* eslint-disable no-const-assign */
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography, Container, Button } from "@mui/material";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";

import Page from "../../../../components/Page";
import FormDataCollab from "../components/FormDataCollab";
// import FormFinancial from "../components/FormFinancial";
import AlertMessage from "../../../../components/alert/alert";

import sellerService from "../services/sellerService";
import { useData } from "../../../../contexts/client";

export default function NewSeller() {
  const { state }: any = useLocation();

  const navigate = useNavigate();
  const [showError, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Erro ao efetuar cadastro");
  const { colabData } = useData();

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  const saveSeller = () => {
    setSubmitting(true);
    sellerService
      .saveCollaborator(colabData)
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
        navigate("/dashboard/colaboradores", { replace: true });
      })
      .catch((error) => {
        if (
          error.response.data.message ===
          "Validation error: Invalid CPF format!"
        )
          setErrorMessage("O formato do CPF está incorreto");
        else if (
          error.response.data.message ===
          "Email already registered in the database!"
        )
          setErrorMessage("Email já cadastrado");
        else setErrorMessage("Erro ao efetuar cadastro");
        setSubmitting(false);
        setError(true);
      });
  };

  const updateSeller = () => {
    setSubmitting(true);
    sellerService
      .updateCollaborator(state.id_User, colabData)
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
        navigate("/dashboard/colaboradores", { replace: true });
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
        setError(true);
      });
  };

  return (
    <Page title="Colaboradores - Cadastro | Optools">
      <Container>
        <Stack direction="row" justifyContent="flex-start" mb={1}>
          <Typography variant="h3" gutterBottom>
            Colaboradores
          </Typography>
        </Stack>

        {
          <FormDataCollab
            setValid={(state: boolean) => setIsValid(state)}
            isValid={isValid}
          />
        }

        <Stack direction="row" justifyContent="flex-end" mt={3}>
          <Button
            sx={{
              paddingY: "12px",
              borderRadius: "40px",
              background: "transparent",
              marginBottom: "0px",
              color: "#004A78",
              marginRight: "10px",
              minWidth: "100px",
            }}
            component={RouterLink}
            to="/dashboard/colaboradores"
            variant="outlined"
            color="secondary"
          >
            Voltar
          </Button>
          {state ? (
            <LoadingButton
              color="secondary"
              variant="contained"
              sx={{
                borderRadius: "30px",
                minWidth: "150px",
                background: "#004A78",
              }}
              loading={isSubmitting}
              onClick={updateSeller}
            >
              Atualizar
            </LoadingButton>
          ) : undefined}
          {!state && (
            <LoadingButton
              color="secondary"
              variant="contained"
              disabled={!isValid}
              sx={{
                borderRadius: "30px",
                minWidth: "150px",
                background: "#004A78",
              }}
              loading={isSubmitting}
              onClick={isValid ? saveSeller : () => {}}
            >
              Confirmar
            </LoadingButton>
          )}
        </Stack>
        {showSuccess && (
          <AlertMessage open={true} message="Cliente registrado com sucesso!" />
        )}
        {showError && <AlertMessage open={true} message={errorMessage} />}
      </Container>
    </Page>
  );
}
