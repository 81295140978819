import { useState } from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";

export default function AlertMessage(props: any) {
  const [open, setOpen] = useState(props.open);

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        message={props.message}
        action={
          <>
            <Button
              color="secondary"
              size="small"
              onClick={() => setOpen(false)}
            >
              Fechar
            </Button>
          </>
        }
      />
    </div>
  );
}
