/* eslint-disable camelcase */
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../../../../components/Page";
import TagsOptions from "../components/TagsOptions";
import Scrollbar from "../../../../components/Scrollbar";
import TagsListHead from "../components/TagsListHead";
import TagsListToolbar from "../components/TagsListToolbar";

import tagService from "../services/tagService";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name_Customer", label: "Cliente", alignRight: false },
  { id: "totalCustomer", label: "Data de Criação", alignRight: false },
  { id: "id_Customer", label: "ID do Cliente", alignRight: false },
  { id: "totalSent", label: "Total Enviada", alignRight: false },
  { id: "totalUsed", label: "Total Utilizadas", alignRight: false },
  { id: "last_OrderCodeFile", label: "Código Última OS", alignRight: false },
  { id: "", label: "Download" },
];

// ----------------------------------------------------------------------

export default function Tags() {
  const [page, setPage] = useState(0);
  const [data, setData]: any = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [params, setParams]: any = useState({ value: "" });
  const [notFirstQuery, setNotFirstQuery] = useState(false);
  const [queryStartEnd, setQueryStartEnd] = useState([
    "0",
    rowsPerPage.toString(),
  ]);

  useEffect(() => {
    tagService
      .getAll(queryStartEnd[0], queryStartEnd[1], params)
      .then((data: any) => setData(data.data))
      .catch((error: any) => console.error(error));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryStartEnd]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        notFirstQuery
          ? tagService
              .getAll(queryStartEnd[0], queryStartEnd[1], params)
              .then((data: any) => setData(data.data))
              .catch((error: any) => console.error(error))
          : setNotFirstQuery(true),
      500
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const handleChangePage = (event: any, newPage: any) => {
    const finalPage = newPage > 0 ? page + 1 : page - 1;
    setQueryStartEnd([
      ((finalPage + 1) * rowsPerPage - rowsPerPage).toString(),
      rowsPerPage.toString(),
    ]);
    setPage(finalPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const finalRowsPerPage = parseInt(event.target.value);
    setQueryStartEnd([
      ((page + 1) * finalRowsPerPage - finalRowsPerPage).toString(),
      finalRowsPerPage.toString(),
    ]);
    setRowsPerPage(finalRowsPerPage);
    setPage(0);
  };

  return (
    <Page title="Etiquetas | Optools">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            Gerenciamento de Etiquetas
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <TagsListToolbar
            filterName={params.value}
            onFilterName={(event: any) => {
              setPage(0);
              setQueryStartEnd(["0", rowsPerPage.toString()]);
              setParams({ ...params, value: event.target.value });
            }}
          />
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TagsListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {data.length ? (
                    data.slice(0, rowsPerPage).map((row: any) => {
                      const {
                        id_Customer,
                        name_Customer,
                        totalSent,
                        totalUsed,
                        initials_Customer,
                        date_OrderCodeFile,
                        last_OrderCodeFile,
                        first_OrderCodeFile,
                        filepath_OrderCodeFile,
                      } = row;

                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          role="checkbox"
                          key={id_Customer}
                        >
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ color: "#004A78", fontSize: "14px" }}
                            >
                              {name_Customer}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="body1"
                              noWrap
                              sx={{ color: "#ABABAB", fontSize: "14px" }}
                            >
                              {date_OrderCodeFile || "Sem data para exibir"}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ color: "#004A78", fontSize: "14px" }}
                            >
                              {initials_Customer}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ color: "#004A78", fontSize: "14px" }}
                            >
                              {totalSent}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ color: "#004A78", fontSize: "14px" }}
                            >
                              {totalUsed}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ color: "#004A78", fontSize: "14px" }}
                            >
                              {first_OrderCodeFile} - {last_OrderCodeFile}
                            </Typography>
                          </TableCell>

                          <TableCell align="left" sx={{ pl: "30px" }}>
                            <TagsOptions
                              id={id_Customer}
                              link={filepath_OrderCodeFile}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                        <Stack
                          width="100%"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Icon
                            icon={inboxOutline}
                            width="56px"
                            color="#909090"
                          />
                          <Typography
                            mt={1}
                            variant="body1"
                            noWrap
                            sx={{ color: "#909090" }}
                          >
                            Nenhum item encontrado...
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            page={0}
            component="div"
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[20, 40, 60]}
            backIconButtonProps={{ disabled: !page }}
            labelRowsPerPage="Linhas por página:"
            nextIconButtonProps={{
              disabled:
                (page + 1) * rowsPerPage >= data[0]?.totalCustomer ||
                data.length < rowsPerPage,
            }}
            labelDisplayedRows={(rowsInfo) => {
              const totalPages = Math.ceil(
                rowsInfo.count / rowsPerPage
              ).toString();
              return (
                <>
                  {`Página: ${page + 1}${
                    !params.value.length
                      ? `/${totalPages === "0" ? "1" : totalPages}`
                      : ""
                  }`}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {!params.value.length ? `Total: ${rowsInfo.count}` : ""}
                </>
              );
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={data?.length ? data[0].totalCustomer : 0}
          />
        </Card>
      </Container>
    </Page>
  );
}
