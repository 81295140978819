import * as Yup from "yup";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";

// material
import { Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// components
import AlertMessage from "../../../../components/alert/alert";

// services
import authService from "../../authService";

export default function RequestPasswordForm() {
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [showErrorEmail, setErrorEmail] = useState(false);

  const RequestPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Endereço de e-mail inválido")
      .required("E-mail é obrigatório"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: RequestPasswordSchema,
    onSubmit: (values, actions) => {
      authService
        .forgotPassword(values)
        .then(() => {
          setSuccess(true);
          actions.setSubmitting(false);
          actions.resetForm();
        })
        .catch((err) => {
          if (err.response.status === 406) {
            setErrorEmail(true);
          }
          setError(true);
          actions.setSubmitting(false);
        });
    },
  });

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
    if (showErrorEmail) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess, showErrorEmail]);

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="E-mail"
            {...getFieldProps("email")}
            variant="filled"
            color="secondary"
            sx={{
              background: "#E2E2E2",
              marginBottom: "10px",
            }}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <Typography
            variant="body1"
            sx={{
              px: 5,
              color: "#8AACC2",
              fontSize: "0.8rem",
              textAlign: "center",
            }}
          >
            Um e-mail de recuperação de senha será enviado em até X minutos.
          </Typography>
        </Stack>

        <LoadingButton
          size="large"
          color="secondary"
          variant="outlined"
          component={RouterLink}
          to="/entrar"
          sx={{ borderRadius: "30px", marginTop: "30px", float: "left" }}
        >
          Voltar
        </LoadingButton>
        <LoadingButton
          size="large"
          type="submit"
          color="secondary"
          variant="contained"
          sx={{
            borderRadius: "30px",
            marginTop: "30px",
            float: "right",
            minWidth: "250px",
          }}
          loading={isSubmitting}
        >
          Recuperar senha
        </LoadingButton>
      </Form>
      {showSuccess && (
        <AlertMessage open={true} message="Solicitação enviada com sucesso!" />
      )}
      {showError && (
        <AlertMessage
          open={true}
          message="Erro ao efetuar solicitação. Tente novamente mais tarde"
        />
      )}
      {showErrorEmail && (
        <AlertMessage
          open={true}
          message="O e-mail não existe, tente novamente com um e-mail cadastrado no sistema!"
        />
      )}
    </FormikProvider>
  );
}
