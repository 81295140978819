import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import authService from "../../authService";
import AlertMessage from "../../../../components/alert/alert";

export default function ResetPasswordForm() {
  const navigate = useNavigate();
  const { token }: any = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [showErrorPassword, setErrorPassword] = useState(false);

  const ResetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().required("Senha é obrigatória"),
    confirmNewPassword: Yup.string().required("Senha é obrigatória"),
  });

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
    if (showErrorPassword) setTimeout(() => setErrorPassword(false), 2000);
  }, [showError, showSuccess, showErrorPassword]);

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, actions) => {
      if (values.newPassword === values.confirmNewPassword) {
        authService
          .resetPassword(values, token)
          .then(() => {
            setSuccess(true);
            actions.setSubmitting(false);
            actions.resetForm();
            navigate("/entrar");
          })
          .catch((err) => {
            setError(true);
            actions.setSubmitting(false);
          });
      } else {
        setErrorPassword(true);
        actions.setSubmitting(false);
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Senha"
            {...getFieldProps("newPassword")}
            variant="filled"
            color="secondary"
            sx={{
              background: "#E2E2E2",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Senha"
            {...getFieldProps("confirmNewPassword")}
            variant="filled"
            color="secondary"
            sx={{
              background: "#E2E2E2",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(
              touched.confirmNewPassword && errors.confirmNewPassword
            )}
            helperText={touched.confirmNewPassword && errors.confirmNewPassword}
          />

          <Typography
            variant="body1"
            sx={{
              px: 5,
              color: "#8AACC2",
              fontSize: "0.8rem",
              textAlign: "center",
            }}
          >
            Sua senha precisa ter no mínimo 8 caracteres, incluindo letras e
            números.
          </Typography>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          color="secondary"
          variant="contained"
          sx={{ borderRadius: "30px", marginTop: "30px" }}
          loading={isSubmitting}
        >
          Salvar
        </LoadingButton>
      </Form>
      {showSuccess && (
        <AlertMessage open={true} message="Senha trocado com sucesso!" />
      )}
      {showError && (
        <AlertMessage
          open={true}
          message="Erro ao efetuar solicitação. Tente novamente mais tarde"
        />
      )}
      {showErrorPassword && (
        <AlertMessage
          open={true}
          message="As senhas não são iguais. Verifique e tente novamente!"
        />
      )}
    </FormikProvider>
  );
}
