import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import IpsModal from "../components/IpsModal";
import editFill from "@iconify/icons-eva/edit-fill";
// material
import {
  Grid,
  Card,
  Stack,
  Avatar,
  Container,
  Typography,
  CardActions,
  CardContent,
  IconButton,
} from "@mui/material";
// components
import Page from "../../../../components/Page";
import TodayIcon from "@mui/icons-material/Today";

import StyleIcon from "@mui/icons-material/Style";
import GestureIcon from "@mui/icons-material/Gesture";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import LanguageIcon from "@mui/icons-material/Language";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import PhotoFilterIcon from "@mui/icons-material/PhotoFilter";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AdministrationModal from "../components/AdministrationModal";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";

export const STYLEICON = { width: "70px", height: "70px" };

export const ADMINISTRACAO = [
  {
    id: 1,
    name: "Material",
    nameProp: "name_Material",
    initials_Material: "initials_Material",
    nameWrite: "materiais",
    icon: function () {
      return <StyleIcon sx={STYLEICON} />;
    },
    endPoint: "/material",
  },
  {
    id: 2,
    name: "Fabricante",
    nameProp: "name_Maker",
    nameWrite: "fabricantes",
    icon: function () {
      return <StoreMallDirectoryIcon sx={STYLEICON} />;
    },
    endPoint: "/maker",
  },
  {
    id: 3,
    name: "Prazo",
    nameProp: "name_Deadline",
    days_Deadline: "days_Deadline",
    nameWrite: "mensagens de prazos",
    icon: function () {
      return <TodayIcon sx={STYLEICON} />;
    },
    endPoint: "/deadline",
  },
  {
    id: 4,
    name: "Retrabalho",
    nameProp: "name_Rework",
    cost_Rework: "cost_Rework",
    deadline_Rework: "deadline_Rework",
    nameWrite: "mensagens de retrabalho",
    icon: function () {
      return <RotateLeftIcon sx={STYLEICON} />;
    },
    endPoint: "/rework",
  },
  {
    id: 5,
    name: "Estágio",
    nameProp: "name_Stage",
    nameWrite: "mensagens de estagio",
    icon: function () {
      return <DynamicFeedIcon sx={STYLEICON} />;
    },
    endPoint: "/stage",
  },
  {
    id: 6,
    name: "Foco",
    nameProp: "name_Focus",
    nameWrite: "mensagens de foco",
    icon: function () {
      return <GpsFixedIcon sx={STYLEICON} />;
    },
    endPoint: "/focus",
  },
  {
    id: 7,
    name: "Desenho",
    nameProp: "name_Design",
    nameWrite: "desenhos",
    icon: function () {
      return <GestureIcon sx={STYLEICON} />;
    },
    endPoint: "/design",
  },
  {
    id: 8,
    name: "Lado(Hard)",
    nameProp: "name_Hard",
    nameWrite: "mensagens de hard",
    icon: function () {
      return <ClearAllIcon sx={STYLEICON} />;
    },
    endPoint: "/hard",
  },
  {
    id: 9,
    name: "MSG Perda",
    nameProp: "name_Message",
    type_Message: "Perda",
    nameWrite: "mensagens de perda",
    icon: function () {
      return <LocalPostOfficeIcon sx={STYLEICON} />;
    },
    endPoint: "/message",
  },
  {
    id: 10,
    name: "MSG Retrabalho",
    nameProp: "name_Message",
    type_Message: "Retrabalho",
    nameWrite: "mensagens de retrabalho",
    icon: function () {
      return <LocalPostOfficeIcon sx={STYLEICON} />;
    },
    endPoint: "/message",
  },
  {
    id: 11,
    name: "MSG Reprovada",
    nameProp: "name_Message",
    type_Message: "Reprovada",
    nameWrite: "mensagens de reprovado",
    icon: function () {
      return <LocalPostOfficeIcon sx={STYLEICON} />;
    },
    endPoint: "/message",
  },
  {
    id: 12,
    name: "Fotossensibilidade",
    nameProp: "name_Photosensitivity",
    nameWrite: "mensagem de fotossensibilidade",
    icon: function () {
      return <PhotoFilterIcon sx={STYLEICON} />;
    },
    endPoint: "/photosensitivity",
  },
  {
    id: 13,
    name: "Bloco",
    nameProp: "name_Block",
    nameWrite: "mensagens de bloco",
    icon: function () {
      return <ViewAgendaIcon sx={STYLEICON} />;
    },
    endPoint: "/block",
  },
  {
    id: 14,
    name: "Empresas",
    nameProp: "name_Company",
    phone_Company: "phone_Company",
    whatsapp_Company: "whatsapp_Company",
    initials_Company: "initials_Company",
    nameWrite: "empresas",
    icon: function () {
      return <ViewAgendaIcon sx={STYLEICON} />;
    },
    endPoint: "/company",
  },
  {
    id: 15,
    name: "Pagamento",
    nameProp: "name_PaymentType",
    nameWrite: "formas de pagamento",
    icon: function () {
      return <AccountBalanceIcon sx={STYLEICON} />;
    },
    endPoint: "/paymentType",
  },
];

export default function Administration() {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData]: any = useState();
  const [data, setData]: any = useState(ADMINISTRACAO);
  const [ipModalOpen, setIpModalOpen] = useState(false);

  useEffect(() => {
    setData(ADMINISTRACAO);
  }, [setData]);

  return (
    <Page title="Administação | Optools">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginBottom={2}
        >
          <Typography variant="h4" gutterBottom>
            Administação
          </Typography>
        </Stack>

        <AdministrationModal
          title={modalData?.name}
          open={modalOpen}
          confirmButtonText="Concluir"
          modalData={modalData}
          setModalData={(value: any[]) => setModalData(value)}
          handler={() => setModalOpen(false)}
        />

        <IpsModal open={ipModalOpen} handler={() => setIpModalOpen(false)} />

        <Grid container spacing={5}>
          {data.map((row: any) => {
            const { name, id, icon } = row;

            return (
              <Grid key={id} item xs={12} sm={6} md={4} lg={3}>
                <Card sx={{ justifyContent: "center", alignItems: "center" }}>
                  <CardContent
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      paddingBottom: "5px",
                    }}
                  >
                    <Avatar
                      alt={name}
                      sx={{
                        width: "112px",
                        height: "112px",
                        backgroundColor: "#008AC7",
                        opacity: "50%",
                      }}
                    >
                      {icon()}
                    </Avatar>

                    <Typography
                      mt={1}
                      variant="h4"
                      noWrap
                      sx={{
                        color: "#303972",
                        marginBottom: "30px",
                        marginTop: "10px",
                      }}
                    >
                      {name}
                    </Typography>
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "12px",
                    }}
                  >
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setModalData(row);
                        setModalOpen(true);
                      }}
                      sx={{
                        marginRight: "8px",
                        background: "#004A78",
                        color: "#FFF",
                        "&:hover": {
                          bgcolor: "#115B89",
                        },
                      }}
                    >
                      <Icon icon={editFill} />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card sx={{ justifyContent: "center", alignItems: "center" }}>
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingBottom: "5px",
                }}
              >
                <Avatar
                  alt="IPs"
                  sx={{
                    width: "112px",
                    height: "112px",
                    backgroundColor: "#008AC7",
                    opacity: "50%",
                  }}
                >
                  <LanguageIcon sx={STYLEICON} />
                </Avatar>

                <Typography
                  mt={1}
                  variant="h4"
                  noWrap
                  sx={{
                    color: "#303972",
                    marginBottom: "30px",
                    marginTop: "10px",
                  }}
                >
                  IPs
                </Typography>
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "12px",
                }}
              >
                <IconButton
                  color="primary"
                  onClick={() => {
                    setIpModalOpen(true);
                  }}
                  sx={{
                    marginRight: "8px",
                    background: "#004A78",
                    color: "#FFF",
                    "&:hover": {
                      bgcolor: "#115B89",
                    },
                  }}
                >
                  <Icon icon={editFill} />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
