import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

export default function ProfilePhoto(props: any) {
  async function getPhoto(file: any) {
    const files: any[] = file.target.files;
    await props.setFileFire(files);
    const url: any = URL.createObjectURL(file.target.files[0]);
    props.setImageFire(url);
  }

  return props.imageFire === "" ? (
    <Box
      component="span"
      sx={{
        border: "4px solid #E3DDFD",
        justifyContent: "center",
        borderRadius: "50px",
        marginTop: "-86px",
        display: "flex",
        width: "128px",
        height: "128px",
        zIndex: "20",
      }}
    >
      <Button
        fullWidth
        sx={{
          textAlign: "center",
          borderRadius: "46px",
          py: 6,
          px: 2,
          backgroundColor: "#8AACC2",
          color: "#FFF",
          "&:hover": {
            bgcolor: "#9BBDD3",
          },
        }}
        variant="text"
        color="secondary"
        component="label"
      >
        + Adicionar Imagem
        <input accept="image/*" type="file" hidden onChange={getPhoto} />
      </Button>
    </Box>
  ) : (
    <Box
      sx={{
        justifyContent: "center",
        marginTop: "-86px",
        display: "flex",
        width: "128px",
        zIndex: "20",
      }}
    >
      <input
        disabled
        accept="image/*"
        onChange={getPhoto}
        hidden
        id="icon-button-file"
        type="file"
      />
      <label htmlFor="icon-button-file">
        <IconButton
          disabled
          color="primary"
          aria-label="upload picture"
          component="span"
          sx={{ p: 0 }}
        >
          <Avatar
            variant="rounded"
            sx={{ width: "128px", height: "128px", borderRadius: "50px" }}
            src={props.imageFire}
          />
        </IconButton>
      </label>
    </Box>
  );
}
