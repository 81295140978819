import http from "../../services/http-common";

class AuthService {
  // eslint-disable-next-line class-methods-use-this
  authentication(data: any) {
    delete data.remember;

    return http.post("/user/auth", data);
  }

  // eslint-disable-next-line class-methods-use-this
  forgotPassword(data: any) {
    return http.patch("/user/forgot/password", data);
  }

  // eslint-disable-next-line class-methods-use-this
  resetPassword(data: any, token: string) {
    return http.patch("/user/reset/password", { password: data.newPassword, token: token });
  }
}

export default new AuthService();
