/* eslint-disable no-const-assign */
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useLocation } from "react-router-dom";
import { Stack, Button, Container } from "@mui/material";

import Page from "../../../../components/Page";
import FormGroup from "../components/FormGroup";
import AlertMessage from "../../../../components/alert/alert";

import groupService from "../services/groupService";
import { useData } from "../../../../contexts/client";

export default function NewGroup() {
  const { state }: any = useLocation();

  const navigate = useNavigate();
  const [showError, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { groupData } = useData();

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  const saveGroup = () => {
    setSubmitting(true);
    groupService
      .saveGroup(groupData)
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
        if (state?.fromRegister)
          navigate("/dashboard/clientes/cadastrar", {
            state: { ...state.values, fromGroups: true },
          });
        else navigate("/dashboard/grupos", { replace: true });
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
        setError(true);
      });
  };

  const updateGroup = () => {
    setSubmitting(true);
    groupService
      .updateGroup(state.id_Team, groupData)
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
        navigate("/dashboard/grupos", { replace: true });
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
        setError(true);
      });
  };

  return (
    <Page title="Clientes - Cadastro | Optools">
      <Container>
        <FormGroup
          setValid={(stateBol: boolean) => setIsValid(stateBol)}
          isValid={isValid}
        />

        <Stack direction="row" justifyContent="flex-end" mt={3}>
          <Button
            sx={{
              height: "60px",
              borderRadius: "40px",
              background: "transparent",
              marginBottom: "0px",
              color: "#004A78",
              marginRight: "10px",
              minWidth: "100px",
            }}
            onClick={() => {
              if (state?.fromRegister)
                navigate("/dashboard/clientes/cadastrar", {
                  state: { ...state.values, fromGroups: true },
                });
              else navigate("/dashboard/grupos");
            }}
            variant="outlined"
            color="secondary"
          >
            VOLTAR
          </Button>
          {state?.name_Team ? (
            <LoadingButton
              color="secondary"
              disabled={!isValid}
              variant="contained"
              onClick={isValid ? updateGroup : () => {}}
              loading={isSubmitting}
              sx={{
                borderRadius: "30px",
                minWidth: "150px",
                background: "#004A78",
              }}
            >
              Atualizar
            </LoadingButton>
          ) : (
            <LoadingButton
              color="secondary"
              variant="contained"
              disabled={!isValid}
              loading={isSubmitting}
              onClick={isValid ? saveGroup : () => {}}
              sx={{
                borderRadius: "30px",
                minWidth: "150px",
                background: "#004A78",
              }}
            >
              {state?.fromRegister ? "CONFIRMAR E VOLTAR" : "CONFIRMAR"}
            </LoadingButton>
          )}
        </Stack>
      </Container>
      {showSuccess && (
        <AlertMessage open={true} message="Grupo registrado com sucesso!" />
      )}
      {showError && (
        <AlertMessage open={true} message="Erro ao efetuar registro" />
      )}
    </Page>
  );
}
