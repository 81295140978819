import PropTypes from "prop-types";
// material
import { TableRow, TableCell, TableHead } from "@mui/material";

// ----------------------------------------------------------------------

OrdersListHead.propTypes = {
  headLabel: PropTypes.array,
};

export default function OrdersListHead({ headLabel }: any) {
  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell: any) => (
          <TableCell
            key={headCell.id}
            sx={{ color: "#004A78", fontSize: "13px" }}
            align={headCell.alignRight ? "right" : "left"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
