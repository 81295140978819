/* eslint-disable no-const-assign */
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography, Button, Container } from "@mui/material";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";

import Page from "../../../../components/Page";
import FormDataJur from "../components/FormDataJur";
import FormDataFis from "../components/FormDataFis";
import AlertMessage from "../../../../components/alert/alert";

import clientService from "../services/clientService";
import { useData } from "../../../../contexts/client";

export default function NewClient() {
  const { state }: any = useLocation();

  const navigate = useNavigate();
  const [showError, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [clientType, setClientType] = useState("");
  const [showSuccess, setSuccess] = useState(false);
  const [sellerUser, setSellerUser] = useState(true);
  const [paymentTypes, setPaymentTypes] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [showButtons, setShowButtons] = useState(true);
  const [errorMessage, setErrorMessage] = useState("Erro ao efetuar registro");
  const { clientDataFis, clientDataJur } = useData();

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  useEffect(() => {
    if (state) {
      if (state.personType_Customer === "F") {
        setClientType("fis");
      } else {
        setClientType("jur");
      }
      setShowButtons(false);
    } else {
      setClientType("jur");
    }
  }, [state]);

  useEffect(() => {
    clientService
      .getPaymentTypes()
      .then((res) => setPaymentTypes(res.data))
      .catch((error) => console.log(error));

    clientService
      .isSellerUser()
      .then((res) => setSellerUser(res))
      .catch((error) => console.log(error));
  }, []);

  const saveFisCustomer = (update: boolean) => {
    const customerId = update ? state.id_Customer : undefined;

    setSubmitting(true);
    clientService
      .saveFisCustomer(clientDataFis, customerId)
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
        navigate("/dashboard/clientes", { replace: true });
      })
      .catch((error) => {
        if (
          error.response.data.message ===
          "Validation error: Invalid CPF format!"
        )
          setErrorMessage("O formato do CPF está incorreto");
        else if (
          error.response.data.message ===
          "Email already registered in the database!"
        )
          setErrorMessage("Email já cadastrado");
        else if (
          error.response.data.message ===
          "Initials already registered in the database!"
        )
          setErrorMessage("Código de cliente já cadastrado");
        else setErrorMessage("Erro ao efetuar cadastro");
        setSubmitting(false);
        setError(true);
      });
  };

  const saveJurCustomer = (update: boolean) => {
    const customerId = update ? state.id_Customer : undefined;

    setSubmitting(true);
    clientService
      .saveJurCustomer(clientDataJur, customerId)
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
        navigate("/dashboard/clientes", { replace: true });
      })
      .catch((error) => {
        if (
          error.response.data.message ===
          "Validation error: Invalid CNPJ format!"
        )
          setErrorMessage("O formato do CNPJ está incorreto");
        else if (
          error.response.data.message ===
          "Email already registered in the database!"
        )
          setErrorMessage("Email já cadastrado");
        else if (
          error.response.data.message ===
          "Initials already registered in the database!"
        )
          setErrorMessage("Código de cliente já cadastrado");
        else setErrorMessage("Erro ao efetuar cadastro");
        setSubmitting(false);
        setError(true);
      });
  };

  return (
    <Page title="Clientes - Cadastro | Optools">
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h3" gutterBottom>
            Clientes
          </Typography>
          {showButtons && (
            <Stack width="38%" direction="row" justifyContent="flex-end" mb={1}>
              <Button
                sx={{
                  width: "60%",
                  height: "54px",
                  marginRight: "12px",
                  border: "2px solid #004A78",
                  color: clientType === "jur" ? "#FEFEFE" : "#004A78",
                  bgcolor: clientType === "jur" ? "#004A78" : "transparent",
                  "&:hover": {
                    color: "#FEFEFE",
                    bgcolor: "#004A78",
                    border: "2px solid #004A78",
                  },
                }}
                color="secondary"
                variant="outlined"
                onClick={() => setClientType("jur")}
              >
                Pessoa Jurídica
              </Button>
              <Button
                sx={{
                  width: "60%",
                  height: "54px",
                  marginRight: "12px",
                  border: "2px solid #004A78",
                  color: clientType === "fis" ? "#FEFEFE" : "#004A78",
                  bgcolor: clientType === "fis" ? "#004A78" : "transparent",
                  "&:hover": {
                    color: "#FEFEFE",
                    bgcolor: "#004A78",
                    border: "2px solid #004A78",
                  },
                }}
                variant="outlined"
                onClick={() => setClientType("fis")}
              >
                Pessoa Física
              </Button>
            </Stack>
          )}
        </Stack>

        {clientType === "jur" && (
          <FormDataJur
            isValid={isValid}
            sellerUser={sellerUser}
            paymentTypes={paymentTypes}
            setValid={(stateBol: boolean) => setIsValid(stateBol)}
          />
        )}
        {clientType === "fis" && (
          <FormDataFis
            isValid={isValid}
            sellerUser={sellerUser}
            paymentTypes={paymentTypes}
            setValid={(stateBol: boolean) => setIsValid(stateBol)}
          />
        )}

        <Stack direction="row" justifyContent="flex-end" mt={3}>
          <Button
            sx={{
              height: "60px",
              borderRadius: "40px",
              background: "transparent",
              marginBottom: "0px",
              color: "#004A78",
              marginRight: "10px",
              minWidth: "100px",
            }}
            component={RouterLink}
            to="/dashboard/clientes"
            variant="outlined"
            color="secondary"
          >
            Voltar
          </Button>
          {state?.id_Customer ? (
            <LoadingButton
              color="secondary"
              variant="contained"
              disabled={!isValid}
              loading={isSubmitting}
              sx={{
                borderRadius: "30px",
                minWidth: "150px",
                background: "#004A78",
              }}
              onClick={
                clientType === "fis"
                  ? () => saveFisCustomer(true)
                  : () => saveJurCustomer(true)
              }
            >
              Atualizar
            </LoadingButton>
          ) : (
            <LoadingButton
              color="secondary"
              variant="contained"
              disabled={!isValid}
              sx={{
                borderRadius: "30px",
                minWidth: "150px",
                background: "#004A78",
              }}
              loading={isSubmitting}
              onClick={
                isValid
                  ? clientType === "fis"
                    ? () => saveFisCustomer(false)
                    : () => saveJurCustomer(false)
                  : () => {}
              }
            >
              Confirmar
            </LoadingButton>
          )}
        </Stack>
      </Container>
      {showSuccess && (
        <AlertMessage open={true} message="Cliente registrado com sucesso!" />
      )}
      {showError && <AlertMessage open={true} message={errorMessage} />}
    </Page>
  );
}
