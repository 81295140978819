/* eslint-disable class-methods-use-this */
import http from "./http-common";

class ZipcodeService {
  getAddress(zipcode: any) {
    return http.get(`integrations/viacep/${zipcode}`);
  }
}

export default new ZipcodeService();
