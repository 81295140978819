/* import { LoadingButton } from "@mui/lab"; */
import {
  CardActions,
  CardContent,
  Typography,
  Modal,
  Card,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import AlertMessage from "../../../../components/alert/alert";
import { useState, useEffect } from "react";

interface ModalProps {
  title: any;
  open: boolean;
  handler: () => void;
  confirmButtonText: string;
  url: string;
  whatsapp: any;
}

export default function PasswordModalPage(props: ModalProps) {
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] = useState(false);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  const clipBoardUrl = () => {
    navigator.clipboard.writeText(props.url).then(
      function () {
        setSuccess(true);
        props.handler();
      },
      function (err) {
        setError(true);
        props.handler();
      }
    );
  };

  const sendWhatsapp = () => {
    const url =
      "https://api.whatsapp.com/send?phone=55" +
      props.whatsapp +
      `&text=Para%20trocar%20a%20senha%20no%20Optools%2C%20clique%20no%20link%3A\n ${props.url}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          props.handler();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ minWidth: "30vw" }}>
          <CardContent>
            <Typography
              variant="h4"
              marginBottom={4}
              color="#004A78"
              gutterBottom
            >
              Redefinição de Senha
            </Typography>
            <Typography variant="h6" component="h2">
              Clique no botão abaixo para copiar o link de redefinição de senha
            </Typography>
          </CardContent>
          <CardActions>
            <LoadingButton
              fullWidth
              onClick={clipBoardUrl}
              loading={false}
              disabled={false}
              variant="contained"
              sx={{
                m: 1,
                height: "48px",
                borderRadius: "30px",
                backgroundColor: "#CDCDCD",
                color: "#004A78",
                "&:hover": {
                  bgcolor: "#004A78",
                  color: "#FEFEFE",
                },
              }}
              autoFocus
            >
              {props.confirmButtonText}
            </LoadingButton>
            <LoadingButton
              fullWidth
              onClick={sendWhatsapp}
              loading={false}
              disabled={false}
              variant="contained"
              sx={{
                m: 1,
                height: "48px",
                borderRadius: "30px",
                backgroundColor: "#CDCDCD",
                color: "#004A78",
                "&:hover": {
                  bgcolor: "#004A78",
                  color: "#FEFEFE",
                },
              }}
              autoFocus
            >
              Enviar para o Whatsapp
            </LoadingButton>
          </CardActions>
        </Card>
      </Modal>
      {showSuccess && (
        <AlertMessage open={true} message="A URL foi copiada com sucesso" />
      )}
      {showError && (
        <AlertMessage open={true} message="Houve um erro, tento novamente" />
      )}
    </>
  );
}
