import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// material
import { styled } from "@mui/system";
import {
  Box,
  Stack,
  Button,
  Select,
  Toolbar,
  MenuItem,
  InputLabel,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import stageService from "../services/stageService";
import Grid from "@mui/material/Unstable_Grid2";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  float: "left",
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 0, 0, 0),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }: any) => ({
  height: 48,
  float: "left",
  borderRadius: "40px",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const DatePicker = styled(OutlinedInput)(({ theme }: any) => ({
  height: 48,
  float: "left",
  borderRadius: "40px",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

OrdersListToolbar.propTypes = {
  company: PropTypes.string,
  stage: PropTypes.string,
  filterReset: PropTypes.func,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterDate: PropTypes.func,
  onFilterCompany: PropTypes.func,
  onFilterStatus: PropTypes.func,
};

export default function OrdersListToolbar({
  company,
  filterName,
  filterReset,
  onFilterName,
  onFilterDate,
  onFilterCompany,
  onFilterStatus,
  stage
}: any) {
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [stagesData, setStagesData]: any = useState([]);
  const stages = async () => {
    const data = await stageService.getAll()
    setStagesData(data?.data ? data.data : [])
    console.log(stagesData)
  };

  useEffect(() => {
    stages()
  }, []);



  return (
    <>

      <Grid container alignItems={'flex-end'} justifyContent={'flex-start'} spacing={1} py={2}>
        <Grid xs={3}>
          <Stack sx={{ width: '100%' }}>

            <SearchStyle
              sx={{ width: '100%' }}
              type="text"
              value={filterName}
              placeholder="Pesquisar..."
              inputProps={{ style: { textTransform: "uppercase" } }}
              onChange={(event: any) =>
                onFilterName(event.target.value?.toUpperCase())
              }
              startAdornment={
                <InputAdornment position="start">
                  <Box
                    component={Icon}
                    icon={searchFill}
                    sx={{ color: "text.disabled" }}
                  />
                </InputAdornment>
              }
            />
          </Stack>
        </Grid>
        <Grid xs={2.5}>
          <Stack sx={{ width: '100%' }}>
            <Typography
              variant="subtitle2"
              color="secondary"
              sx={{ marginLeft: ["4px", "14px"] }}
            >
              Data inicial:
            </Typography>
            <DatePicker

              type="date"
              value={date1}
              onChange={(event: any) => {
                const value = event.target.value;

                setDate1(value);
                onFilterDate(
                  value.split("-").reverse().join("/"),
                  date2.split("-").reverse().join("/")
                );
              }}
            />
          </Stack>
        </Grid>
        <Grid xs={2.5}>
          <Stack >
            <Typography
              variant="subtitle2"
              color="secondary"
              sx={{ marginLeft: ["4px", "14px"] }}
            >
              Data final:
            </Typography>
            <DatePicker

              type="date"
              value={date2}
              onChange={(event: any) => {
                const value = event.target.value;

                setDate2(value);
                onFilterDate(
                  date1.split("-").reverse().join("/"),
                  value.split("-").reverse().join("/")
                );
              }}
            />
          </Stack>
        </Grid>
        <Grid xs={1.5}>
          <FormControl
            sx={{ width: '100%' }}
          >
            <InputLabel id="type" color="secondary">
              Unidade
            </InputLabel>
            <Select
              fullWidth
              labelId="type"
              color="secondary"
              label="Tipo de Cliente"
              value={company || "ALL"}
              sx={{ borderRadius: "12px" }}
              onChange={(event) => onFilterCompany(event.target.value)}
            >
              <MenuItem value="ALL">Todas</MenuItem>
              <MenuItem value="PA">PA - Porto Alegre</MenuItem>
              <MenuItem value="NE">NE - Fortaleza</MenuItem>
              <MenuItem value="SP">SP - São Paulo</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={1.5}>
          <FormControl
            sx={{ width: '100%' }}
          >
            <InputLabel id="type" color="secondary">
              Status
            </InputLabel>
            <Select
              fullWidth
              labelId="type"
              color="secondary"
              label="Status"
              value={stage || "ALL"}
              sx={{ borderRadius: "12px", }}
              onChange={(event) => onFilterStatus(event.target.value)}
            >
              <MenuItem value="ALL">Todas</MenuItem>
              <MenuItem value="CADASTRADA">CADASTRADA</MenuItem>
              <MenuItem value="RECEBIDA">RECEBIDA</MenuItem>
              {stagesData?.length > 0 && stagesData.map((item: any, index: any) => (
                <MenuItem value={item?.name} key={index}>{item?.name}</MenuItem>
              ))}

            </Select>
          </FormControl>
        </Grid>
        <Grid xs={1} height='100%'>
          <Stack width={'100%'} height='100%' alignItems='center' justifyContent={'center'}>

            <Button
              color="inherit"
              onClick={() => {
                setDate1("");
                setDate2("");
                filterReset();
              }}
            >
              <HighlightOffIcon sx={{ color: "red" }} />
              <Typography variant="body2" color="red" sx={{ ml: "3px" }}>
                LIMPAR
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>

    </>
  );
}
