/* eslint-disable no-const-assign */
import { useState, useEffect, useRef } from "react";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography, Button, Container } from "@mui/material";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";

import Page from "../../../components/Page";
import FormDataOrder from "../components/FormDataOrder";
import AlertMessage from "../../../components/alert/alert";

import { useData } from "../../../contexts/client";
import orderService from "../services/orderService";

export default function NewOrder() {
  const { state }: any = useLocation();

  let loaded = useRef(false);
  const navigate = useNavigate();
  const { orderData } = useData();
  const [showError, setError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [noCredit, setNoCredit] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectData, setSelectData]: any = useState({});

  useEffect(() => {
    const types = [
      "maker",
      "focus",
      "block",
      "photosensitivity",
      "design",
      "material",
    ];

    if (!loaded.current) {
      loaded.current = true;
      types.forEach((item: string) => {
        orderService
          .getSelect(item)
          .then((data) => {
            const info = selectData;
            info[item] = data.data;
            setSelectData(info);
          })
          .catch((err) => console.log(err));

        orderService
          .getLens()
          .then((data) => {
            setSelectData({ ...selectData, lens: data.data });
          })
          .catch((err) => console.log(err));
      });
    }
  }, [selectData]);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  const saveOrder = () => {
    setSubmitting(true);
    orderService
      .saveOrder(orderData)
      .then(() => {
        setSubmitting(false);
        setSuccess(true);
        navigate("/dashboard/cadastrar", { replace: true });
        return orderService.getTag(orderData.code_Order);
      })
      .then((tagResponse) => {
        console.log({ tagResponse });
      })
      .catch((error) => {
        if (error.response.status === 406) {
          setNoCredit(true);
          setError(true);

          setTimeout(() => {
            setError(false);
            setNoCredit(false);
          }, 2000);
        }
        console.error(error);
        setSubmitting(false);
      });
  };

  useEffect(() => {
    // orderService.getTag()
  }, []);

  const updateOrder = () => {
    setSubmitting(true);
    orderService
      .updateOrder({ ...orderData, code_Order: state.id_Order })
      .then(() => {
        setSuccess(true);
        setSubmitting(false);
        navigate("/dashboard/cadastrar", { replace: true });
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
        setError(true);
      });
  };

  return (
    <Page title={state ? "Pedido | Optools" : "Novo Pedido | Optools"}>
      <Container>
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h3" gutterBottom>
            {state
              ? `Pedido: ${state.id_Order}`
              : `Novo Pedido: ${orderData.code_Order}`}
          </Typography>
        </Stack>

        <FormDataOrder
          selects={selectData}
          setValid={(stateBol: boolean) => setIsValid(stateBol)}
          isValid={isValid}
        />

        <Stack direction="row" justifyContent="flex-end" mt={3}>
          <Button
            sx={{
              height: "60px",
              borderRadius: "40px",
              background: "transparent",
              marginBottom: "0px",
              color: "#004A78",
              marginRight: "10px",
              minWidth: "100px",
            }}
            component={RouterLink}
            to="/dashboard/pedidos"
            variant="outlined"
            color="secondary"
          >
            Voltar
          </Button>
          {!state ? (
            <LoadingButton
              color="secondary"
              variant="contained"
              disabled={!isValid}
              sx={{
                borderRadius: "30px",
                minWidth: "150px",
                background: "#004A78",
              }}
              loading={isSubmitting}
              onClick={isValid ? () => saveOrder() : () => {}}
            >
              Confirmar
            </LoadingButton>
          ) : (
            !state?.actions[0]?.date && (
              <LoadingButton
                color="secondary"
                variant="contained"
                disabled={!isValid}
                sx={{
                  borderRadius: "30px",
                  minWidth: "150px",
                  background: "#004A78",
                }}
                loading={isSubmitting}
                onClick={isValid ? () => updateOrder() : () => {}}
              >
                Atualizar
              </LoadingButton>
            )
          )}
        </Stack>
      </Container>
      {showSuccess && (
        <AlertMessage open={true} message="Sucesso na operação!" />
      )}
      {showError && (
        <AlertMessage
          open={true}
          message={
            noCredit
              ? "O usuário não possui crédito suficiente"
              : "Erro ao efetuar registro"
          }
        />
      )}
    </Page>
  );
}
