import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/system";
import { Box, Drawer, Button, Typography } from "@mui/material";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import changeSideBar from "./SidebarConfig";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: any) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [sidebarConfig, SetSidebarConfig] = useState<any>([]);

  const token: any = localStorage.getItem("user");
  const user = JSON.parse(token);

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/entrar", { replace: true });
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const test = changeSideBar(token);
    SetSidebarConfig(test);
  }, [token]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100vh",
        backgroundColor: "#004A78",
        "& .simplebar-content": {
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, pt: 3, pb: 2 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo sx={{ width: "220px" }} />
        </Box>
      </Box>
      <Box
        sx={{ height: "1px", width: "85%", bgcolor: "#fff", mb: 1, mx: "auto" }}
      />
      <Box sx={{ textAlign: "center", px: 4 }}>
        <Typography sx={{ color: "#fff", fontSize: "16px" }}>
          {user.email}
        </Typography>
        <Typography
          sx={{ color: "#fff", fontSize: "16px", marginBottom: "10px" }}
        >
          {user.role.toUpperCase()}
        </Typography>
      </Box>
      <Box
        sx={{ height: "1px", width: "85%", bgcolor: "#fff", mb: 3, mx: "auto" }}
      />

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ p: 2, pt: 1.5 }}>
        <Button
          fullWidth
          color="inherit"
          onClick={logout}
          sx={{
            color: "#fff",
            background: "#FF4433",
            "&:hover": {
              background: "#FF4433",
            },
          }}
        >
          Sair do sistema
        </Button>
      </Box>

      <Box sx={{ textAlign: "center", fontSize: "14px" }}>
        <Typography
          sx={{ color: "#fff", fontSize: "12px", marginBottom: "10px" }}
        >
          Optools - Sistema Operacional
        </Typography>
        <Typography
          sx={{ color: "#fff", fontSize: "10px", marginBottom: "10px" }}
        >
          Desenvolvido por Braint Tech
        </Typography>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
