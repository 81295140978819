/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import * as Yup from "yup";
import { useLocation } from "react-router";
import { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Card,
  Grid,
  Select,
  MenuItem,
  Container,
  TextField,
  Typography,
  CardHeader,
  InputLabel,
  CardContent,
  FormControl,
  InputAdornment,
  Button,
  Stack,
} from "@mui/material";

import ActionCard from "./ActionCard";
import orderService from "../services/orderService";
import isEqual from "lodash.isequal";

type FormState = {
  lensesBrand_Order: string;
  idMaker_Order: string;
  lensesAmount_Order: string;
  idBlock_Order: string;
  idPhotosensitivity_Order: string;
  idDesign_Order: string;
  idLens_Order: string;
  idMaterial_Order: string;
  idFocus_Order: string;

  // Prescription
  rightSpherical_Order: string;
  rightCylindrical_Order: string;
  rightAxis_Order: string;
  leftSpherical_Order: string;
  leftCylindrical_Order: string;
  leftAxis_Order: string;
  leftAddition_Order: string;
  rightAddition_Order: string;

  // Extra
  observation_Order: string;

  idOrderType_Order: string;
  idDeadline_Order: string;
  idCompany_Order: string;
};

function printTag(base64: string) {
  const winparams =
    "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes,resizable,screenX=50,screenY=50,width=850,height=1050";
  const html = `
  <embed
    width=100%
    height=100%
    type="application/pdf"
    src="data:application/pdf;base64,${base64}"
  ></embed>`;
  const printWindow = window.open("", "PDF", winparams);
  if (printWindow) {
    printWindow.document.write(html);
    printWindow.document.close();
    printWindow.addEventListener("load", () => {
      setTimeout(() => {
        printWindow.print();
      }, 2000);
    });
  }
}

export default function FormDataOrder(props: any) {
  const errorAlertHandler = props.errorAlertHandler as React.Dispatch<
    React.SetStateAction<string | null>
  >;
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [ready, setReady] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const initialValues = useRef<FormState | null>(null);
  const [validActions, setValidActions] = useState([]);

  useEffect(() => {
    if (state?.actions)
      if (Object.keys(state?.actions).length)
        setValidActions(state?.actions?.filter((e: any) => e?.date !== null));
  }, [state]);

  useEffect(() => {
    initialValues.current = {
      // Lens Data
      lensesBrand_Order: state ? state.lensesBrand_Order : "",
      idMaker_Order: state?.maker?.id || "",
      lensesAmount_Order: state?.lensesAmount_Order || "",
      idBlock_Order: state?.block?.id || 7,
      idPhotosensitivity_Order: state?.photosensitivity?.id || "",
      idDesign_Order: state?.design?.id || "",
      idLens_Order: state?.lens?.id || "",
      idMaterial_Order: state?.material?.id || "",
      idFocus_Order: state?.focus?.id || "",

      // Prescription
      rightSpherical_Order: state ? state.rightSpherical_Order : "",
      rightCylindrical_Order: state ? state.rightCylindrical_Order : "",
      rightAxis_Order: state ? state.rightAxis_Order : "",
      leftSpherical_Order: state ? state.leftSpherical_Order : "",
      leftCylindrical_Order: state ? state.leftCylindrical_Order : "",
      leftAxis_Order: state ? state.leftAxis_Order : "",
      rightAddition_Order: state ? state.rightAddition_Order : "",
      leftAddition_Order: state ? state.leftAddition_Order : "",

      // Extra
      observation_Order: state?.observation_Order || "",

      idOrderType_Order: state?.orderType?.id || "",
      idDeadline_Order: state?.deadline?.id || "1",
      idCompany_Order: state?.company?.id || "",
    };
  }, [state]);

  const RegisterSchema = Yup.object().shape({
    // Lens Data
    lensesBrand_Order: Yup.string(),
    idMaker_Order: Yup.string().required("Campo obrigatório"),
    lensesAmount_Order: Yup.string().required("Campo obrigatório"),
    idBlock_Order: Yup.string(),
    idPhotosensitivity_Order: Yup.string().required("Campo obrigatório"),
    idDesign_Order: Yup.string().required("Campo obrigatório"),
    idLens_Order: Yup.string().required("Campo obrigatório"),
    idMaterial_Order: Yup.string().required("Campo obrigatório"),
    idFocus_Order: Yup.string().required("Campo obrigatório"),

    // Prescription
    rightSpherical_Order: Yup.string(),
    rightCylindrical_Order: Yup.string(),
    rightAxis_Order: Yup.string(),
    leftSpherical_Order: Yup.string(),
    leftCylindrical_Order: Yup.string(),
    leftAxis_Order: Yup.string(),
    leftAddition_Order: Yup.string(),
    rightAddition_Order: Yup.string(),

    // Extra
    observation_Order: Yup.string(),

    idOrderType_Order: Yup.string().required("Campo obrigatório"),
    idDeadline_Order: Yup.string().required("Campo obrigatório"),
    idCompany_Order: Yup.string().required("Campo obrigatório"),
  });

  let formikValues: FormikConfig<FormikValues> = {
    initialValues: initialValues.current || {},
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: RegisterSchema,
    onSubmit: () => {
      // TODO: CADASTRAR LENTE
    },
  };

  const formik = useFormik(formikValues);

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (formik.isValid) {
      props.setValid(true);
    } else if (props.isValid) props.setValid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isValid]);

  useEffect(() => {
    if (props.changer) {
      setReady(false);
      setTimeout(() => setReady(true), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.changer]);

  useEffect(() => {
    if (props.selects !== undefined && !ready)
      setTimeout(() => setReady(true), 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tagPrinter = useCallback(
    (tagCode: string | number | undefined) =>
      new Promise((res, rej) => {
        orderService
          .getTag(tagCode ?? "")
          .then((r) => {
            const { base64 } = r.data;
            printTag(base64);
            res(true);
          })
          .catch(() => {
            errorAlertHandler("Não foi possível gerar a etiqueta");
            rej();
          });
      }),
    [errorAlertHandler]
  );

  const updateOrder = () => {
    setSubmitting(true);
    orderService
      .saveOrder({ ...values, code_Order: state.code_Order })
      .then(async () => {
        setSubmitting(false);
        props.setSuccess(true);

        const data = await orderService.getById(state.code_Order);
        const actions = await orderService.getActions(state.code_Order);
        await tagPrinter(state?.code_Order);
        console.log({ na: actions });
        navigate("/dashboard/buscar", {
          replace: true,
          state: {
            ...data.data,
            code_Order: state.code_Order,
            actions: actions.data,
          },
        });
      })
      .catch((error) => {
        setErrorOccurred(true);
        console.error(error);
        setSubmitting(false);
        props.setError(true);
      });
  };

  const isDone =state && state.actions && state.actions.length > 0 && state?.actions?.find((e: any) => e.type === "BAIXA")
    ? true
    : false;

  return (
    <>
      <Card sx={{ pb: 2 }}>
        <CardHeader
          sx={{
            background: "#004A78",
            color: "#fff",
            paddingLeft: "30px",
            paddingBottom: "10px",
            paddingTop: "10px",
          }}
          title="Identificação das Lentes"
        />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Container>
                {ready ? (
                  <Grid container columnSpacing={3} rowSpacing={[2, 3]}>
                    <Grid item xs={12} md={3}>
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#004A78",
                          fontSize: ["20px", "24px"],
                          textAlign: ["center", "left"],
                        }}
                      >
                        {state
                          ? state?.id_Order
                            ? state.code_Order || "Código"
                            : "Não Encontrado"
                          : "Não Encontrado"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#004A78",
                          fontSize: ["20px", "24px"],
                          textAlign: ["center", "left"],
                        }}
                      >
                        {state
                          ? state.name_Customer || "Nome do Cliente"
                          : "Nome do Cliente"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#004A78",
                          fontSize: ["20px", "24px"],
                          textAlign: ["center", "left"],
                        }}
                      >
                        {state ? state.billingPlace_Order || "Local" : "Local"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography
                        variant="h4"
                        sx={{
                          color: "#004A78",
                          fontSize: ["20px", "24px"],
                          textAlign: ["center", "left"],
                        }}
                      >
                        {state
                          ? state.serverRegisterDate_Order || "Data"
                          : "Data"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="product">
                          Tratamento*
                        </InputLabel>
                        <Select
                          fullWidth
                          color="secondary"
                          labelId="product"
                          label="Tratamento*"
                          disabled={
                            state?.billing_Order ||
                            !state?.id_Order ||
                            !state ||
                            isDone
                          }
                          {...getFieldProps("idLens_Order")}
                          error={Boolean(
                            touched.idLens_Order && errors.idLens_Order
                          )}
                        >
                          {props.selects?.lens?.map((item: any) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="amount">
                          Quantidade*
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="amount"
                          color="secondary"
                          label="Quantidade*"
                          disabled={
                            state?.billing_Order ||
                            !state?.id_Order ||
                            !state ||
                            isDone
                          }
                          {...getFieldProps("lensesAmount_Order")}
                          error={Boolean(
                            touched.lensesAmount_Order &&
                              errors.lensesAmount_Order
                          )}
                        >
                          <MenuItem value="1">0.5 par</MenuItem>
                          <MenuItem value="2">1.0 par</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="maker">
                          Fabricante*
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="maker"
                          color="secondary"
                          label="Fabricante*"
                          disabled={
                            state?.billing_Order ||
                            !state?.id_Order ||
                            !state ||
                            isDone
                          }
                          {...getFieldProps("idMaker_Order")}
                          error={Boolean(
                            touched.idMaker_Order && errors.idMaker_Order
                          )}
                        >
                          {props.selects?.maker?.map((item: any) => {
                            return (
                              <MenuItem key={item?.id} value={item?.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="focus">
                          Tipo de lente*
                        </InputLabel>
                        <Select
                          fullWidth
                          label="Tipo de lente*"
                          labelId="focus"
                          color="secondary"
                          disabled={
                            state?.billing_Order ||
                            !state?.id_Order ||
                            !state ||
                            isDone
                          }
                          {...getFieldProps("idFocus_Order")}
                          error={Boolean(
                            touched.idFocus_Order && errors.idFocus_Order
                          )}
                        >
                          {props.selects?.focus?.map((item: any) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="block">
                          Observação Lente/Serviço
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="block"
                          color="secondary"
                          label="Observação Lente/Serviço"
                          disabled={
                            state?.billing_Order ||
                            !state?.id_Order ||
                            !state ||
                            isDone
                          }
                          {...getFieldProps("idBlock_Order")}
                          error={Boolean(
                            touched.idBlock_Order && errors.idBlock_Order
                          )}
                        >
                          {props.selects?.block?.map((item: any) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="photosensitivity">
                          Fotossensibilidade*
                        </InputLabel>
                        <Select
                          fullWidth
                          color="secondary"
                          labelId="photosensitivity"
                          label="Fotossensibilidade*"
                          disabled={
                            state?.billing_Order ||
                            !state?.id_Order ||
                            !state ||
                            isDone
                          }
                          {...getFieldProps("idPhotosensitivity_Order")}
                          error={Boolean(
                            touched.idPhotosensitivity_Order &&
                              errors.idPhotosensitivity_Order
                          )}
                        >
                          {props.selects?.photosensitivity?.map((item: any) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="type">
                          Inteira/Recortada*
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="type"
                          label="Inteira/Recortada*"
                          color="secondary"
                          disabled={
                            state?.billing_Order ||
                            !state?.id_Order ||
                            !state ||
                            isDone
                          }
                          {...getFieldProps("idDesign_Order")}
                          error={Boolean(
                            touched.idDesign_Order && errors.idDesign_Order
                          )}
                        >
                          {props.selects?.design?.map((item: any) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="material">
                          Material*
                        </InputLabel>
                        <Select
                          fullWidth
                          color="secondary"
                          labelId="material"
                          label="Material*"
                          disabled={
                            state?.billing_Order ||
                            !state?.id_Order ||
                            !state ||
                            isDone
                          }
                          {...getFieldProps("idMaterial_Order")}
                          error={Boolean(
                            touched.idMaterial_Order && errors.idMaterial_Order
                          )}
                        >
                          {props.selects?.material?.map((item: any) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography variant="subtitle1">Prescrição</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        type="text"
                        color="secondary"
                        variant="outlined"
                        label="Esférico"
                        disabled={
                          state?.billing_Order ||
                          !state?.id_Order ||
                          !state ||
                          isDone
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("rightSpherical_Order")}
                        helperText={
                          touched.rightSpherical_Order &&
                          errors.rightSpherical_Order
                        }
                        error={Boolean(
                          touched.rightSpherical_Order &&
                            errors.rightSpherical_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        type="text"
                        color="secondary"
                        variant="outlined"
                        label="Cilíndrico"
                        disabled={
                          state?.billing_Order ||
                          !state?.id_Order ||
                          !state ||
                          isDone
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("rightCylindrical_Order")}
                        helperText={
                          touched.rightCylindrical_Order &&
                          errors.rightCylindrical_Order
                        }
                        error={Boolean(
                          touched.rightCylindrical_Order &&
                            errors.rightCylindrical_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Eixo"
                        color="secondary"
                        variant="outlined"
                        disabled={
                          state?.billing_Order ||
                          !state?.id_Order ||
                          !state ||
                          isDone
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("rightAxis_Order")}
                        helperText={
                          touched.rightAxis_Order && errors.rightAxis_Order
                        }
                        error={Boolean(
                          touched.rightAxis_Order && errors.rightAxis_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Adição"
                        color="secondary"
                        variant="outlined"
                        disabled={
                          state?.billing_Order ||
                          !state?.id_Order ||
                          !state ||
                          isDone
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("rightAddition_Order")}
                        helperText={
                          touched.rightAddition_Order &&
                          errors.rightAddition_Order
                        }
                        error={Boolean(
                          touched.rightAddition_Order &&
                            errors.rightAddition_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        type="text"
                        color="secondary"
                        variant="outlined"
                        label="Esférico"
                        disabled={
                          state?.billing_Order ||
                          !state?.id_Order ||
                          !state ||
                          isDone
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">L</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("leftSpherical_Order")}
                        helperText={
                          touched.leftSpherical_Order &&
                          errors.leftSpherical_Order
                        }
                        error={Boolean(
                          touched.leftSpherical_Order &&
                            errors.leftSpherical_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        type="text"
                        color="secondary"
                        variant="outlined"
                        label="Cilíndrico"
                        disabled={
                          state?.billing_Order ||
                          !state?.id_Order ||
                          !state ||
                          isDone
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">L</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("leftCylindrical_Order")}
                        helperText={
                          touched.leftCylindrical_Order &&
                          errors.leftCylindrical_Order
                        }
                        error={Boolean(
                          touched.leftCylindrical_Order &&
                            errors.leftCylindrical_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Eixo"
                        color="secondary"
                        variant="outlined"
                        disabled={
                          state?.billing_Order ||
                          !state?.id_Order ||
                          !state ||
                          isDone
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">L</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("leftAxis_Order")}
                        helperText={
                          touched.leftAxis_Order && errors.leftAxis_Order
                        }
                        error={Boolean(
                          touched.leftAxis_Order && errors.leftAxis_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Adição"
                        color="secondary"
                        variant="outlined"
                        disabled={
                          state?.billing_Order ||
                          !state?.id_Order ||
                          !state ||
                          isDone
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">L</InputAdornment>
                          ),
                        }}
                        {...getFieldProps("leftAddition_Order")}
                        helperText={
                          touched.leftAddition_Order &&
                          errors.leftAddition_Order
                        }
                        error={Boolean(
                          touched.leftAddition_Order &&
                            errors.leftAddition_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography variant="subtitle1">Observações</Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        disabled
                        fullWidth
                        multiline
                        type="text"
                        color="secondary"
                        variant="outlined"
                        label="Observações"
                        {...getFieldProps("observation_Order")}
                        helperText={
                          touched.observation_Order && errors.observation_Order
                        }
                        error={Boolean(
                          touched.observation_Order && errors.observation_Order
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Typography variant="subtitle1">Finalização</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="others">
                          Outros*
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="others"
                          label="Outros*"
                          color="secondary"
                          disabled={
                            state?.billing_Order ||
                            !state?.id_Order ||
                            !state ||
                            isDone
                          }
                          {...getFieldProps("idOrderType_Order")}
                          error={Boolean(
                            touched.idOrderType_Order &&
                              errors.idOrderType_Order
                          )}
                        >
                          <MenuItem value="1">Normal</MenuItem>
                          <MenuItem value="2">Cortesia</MenuItem>
                          <MenuItem value="3">Reembolso</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="urgent">
                          Tipo de Urgência*
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="urgent"
                          color="secondary"
                          label="Tipo de Urgência*"
                          disabled={
                            state?.billing_Order ||
                            !state?.id_Order ||
                            !state ||
                            isDone
                          }
                          {...getFieldProps("idDeadline_Order")}
                          error={Boolean(
                            touched.idDeadline_Order && errors.idDeadline_Order
                          )}
                        >
                          <MenuItem value="1">Normal</MenuItem>
                          <MenuItem value="2">Urgente</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <InputLabel color="secondary" id="company">
                          Local de Produção*
                        </InputLabel>
                        <Select
                          fullWidth
                          color="secondary"
                          labelId="company"
                          label="Local de Produção*"
                          disabled={
                            state?.billing_Order ||
                            !state?.id_Order ||
                            !state ||
                            isDone
                          }
                          {...getFieldProps("idCompany_Order")}
                          error={Boolean(
                            touched.idCompany_Order && errors.idCompany_Order
                          )}
                        >
                          {props.selects?.company?.map((item: any) => (
                            <MenuItem key={item?.id} value={item?.id}>
                              {item.initials_Company} - {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    {!state?.billing_Order ? (
                      <Grid item xs={12} md={12}>
                        <Stack
                          flexDirection="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          {((isEqual(values, initialValues.current) &&
                            validActions?.length > 0) ||
                            errorOccurred) && (
                            <Button
                              variant="text"
                              onClick={() => tagPrinter(state?.code_Order)}
                              color="secondary"
                              sx={{
                                marginRight: "20px",
                              }}
                            >
                              Imprimir etiqueta
                            </Button>
                          )}
                          <LoadingButton
                            color="secondary"
                            variant="contained"
                            disabled={
                              !props.isValid ||
                              (isEqual(values, initialValues.current) &&
                                validActions?.length > 0)
                            }
                            loading={isSubmitting}
                            onClick={
                              props.isValid ? () => updateOrder() : () => {}
                            }
                            sx={{
                              borderRadius: "30px",
                              minWidth: "150px",
                              height: "50px",
                              float: "right",
                              background: "#004A78",
                              marginTop: "0px !important",
                            }}
                          >
                            Confirmar
                          </LoadingButton>
                        </Stack>
                      </Grid>
                    ) : undefined}
                  </Grid>
                ) : (
                  <Typography sx={{ textAlign: "center", paddingY: "56px" }}>
                    Carregando...
                  </Typography>
                )}
              </Container>
            </Form>
          </FormikProvider>
        </CardContent>
      </Card>
      {state ? (
        <ActionCard
          actions={state?.actions}
          code_Order={state.code_Order}
          setError={(value: boolean) => props.setError(value)}
          setSuccess={(value: boolean) => props.setSuccess(value)}
        />
      ) : undefined}
    </>
  );
}
