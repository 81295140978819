import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import AlertMessage from "../../../components/alert/alert";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Stack,
  TextField,
  CardActions,
  CardContent,
  Typography,
  Modal,
  Card,
} from "@mui/material";

interface ModalProps {
  open: boolean;
  handler: () => void;
  changePassword: (password: string) => void;
}

export default function PasswordModal(props: ModalProps) {
  const navigate = useNavigate();
  const [showError, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const RegisterSchema = Yup.object().shape({
    costReportPassword_Customer: Yup.string().required("A senha é obrigatória"),
  });
  var formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      costReportPassword_Customer: "",
    },
    onSubmit: () => {
      setSubmitting(true);
      props.changePassword(
        values.costReportPassword_Customer?.toUpperCase() || "wrongpass"
      );
      setTimeout(() => {
        setSubmitting(false);
        formik.setFieldValue("costReportPassword_Customer", "");
      }, 1500);
    },
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: RegisterSchema,
  };

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
  }, [showError]);

  const formik = useFormik(formikValues);

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Modal
        open={props.open}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            navigate("/dashboard/pedidos", { replace: true });
          } else {
            formik.resetForm();
            props.handler();
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ minWidth: "30vw" }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <CardContent>
                <Typography
                  variant="h4"
                  marginBottom={4}
                  color="#004A78"
                  gutterBottom
                >
                  Acesso aos relatórios
                </Typography>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    Senha*
                  </Typography>
                  <TextField
                    fullWidth
                    type="password"
                    color="secondary"
                    variant="outlined"
                    placeholder="Obrigatória"
                    {...getFieldProps("costReportPassword_Customer")}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    error={Boolean(
                      touched.costReportPassword_Customer &&
                        errors.costReportPassword_Customer
                    )}
                    helperText={
                      touched.costReportPassword_Customer &&
                      errors.costReportPassword_Customer
                    }
                  />
                </Stack>
              </CardContent>
              <CardActions>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!formik.isValid}
                  sx={{
                    m: 1,
                    height: "48px",
                    borderRadius: "30px",
                    backgroundColor: "#CDCDCD",
                    color: "#004A78",
                    "&:hover": {
                      bgcolor: "#004A78",
                      color: "#FEFEFE",
                    },
                  }}
                  autoFocus
                >
                  ACESSAR
                </LoadingButton>
              </CardActions>
            </Form>
          </FormikProvider>
        </Card>
      </Modal>
      {showError && (
        <AlertMessage open={true} message="Código de OS não disponível" />
      )}
    </>
  );
}
