import http from "../../../../services/http-common";
import firebase from "firebase/app";
import "firebase/storage";

class ClientService {
  // eslint-disable-next-line class-methods-use-this
  async saveFisCustomer(data: any, idCustomer?: string) {
    const idUser = idCustomer ? (await http.get(`/customer/user/${idCustomer}`)).data[0] : undefined;
    const billingAddress = data.city !== "" ? true : false;
    const parse: any = localStorage.getItem("user");
    const user = JSON.parse(parse);

    let urlDownload = "";
    try {
      if (data.file) {
        const storage = firebase.storage();

        const filePath = `clientes/pessoa-fisica/${data.name_Customer} - ${data.cpf_Customer}`;

        const storageRef = await storage.ref(filePath);
        await storageRef.put(data.file);
        urlDownload = await storageRef.getDownloadURL();
      } else {
        urlDownload = data.pictureFilepath_Customer;
      }
      const fisCustomer: any = {
        // PROFILE PIC
        pictureFilename_Customer: `${data.name_Customer} - ${data.cpf_Customer}`,
        pictureFilepath_Customer: urlDownload,

        // GENERAL DATA
        name_Customer: data.name_Customer?.toUpperCase() || "",
        cpf_Customer: data.cpf_Customer?.replace(/[^0-9]/g, ""),
        email_Customer: data.email_Customer?.toUpperCase() || "",
        whatsapp_Customer: data.whatsapp_Customer?.replace(/[^0-9]/g, ""),
        idPaymentType_Customer: data.idPaymentType_Customer,
        billingPlace_Customer: data.billingPlace_Customer || "",

        // EXTRA
        personType_Customer: "F",
      };

      if (!idCustomer || !data.idSeller_Customer) fisCustomer.idSeller_Customer = user.idUser;
      else fisCustomer.idSeller_Customer = data.idSeller_Customer;

      if (data.zipcode_Address?.match(/^\d{5}-\d{3}$/)) {
        fisCustomer.address = {
          city_Address: data.city_Address?.toUpperCase() || "",
          state_Address: data.state_Address?.toUpperCase() || "",
          street_Address: data.street_Address?.toUpperCase() || "",
          number_Address: data.number_Address?.toUpperCase() || "",
          zipcode_Address: data.zipcode_Address?.replace(/[^0-9]/g, ""),
          complement_Address: data.complement_Address?.toUpperCase() || "",
          neighbourhood_Address: data.neighbourhood_Address?.toUpperCase() || "",
        };

        if (!billingAddress) {
          fisCustomer.billingAddress = {
            city_Address: billingAddress ? data.city?.toUpperCase() || "" : data.city_Address?.toUpperCase() || "",
            state_Address: billingAddress ? data.state?.toUpperCase() || "" : data.state_Address?.toUpperCase() || "",
            street_Address: billingAddress
              ? data.street?.toUpperCase() || ""
              : data.street_Address?.toUpperCase() || "",
            number_Address: billingAddress
              ? data.number?.toUpperCase() || ""
              : data.number_Address?.toUpperCase() || "",
            zipcode_Address: billingAddress
              ? data.zipcode?.replace(/[^0-9]/g, "")
              : data.zipcode_Address?.replace(/[^0-9]/g, ""),
            complement_Address: billingAddress
              ? data.complement?.toUpperCase() || ""
              : data.complement_Address?.toUpperCase() || "",
            neighbourhood_Address: billingAddress
              ? data.neighbourhood?.toUpperCase() || ""
              : data.neighbourhood_Address?.toUpperCase() || "",
          };
        }
      }

      if (data.zipcode?.match(/^\d{5}-\d{3}$/)) {
        fisCustomer.billingAddress = {
          city_Address: billingAddress ? data.city?.toUpperCase() || "" : data.city_Address?.toUpperCase() || "",
          state_Address: billingAddress ? data.state?.toUpperCase() || "" : data.state_Address?.toUpperCase() || "",
          street_Address: billingAddress ? data.street?.toUpperCase() || "" : data.street_Address?.toUpperCase() || "",
          number_Address: billingAddress ? data.number?.toUpperCase() || "" : data.number_Address?.toUpperCase() || "",
          zipcode_Address: billingAddress
            ? data.zipcode?.replace(/[^0-9]/g, "")
            : data.zipcode_Address?.replace(/[^0-9]/g, ""),
          complement_Address: billingAddress
            ? data.complement?.toUpperCase() || ""
            : data.complement_Address?.toUpperCase() || "",
          neighbourhood_Address: billingAddress
            ? data.neighbourhood?.toUpperCase() || ""
            : data.neighbourhood_Address?.toUpperCase() || "",
        };
      }

      if (!data.sellerUser) {
        if (data.costReportPassword_Customer?.length)
          fisCustomer.costReportPassword_Customer = data.costReportPassword_Customer?.toUpperCase() || "";

        fisCustomer.fullName_Customer = data.name_Customer?.toUpperCase() || "";
        fisCustomer.rg_Customer = data.rg_Customer?.toUpperCase() || "";
        fisCustomer.phone_Customer = data.phone_Customer.length ? data.phone_Customer?.replace(/[^0-9]/g, "") : "";
        fisCustomer.contmaticCode_Customer = data.contmaticCode_Customer?.toUpperCase() || "";
        fisCustomer.contact_Customer = data.contact_Customer?.toUpperCase() || "";
        if (data.birthDate_Customer?.match(/^\d{2}\/\d{2}\/\d{4}$/))
          fisCustomer.birthDate_Customer = data.birthDate_Customer;
        fisCustomer.initials_Customer = data.initials_Customer?.toUpperCase() || "";
        fisCustomer.idTeam_Customer = data.idTeam_Customer;
        fisCustomer.prepaid_Customer = data.prepaid_Customer ? true : false;
        fisCustomer.singleNationalChooser_Customer = data.singleNationalChooser_Customer ? true : false;
        fisCustomer.billingEmail_Customer = data.email_Customer?.toUpperCase() || "";
        fisCustomer.billingPhone_Customer = data.whatsapp_Customer.length
          ? data.whatsapp_Customer?.replace(/[^0-9]/g, "")
          : "";
          fisCustomer.observations_Customer = data.observations_Customer?.toUpperCase() || "";
      } else {
        fisCustomer.observations_Customer = data.observations_Customer?.toUpperCase() || "";
      }

      if (idCustomer)
        return http.put(`/customer${data.sellerUser ? "/seller" : ""}/update/${idUser}/${idCustomer}`, fisCustomer);
      else return http.post(`/customer${data.sellerUser ? "/seller" : ""}`, fisCustomer);
    } catch (error) {
      console.log(error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async saveJurCustomer(data: any, idCustomer?: string) {
    const idUser = idCustomer ? (await http.get(`/customer/user/${idCustomer}`)).data[0] : undefined;
    const billingAddress = data.city !== "" ? true : false;
    const parse: any = localStorage.getItem("user");
    const user = JSON.parse(parse);
    let urlDownload = "";

    try {
      if (data.file) {
        const storage = firebase.storage();

        const filePath = `clientes/pessoa-juridica/${data.name_Customer} - ${data.cnpj_Customer}`;

        const storageRef = await storage.ref(filePath);
        await storageRef.put(data.file);

        urlDownload = await storageRef.getDownloadURL();
      } else {
        urlDownload = data.pictureFilepath_Customer;
      }

      const jurCustomer: any = {
        // PROFILE PIC
        pictureFilename_Customer: `${data.name_Customer} - ${data.cnpj_Customer}`,
        pictureFilepath_Customer: urlDownload,

        // GENERAL DATA
        fullName_Customer: data.fullName_Customer?.toUpperCase() || "",
        cnpj_Customer: data.cnpj_Customer.replace(/[^0-9]/g, ""),
        contact_Customer: data.contact_Customer?.toUpperCase() || "",
        email_Customer: data.email_Customer?.toUpperCase() || "",
        name_Customer: data.name_Customer?.toUpperCase() || "",
        phone_Customer: data.phone_Customer.length ? data.phone_Customer?.replace(/[^0-9]/g, "") : "",
        whatsapp_Customer: data.whatsapp_Customer.replace(/[^0-9]/g, ""),
        idPaymentType_Customer: data.idPaymentType_Customer,
        billingPlace_Customer: data.billingPlace_Customer || "",

        // EXTRA
        personType_Customer: "J",
      };

      if (!idCustomer || !data.idSeller_Customer) jurCustomer.idSeller_Customer = user.idUser;
      else jurCustomer.idSeller_Customer = data.idSeller_Customer;

      if (data.zipcode_Address.match(/^\d{5}-\d{3}$/)) {
        jurCustomer.address = {
          city_Address: data.city_Address?.toUpperCase() || "",
          state_Address: data.state_Address?.toUpperCase() || "",
          street_Address: data.street_Address?.toUpperCase() || "",
          number_Address: data.number_Address?.toUpperCase() || "",
          zipcode_Address: data.zipcode_Address.replace(/[^0-9]/g, ""),
          complement_Address: data.complement_Address?.toUpperCase() || "",
          neighbourhood_Address: data.neighbourhood_Address?.toUpperCase() || "",
        };

        if (!billingAddress) {
          jurCustomer.billingAddress = {
            city_Address: billingAddress ? data.city?.toUpperCase() || "" : data.city_Address?.toUpperCase() || "",
            state_Address: billingAddress ? data.state?.toUpperCase() || "" : data.state_Address?.toUpperCase() || "",
            street_Address: billingAddress
              ? data.street?.toUpperCase() || ""
              : data.street_Address?.toUpperCase() || "",
            number_Address: billingAddress
              ? data.number?.toUpperCase() || ""
              : data.number_Address?.toUpperCase() || "",
            zipcode_Address: billingAddress
              ? data.zipcode.replace(/[^0-9]/g, "")
              : data.zipcode_Address.replace(/[^0-9]/g, ""),
            complement_Address: billingAddress
              ? data.complement?.toUpperCase() || ""
              : data.complement_Address?.toUpperCase() || "",
            neighbourhood_Address: billingAddress
              ? data.neighbourhood?.toUpperCase() || ""
              : data.neighbourhood_Address?.toUpperCase() || "",
          };
        }
      }

      if (data.zipcode.match(/^\d{5}-\d{3}$/)) {
        jurCustomer.billingAddress = {
          city_Address: billingAddress ? data.city?.toUpperCase() || "" : data.city_Address?.toUpperCase() || "",
          state_Address: billingAddress ? data.state?.toUpperCase() || "" : data.state_Address?.toUpperCase() || "",
          street_Address: billingAddress ? data.street?.toUpperCase() || "" : data.street_Address?.toUpperCase() || "",
          number_Address: billingAddress ? data.number?.toUpperCase() || "" : data.number_Address?.toUpperCase() || "",
          zipcode_Address: billingAddress
            ? data.zipcode.replace(/[^0-9]/g, "")
            : data.zipcode_Address.replace(/[^0-9]/g, ""),
          complement_Address: billingAddress
            ? data.complement?.toUpperCase() || ""
            : data.complement_Address?.toUpperCase() || "",
          neighbourhood_Address: billingAddress
            ? data.neighbourhood?.toUpperCase() || ""
            : data.neighbourhood_Address?.toUpperCase() || "",
        };
      }

      if (!data.sellerUser) {
        if (data.costReportPassword_Customer?.length)
          jurCustomer.costReportPassword_Customer = data.costReportPassword_Customer?.toUpperCase() || "";

        jurCustomer.municipalRegistration_Customer = data.municipalRegistration_Customer?.toUpperCase() || "";
        jurCustomer.stateRegistration_Customer = data.stateRegistration_Customer?.toUpperCase() || "";
        jurCustomer.contmaticCode_Customer = data.contmaticCode_Customer;
        jurCustomer.initials_Customer = data.initials_Customer?.toUpperCase() || "";
        jurCustomer.idTeam_Customer = data.idTeam_Customer;
        jurCustomer.prepaid_Customer = data.prepaid_Customer ? true : false;
        jurCustomer.singleNationalChooser_Customer = data.singleNationalChooser_Customer ? true : false;
        jurCustomer.billingEmail_Customer = data.email_Customer?.toUpperCase() || "";
        jurCustomer.billingPhone_Customer = data.whatsapp_Customer.length
          ? data.whatsapp_Customer?.replace(/[^0-9]/g, "")
          : "";
          jurCustomer.observations_Customer = data.observations_Customer?.toUpperCase() || "";
      } else {
        jurCustomer.observations_Customer = data.observations_Customer?.toUpperCase() || "";
      }
    
      if (idCustomer)
      {
       
        return http.put(`/customer${data.sellerUser ? "/seller" : ""}/update/${idUser}/${idCustomer}`, jurCustomer);
      }
        
      else return http.post(`/customer${data.sellerUser ? "/seller" : ""}`, jurCustomer);
    } catch (error) {
      console.log(error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getById(id: string) {
    return http.get(`customer/profile/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  getAll(offset: string, limit: string, params: any): any {
    const parse: any = localStorage.getItem("user");
    const user = JSON.parse(parse);
    if (user && user?.role && user?.idUser) {
      if (user.role === "Vendedor") {
        return http.get(`customer/find/${user.idUser}/${offset}/${limit}`, {
          params: params,
        });
      }
    }
    return http.get(`customer/find/${offset}/${limit}`, {
      params: params,
    });
  }

  async isSellerUser() {
    const parse: any = await localStorage.getItem("user");
    const user = await JSON.parse(parse);

    return user.role === "Vendedor";
  }

  // eslint-disable-next-line class-methods-use-this
  getLens() {
    return http.get("/lens/find");
  }

  getReport(idCustomer: string, prePaid: boolean, startDate?: string, endDate?: string) {
    const today = new Date();
    const priorDate = new Date().setDate(today.getDate() - 30);
    const defaultStart = new Date(priorDate).toLocaleDateString("pt-br");

    const type = prePaid ? "prepaid" : "postpaid";
    const data = {
      startDate: startDate || defaultStart,
      endDate: endDate || "01/01/2050",
    };
    const query = `customer/${type}/cost/report/${idCustomer}`;

    return http.post(query, data);
  }

  addCredit(idCustomer: number, value: number) {
    const credit = {
      idCustomer_Credit: idCustomer,
      value_Credit: value,
    };

    return http.post("credit/", credit);
  }

  getPaymentTypes() {
    return http.get("paymentType/find");
  }

  async removeClient(idClient: any) {
    const idUser = (await http.get(`/customer/user/${idClient}`)).data[0];

    return http.delete(`user/delete/${idUser}`);
  }

  buildQr(idClient: any, numPage: any) {
    return http.get(`order/code/generator/${idClient}/${numPage}/`);
  }

  passwordRecover(email: any) {
    return http.patch(`/user/forgot/password`,{email:email});
  }
}

export default new ClientService();
