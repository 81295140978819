import * as Yup from "yup";
import InputMask from "react-input-mask";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Card,
  Grid,
  Radio,
  Select,
  Button,
  Tooltip,
  MenuItem,
  Checkbox,
  Container,
  TextField,
  CardHeader,
  Typography,
  RadioGroup,
  InputLabel,
  FormControl,
  CardContent,
  Autocomplete,
  FormControlLabel,
} from "@mui/material";

import Photo from "../../../../components/Photo";
import { useData } from "../../../../contexts/client";
import zipcodeService from "../../../../services/zipcode";
import groupService from "./../../group/services/groupService";

export const COMPANIES = [
  { name: "PA - Porto Alegre", value: "PA" },
  { name: "NE - Fortaleza", value: "NE" },
  { name: "SP - São Paulo", value: "SP" },
];

export default function FormDataFis(props: any) {
  const navigate = useNavigate();
  const { state }: any = useLocation();
  const [ready, setReady] = useState(false);
  const { handleClientDataFis } = useData();
  const [imageFire, setImageFire] = useState("");
  const [fileFire, setFileFire] = useState<any[]>([]);
  const [sameAddress, setSameAddress] = useState(true);
  const [groupData, setGroupData]: any = useState({
    name_Team: "",
    id_Team: "",
  });

  const parse: any = localStorage.getItem("user");
  const user = JSON.parse(parse);

  const RegisterSchema = Yup.object().shape({
    name_Customer: Yup.string().required("Campo Obrigatório"),
    cpf_Customer: Yup.string()
      .required("Campo Obrigatório")
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "CPF Inválido"),
    email_Customer: Yup.string()
      .email("Email inválido!")
      .required("Campo Obrigatório"),
    rg_Customer: Yup.string().nullable(),
    birthDate_Customer: Yup.string(),
    contact_Customer: Yup.string().nullable(),
    phone_Customer: Yup.string(),
    costReportPassword_Customer: Yup.string().test(
      "len",
      "Campo Obrigatório",
      (val: string | undefined) =>
        !props.sellerUser && !state ? (val?.length ? true : false) : true
    ),
    whatsapp_Customer: Yup.string().matches(
      /^\(\d{2}\)\d{5}-\d{4}$/,
      "Número Inválido"
    ),
    contmaticCode_Customer: Yup.string().test(
      "len",
      "Campo Obrigatório",
      (val: string | undefined) =>
        !props.sellerUser ? (val?.length ? true : false) : true
    ),
    idTeam_Customer: Yup.string().test(
      "len",
      "Campo Obrigatório",
      (val: string | undefined) =>
        !props.sellerUser ? (val?.length ? true : false) : true
    ),
    initials_Customer: Yup.string().test(
      "len",
      "Deve ter exatamente 3 letras",
      (val: string | undefined) =>
        !props.sellerUser ? val?.length === 3 : true
    ),
    prepaid_Customer: Yup.number().required("Campo obrigatório"),
    singleNationalChooser_Customer: Yup.number().required("Campo obrigatório"),
    idPaymentType_Customer: Yup.number().required(
      "O tipo de pagamento é obrigatório"
    ),
    observations_Customer: Yup.string()
      .nullable()
      .test("len", "Campo Obrigatório", (val: string | null | undefined) =>
        props.sellerUser ? (val?.length ? true : false) : true
      ),

    // Common Address
    zipcode_Address: Yup.string().test("len", "CEP Inválido", (val: any) =>
      !props.sellerUser ? val?.match(/^\d{5}-\d{3}$/) : true
    ),
    city_Address: Yup.string().test(
      "len",
      "Campo Obrigatório",
      (val: string | undefined) =>
        !props.sellerUser ? (val?.length ? true : false) : true
    ),
    state_Address: Yup.string().test(
      "len",
      "Obrigatório",
      (val: string | undefined) =>
        !props.sellerUser ? (val?.length ? true : false) : true
    ),
    street_Address: Yup.string().test(
      "len",
      "Campo Obrigatório",
      (val: string | undefined) =>
        !props.sellerUser ? (val?.length ? true : false) : true
    ),
    number_Address: Yup.string().test(
      "len",
      "Obrigatório",
      (val: string | undefined) =>
        !props.sellerUser ? (val?.length ? true : false) : true
    ),
    complement_Address: Yup.string(),
    neighbourhood_Address: Yup.string().test(
      "len",
      "Campo Obrigatório",
      (val: string | undefined) =>
        !props.sellerUser ? (val?.length ? true : false) : true
    ),

    billingPlace_Customer: Yup.string().required("Campo Obrigatório"),

    // Billing Address
    zipcode: Yup.string().test("ver", "Campo Obrigatório", (val: any) =>
      !sameAddress && !props.sellerUser ? val?.match(/^\d{5}-\d{3}$/) : true
    ),
    city: Yup.string().test("ver", "Campo Obrigatório", (val: any) =>
      !sameAddress && !props.sellerUser ? val?.length : true
    ),
    state: Yup.string().test("ver", "Campo Obrigatório", (val: any) =>
      !sameAddress && !props.sellerUser ? val?.length : true
    ),
    street: Yup.string().test("ver", "Campo Obrigatório", (val: any) =>
      !sameAddress && !props.sellerUser ? val?.length : true
    ),
    number: Yup.string().test("ver", "Campo Obrigatório", (val: any) =>
      !sameAddress && !props.sellerUser ? val?.length : true
    ),
    neighbourhood: Yup.string().test("ver", "Campo Obrigatório", (val: any) =>
      !sameAddress && !props.sellerUser ? val?.length : true
    ),
    complement: Yup.string(),
  });

  const formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      idSeller_Customer: state ? state?.idSeller_User || "" : "",
      pictureFilename_Customer: state
        ? state.fromGroups
          ? state.pictureFilename_Customer
          : state.pictureFilename_User
        : "",
      pictureFilepath_Customer: state
        ? state.fromGroups
          ? state.pictureFilepath_Customer
          : state.pictureFilepath_User
        : "",

      name_Customer: state ? state.name_Customer || "" : "",
      cpf_Customer: state
        ? state.fromGroups
          ? state.cpf_Customer
          : state?.cpf_PrivatePerson?.replace(
              /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
              "$1.$2.$3-$4"
            )
        : "",
      rg_Customer: state
        ? state.fromGroups
          ? state.rg_Customer
          : state?.rg_PrivatePerson === "undefined"
          ? ""
          : state?.rg_PrivatePerson
        : "",
      birthDate_Customer: state
        ? state.fromGroups
          ? state.birthDate_Customer
          : state?.birthDate_PrivatePerson?.replace(
              /^(\d{2})(\d{2})(\d{4})$/,
              "$1/$2/$3"
            )
        : "",
      costReportPassword_Customer: state
        ? state.costReportPassword_Customer
        : "",
      contact_Customer: state ? state.contact_Customer || "" : "",
      email_Customer: state
        ? state.fromGroups
          ? state.email_Customer
          : state.email_User
        : "",
      phone_Customer: state
        ? state.fromGroups
          ? state.phone_Customer
          : state?.phone_User || ""
        : "",
      whatsapp_Customer: state
        ? state.whatsapp_Customer?.replace(
            /^(\d{2})(\d{5})(\d{4})$/,
            "($1)$2-$3"
          )
        : "",
      contmaticCode_Customer: state ? state.contmaticCode_Customer || "" : "",
      idTeam_Customer: state ? state.idTeam_Customer?.toString() || "" : "",
      initials_Customer: state ? state.initials_Customer || "" : "",
      prepaid_Customer: state ? state.prepaid_Customer || 0 : 0,
      singleNationalChooser_Customer: state
        ? state.singleNationalChooser_Customer || 0
        : 0,
      idPaymentType_Customer: state ? state.idPaymentType_Customer : "1",
      observations_Customer: state
        ? state?.observations_Customer
        : user.role === "Vendedor"
        ? "EMAIL PARA ENVIO DA NOTA FISCAL:\n\nOPTO CLASS - R$\nHARD COATING - R$\nOPTO GOLD VERDE - R$\nOPTO GOLD DOURADO - R$\nOPTO EXTRA CLEAN - R$\nSUPER OPTO - R$\nOPTO DIGITAL - R$"
        : "",

      // Common Address
      zipcode_Address: state
        ? state.fromGroups
          ? state.zipcode_Address
          : state?.address?.zipcode?.replace(/^(\d{5})(\d{3})$/, "$1-$2") || ""
        : "",
      city_Address: state
        ? state.fromGroups
          ? state.city_Address
          : state?.address?.city || ""
        : "",
      state_Address: state
        ? state.fromGroups
          ? state.state_Address
          : state?.address?.state || ""
        : "",
      street_Address: state
        ? state.fromGroups
          ? state.street_Address
          : state?.address?.street || ""
        : "",
      number_Address: state
        ? state.fromGroups
          ? state.number_Address
          : state?.address?.number || ""
        : "",
      complement_Address: state
        ? state.fromGroups
          ? state.complement_Address
          : state?.address?.complement || ""
        : "",
      neighbourhood_Address: state
        ? state?.fromGroups
          ? state.neighbourhood_Address
          : state?.address?.neighbourhood || ""
        : "",

      billingPlace_Customer: state ? state.billingPlace_Customer : "",

      // Billing Address
      zipcode: state
        ? state.fromGroups
          ? state.zipcode
          : state?.billingAddress?.zipcode?.replace(
              /^(\d{5})(\d{3})$/,
              "$1-$2"
            ) || ""
        : "",
      city: state
        ? state.fromGroups
          ? state.city
          : state.billingAddress?.city || ""
        : "",
      state: state
        ? state.fromGroups
          ? state.state
          : state.billingAddress?.state || ""
        : "",
      street: state
        ? state.fromGroups
          ? state.street
          : state.billingAddress?.street || ""
        : "",
      number: state
        ? state.fromGroups
          ? state.number
          : state?.billingAddress?.number || ""
        : "",
      complement: state
        ? state.fromGroups
          ? state.complement
          : state?.billingAddress?.complement || ""
        : "",
      neighbourhood: state
        ? state.fromGroups
          ? state.neighbourhood
          : state?.billingAddress?.neighbourhood || ""
        : "",
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: RegisterSchema,
    onSubmit: () => {
      //TODO: CADASTRAR FIS
    },
  };

  const formik = useFormik(formikValues);

  const searchZipcode = (first: boolean) => {
    const mainValue = first ? values.zipcode_Address : values.zipcode;
    const zipCode = mainValue.replace(/[^0-9]/g, "");
    if (zipCode.length === 8) {
      zipcodeService
        .getAddress(zipCode)
        .then((data) => {
          if (first) {
            values.state_Address = data.data.uf;
            values.city_Address = data.data.localidade;
            values.street_Address = data.data.logradouro;
            values.neighbourhood_Address = data.data.bairro;
            values.complement_Address = data.data.complemento;
          } else {
            values.state = data.data.uf;
            values.city = data.data.localidade;
            values.street = data.data.logradouro;
            values.neighbourhood = data.data.bairro;
            values.complement = data.data.complemento;
          }
        })
        .catch((error) => {
          console.error("zipcode error -> ", error);
          alert("Erro ao recuperar o endereço!");
        });
    }
  };

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    let list: any = [];

    if (user.role !== "Vendedor")
      groupService
        .getAll("0", "100000", { value: "" })
        .then((data: any) => {
          data.data.forEach((element: any) => {
            list.push({ label: element.name_Team, id: element.id_Team });
          });

          setGroupData(list);
        })
        .catch((error: any) => {
          console.error(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state) {
      if (
        state?.address?.zipcode !== state?.billingAddress?.zipcode ||
        state?.zipcode_Address !== state?.zipcode
      )
        setSameAddress(false);
      else setSameAddress(true);
    }
  }, [state]);

  useEffect(() => {
    if (props?.paymentTypes) setTimeout(() => setReady(true), 1000);
    if (!state?.completeRegistration_Customer) formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, state]);

  useEffect(() => {
    setImageFire(values.pictureFilepath_Customer);
  }, [values.pictureFilepath_Customer]);

  useEffect(() => {
    console.log(values)
    if (formik.isValid) {
      handleClientDataFis(
        { ...values, sellerUser: props.sellerUser },
        fileFire[0]
      );
      if (!props.isValid) props.setValid(true);
    } else {
      props.setValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isValid, values, fileFire, props.state, groupData]);

  useEffect(() => {
    formik.validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sameAddress]);

  return (
    <Card>
      <CardHeader
        sx={{
          color: "#fff",
          padding: "10px 30px",
          background: "#004A78",
        }}
        title="Dados do Cliente"
      />
      <CardContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Container>
              {ready ? (
                <Grid container columnSpacing={3} rowSpacing={3}>
                  <Grid item xs={12} md={3}>
                    <Photo
                      imageFire={imageFire}
                      setImageFire={(value: any) => setImageFire(value)}
                      setFileFire={(value: any[]) => setFileFire(value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Grid container columnSpacing={3} rowSpacing={3}>
                      <Grid item xs={12} md={8}>
                        <TextField
                          fullWidth
                          type="text"
                          color="secondary"
                          variant="outlined"
                          label="Nome Completo*"
                          {...getFieldProps("name_Customer")}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          helperText={
                            touched.name_Customer && errors.name_Customer
                          }
                          error={Boolean(
                            touched.name_Customer && errors.name_Customer
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <InputMask
                          {...getFieldProps("cpf_Customer")}
                          mask="999.999.999-99"
                        >
                          {() => (
                            <TextField
                              fullWidth
                              type="text"
                              label="CPF*"
                              color="secondary"
                              variant="outlined"
                              {...getFieldProps("cpf_Customer")}
                              helperText={
                                touched.cpf_Customer && errors.cpf_Customer
                              }
                              error={Boolean(
                                touched.cpf_Customer && errors.cpf_Customer
                              )}
                            />
                          )}
                        </InputMask>
                      </Grid>

                      <Grid item xs={12} md={5}>
                        <TextField
                          fullWidth
                          type="text"
                          label="E-mail*"
                          color="secondary"
                          variant="outlined"
                          {...getFieldProps("email_Customer")}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          helperText={
                            touched.email_Customer && errors.email_Customer
                          }
                          error={Boolean(
                            touched.email_Customer && errors.email_Customer
                          )}
                        />
                      </Grid>

                      {!props?.sellerUser ? (
                        <>
                          <Grid item xs={12} md={4}>
                            <TextField
                              fullWidth
                              type="text"
                              label="RG"
                              color="secondary"
                              variant="outlined"
                              {...getFieldProps("rg_Customer")}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              helperText={
                                touched.rg_Customer && errors.rg_Customer
                              }
                              error={Boolean(
                                touched.rg_Customer && errors.rg_Customer
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <InputMask
                              {...getFieldProps("birthDate_Customer")}
                              mask="99/99/9999"
                            >
                              {() => (
                                <TextField
                                  fullWidth
                                  type="text"
                                  variant="outlined"
                                  color="secondary"
                                  label="Data de Nascimento"
                                  {...getFieldProps("birthDate_Customer")}
                                  error={Boolean(
                                    touched.birthDate_Customer &&
                                      errors.birthDate_Customer
                                  )}
                                  helperText={
                                    touched.birthDate_Customer &&
                                    errors.birthDate_Customer
                                  }
                                />
                              )}
                            </InputMask>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} md={4}>
                            <InputMask
                              {...getFieldProps("whatsapp_Customer")}
                              mask="(99)99999-9999"
                            >
                              {() => (
                                <TextField
                                  fullWidth
                                  type="text"
                                  label="Whatsapp*"
                                  color="secondary"
                                  variant="outlined"
                                  {...getFieldProps("whatsapp_Customer")}
                                  helperText={
                                    touched.whatsapp_Customer &&
                                    errors.whatsapp_Customer
                                  }
                                  error={Boolean(
                                    touched.whatsapp_Customer &&
                                      errors.whatsapp_Customer
                                  )}
                                />
                              )}
                            </InputMask>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                              <InputLabel color="secondary" id="paymentType">
                                Forma de Pagamento*
                              </InputLabel>
                              <Select
                                fullWidth
                                color="secondary"
                                labelId="paymentType"
                                label="Forma de Pagamento*"
                                {...getFieldProps("idPaymentType_Customer")}
                                error={Boolean(
                                  touched.idPaymentType_Customer &&
                                    errors.idPaymentType_Customer
                                )}
                              >
                                {props?.paymentTypes?.map((item: any) => {
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>

                  {props && !props?.sellerUser ? (
                    <>
                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          type="text"
                          color="secondary"
                          variant="outlined"
                          label="Nome do Contato"
                          {...getFieldProps("contact_Customer")}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          helperText={
                            touched.contact_Customer && errors.contact_Customer
                          }
                          error={Boolean(
                            touched.contact_Customer && errors.contact_Customer
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          type="text"
                          color="secondary"
                          variant="outlined"
                          label={`Senha de Acesso${!state ? "*" : ""}`}
                          {...getFieldProps("costReportPassword_Customer")}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          helperText={
                            touched.costReportPassword_Customer &&
                            errors.costReportPassword_Customer
                          }
                          error={Boolean(
                            touched.costReportPassword_Customer &&
                              errors.costReportPassword_Customer
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputMask
                          {...getFieldProps("phone_Customer")}
                          mask="(99)9999-9999"
                        >
                          {() => (
                            <TextField
                              fullWidth
                              type="text"
                              label="Telefone"
                              color="secondary"
                              variant="outlined"
                              {...getFieldProps("phone_Customer")}
                              helperText={
                                touched.phone_Customer && errors.phone_Customer
                              }
                              error={Boolean(
                                touched.phone_Customer && errors.phone_Customer
                              )}
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputMask
                          {...getFieldProps("whatsapp_Customer")}
                          mask="(99)99999-9999"
                        >
                          {() => (
                            <TextField
                              fullWidth
                              type="text"
                              label="Whatsapp*"
                              color="secondary"
                              variant="outlined"
                              {...getFieldProps("whatsapp_Customer")}
                              helperText={
                                touched.whatsapp_Customer &&
                                errors.whatsapp_Customer
                              }
                              error={Boolean(
                                touched.whatsapp_Customer &&
                                  errors.whatsapp_Customer
                              )}
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          fullWidth
                          type="text"
                          color="secondary"
                          variant="outlined"
                          label="Cód. Contmatic*"
                          {...getFieldProps("contmaticCode_Customer")}
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          helperText={
                            touched.contmaticCode_Customer &&
                            errors.contmaticCode_Customer
                          }
                          error={Boolean(
                            touched.contmaticCode_Customer &&
                              errors.contmaticCode_Customer
                          )}
                        />
                      </Grid>
                    </>
                  ) : undefined}
                  {!props.sellerUser ? (
                    <>
                      <Grid item xs={12} md={3}>
                        <Tooltip
                          title={
                            !!state?.completeRegistration_Customer
                              ? "Não é possível alterar o código"
                              : ""
                          }
                        >
                          <TextField
                            fullWidth
                            type="text"
                            color="secondary"
                            variant="outlined"
                            label="Cód. do Cliente*"
                            {...getFieldProps("initials_Customer")}
                            disabled={!!state?.completeRegistration_Customer}
                            inputProps={{
                              maxLength: 3,
                              style: { textTransform: "uppercase" },
                            }}
                            helperText={
                              touched.initials_Customer &&
                              errors.initials_Customer
                            }
                            error={Boolean(
                              touched.initials_Customer &&
                                errors.initials_Customer
                            )}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          fullWidth
                          disablePortal
                          color="secondary"
                          options={groupData}
                          noOptionsText="Nenhuma Opção"
                          value={values.idTeam_Customer}
                          getOptionLabel={(option) => {
                            if (typeof option === "string")
                              return (
                                groupData
                                  ?.find(
                                    (x: any) => x.id === Number.parseInt(option)
                                  )
                                  ?.label.toUpperCase() || ""
                              );
                            else return option?.label.toUpperCase() || "";
                          }}
                          isOptionEqualToValue={(option: any, value) =>
                            value === option.id.toString() || value === ""
                          }
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              label="Grupo*"
                              color="secondary"
                              helperText={
                                touched.idTeam_Customer &&
                                errors.idTeam_Customer
                              }
                              error={Boolean(
                                touched.idTeam_Customer &&
                                  errors.idTeam_Customer
                              )}
                            />
                          )}
                          onChange={(event, value: any) => {
                            if (!value?.length)
                              formik.setFieldTouched("idTeam_Customer", true);
                            formik.setFieldValue(
                              "idTeam_Customer",
                              value?.id.toString() || "",
                              true
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Button
                          fullWidth
                          onClick={async () => {
                            navigate("/dashboard/grupos/cadastrar", {
                              state: {
                                values: state?.id_Customer
                                  ? {
                                      ...values,
                                      personType_Customer: "F",
                                      idSeller_User: state?.idSeller_User,
                                      id_Customer: state?.id_Customer,
                                      id_User: state?.id_User,
                                    }
                                  : { ...values, personType_Customer: "F" },
                                fromRegister: true,
                              },
                            });
                          }}
                          sx={{
                            lineHeight: "1rem",
                            height: "54px",
                            marginRight: "12px",
                            border: "2px solid #004A78",
                            color: "#FEFEFE",
                            bgcolor: "#004A78",
                            "&:hover": {
                              color: "#FEFEFE",
                              bgcolor: "#004A78",
                              border: "2px solid #004A78",
                            },
                          }}
                          color="secondary"
                          variant="outlined"
                        >
                          Criar Grupo
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <InputLabel id="prepaid_Customer" color="secondary">
                            Cliente pré pago*
                          </InputLabel>
                          <Select
                            fullWidth
                            color="secondary"
                            labelId="prepaid_Customer"
                            label="Cliente pré pago*"
                            {...getFieldProps("prepaid_Customer")}
                            error={Boolean(
                              touched.gender_User && errors.gender_User
                            )}
                          >
                            <MenuItem value={1}>Sim</MenuItem>
                            <MenuItem value={0}>Não</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <InputLabel id="single" color="secondary">
                            Simples Nacional*
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="single"
                            color="secondary"
                            label="Simples Nacional*"
                            {...getFieldProps("singleNationalChooser_Customer")}
                            error={Boolean(
                              touched.singleNationalChooser_Customer &&
                                errors.singleNationalChooser_Customer
                            )}
                          >
                            <MenuItem value={1}>Sim</MenuItem>
                            <MenuItem value={0}>Não</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <FormControl fullWidth>
                          <InputLabel color="secondary" id="paymentType">
                            Forma de Pagamento*
                          </InputLabel>
                          <Select
                            fullWidth
                            color="secondary"
                            labelId="paymentType"
                            label="Forma de Pagamento*"
                            {...getFieldProps("idPaymentType_Customer")}
                            error={Boolean(
                              touched.idPaymentType_Customer &&
                                errors.idPaymentType_Customer
                            )}
                          >
                            {props?.paymentTypes?.map((item: any) => {
                              return (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  ) : undefined}
                  
   
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        multiline
                        type="text"
                        color="secondary"
                        variant="outlined"
                        label="Observações*"

                        {...getFieldProps("observations_Customer")}
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        helperText={
                          touched.observations_Customer &&
                          errors.observations_Customer
                        }
                        error={Boolean(
                          touched.observations_Customer &&
                            errors.observations_Customer
                        )}
                      />
                    </Grid>

                  <Grid item xs={12} md={7}>
                    <Grid container columnSpacing={2} rowSpacing={2}>
                      <Grid item xs={12} md={5}>
                        <Typography variant="subtitle1" gutterBottom>
                          Endereço Comum{props.sellerUser ? "" : "*"}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={7}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="secondary"
                              disableTouchRipple
                              checked={sameAddress}
                              sx={{ padding: 0, pr: 1 }}
                              inputProps={{ "aria-label": "primary checkbox" }}
                              onChange={() => {
                                formik.setFieldValue("zipcode", "", false);
                                formik.setFieldValue("city", "", false);
                                formik.setFieldValue("state", "", false);
                                formik.setFieldValue("street", "", false);
                                formik.setFieldValue("number", "", false);
                                formik.setFieldValue("complement", "", false);
                                formik.setFieldValue(
                                  "neighbourhood",
                                  "",
                                  false
                                );

                                setSameAddress(!sameAddress);
                              }}
                            />
                          }
                          label="MESMO ENDEREÇO DE COBRANÇA"
                        />

                        <Typography
                          variant="subtitle1"
                          gutterBottom
                        ></Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <InputMask
                          {...getFieldProps("zipcode_Address")}
                          mask="99999-999"
                        >
                          {() => (
                            <TextField
                              fullWidth
                              type="text"
                              label={`CEP${props.sellerUser ? "" : "*"}`}
                              color="secondary"
                              variant="outlined"
                              onKeyUp={() => searchZipcode(true)}
                              {...getFieldProps("zipcode_Address")}
                              error={Boolean(
                                touched.zipcode_Address &&
                                  errors.zipcode_Address
                              )}
                              helperText={
                                touched.zipcode_Address &&
                                errors.zipcode_Address
                              }
                            />
                          )}
                        </InputMask>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          type="text"
                          label={`Cidade${props.sellerUser ? "" : "*"}`}
                          {...getFieldProps("city_Address")}
                          variant="outlined"
                          color="secondary"
                          error={Boolean(
                            touched.city_Address && errors.city_Address
                          )}
                          helperText={
                            touched.city_Address && errors.city_Address
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          fullWidth
                          type="text"
                          label={`Estado${props.sellerUser ? "" : "*"}`}
                          {...getFieldProps("state_Address")}
                          variant="outlined"
                          color="secondary"
                          error={Boolean(
                            touched.state_Address && errors.state_Address
                          )}
                          helperText={
                            touched.state_Address && errors.state_Address
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <TextField
                          fullWidth
                          type="text"
                          label={`Logradouro${props.sellerUser ? "" : "*"}`}
                          {...getFieldProps("street_Address")}
                          variant="outlined"
                          color="secondary"
                          error={Boolean(
                            touched.street_Address && errors.street_Address
                          )}
                          helperText={
                            touched.street_Address && errors.street_Address
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <TextField
                          fullWidth
                          type="text"
                          label={`Núm.${props.sellerUser ? "" : "*"}`}
                          {...getFieldProps("number_Address")}
                          variant="outlined"
                          color="secondary"
                          error={Boolean(
                            touched.number_Address && errors.number_Address
                          )}
                          helperText={
                            touched.number_Address && errors.number_Address
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          type="text"
                          label="Complemento"
                          {...getFieldProps("complement_Address")}
                          variant="outlined"
                          color="secondary"
                          error={Boolean(
                            touched.complement_Address &&
                              errors.complement_Address
                          )}
                          helperText={
                            touched.complement_Address &&
                            errors.complement_Address
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          type="text"
                          label={`Bairro${props.sellerUser ? "" : "*"}`}
                          {...getFieldProps("neighbourhood_Address")}
                          variant="outlined"
                          color="secondary"
                          error={Boolean(
                            touched.neighbourhood_Address &&
                              errors.neighbourhood_Address
                          )}
                          helperText={
                            touched.neighbourhood_Address &&
                            errors.neighbourhood_Address
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <Typography variant="subtitle1" gutterBottom>
                      Unidade Opto*
                    </Typography>
                    <RadioGroup {...getFieldProps("billingPlace_Customer")}>
                      {COMPANIES.map((item) => (
                        <FormControlLabel
                          key={item.value}
                          value={item.value}
                          control={<Radio />}
                          label={item.name}
                        />
                      ))}
                    </RadioGroup>
                  </Grid>
                  {!sameAddress ? (
                    <Grid item xs={12} md={12}>
                      <Grid container columnSpacing={2} rowSpacing={2}>
                        <Grid item xs={12} md={12}>
                          <Typography variant="subtitle1" gutterBottom>
                            Endereço de Cobrança{props.sellerUser ? "" : "*"}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <InputMask
                            {...getFieldProps("zipcode")}
                            mask="99999-999"
                          >
                            {() => (
                              <TextField
                                fullWidth
                                type="text"
                                label={`CEP${props.sellerUser ? "" : "*"}`}
                                color="secondary"
                                variant="outlined"
                                onKeyUp={() => searchZipcode(false)}
                                {...getFieldProps("zipcode")}
                                error={Boolean(
                                  touched.zipcode && errors.zipcode
                                )}
                                helperText={touched.zipcode && errors.zipcode}
                              />
                            )}
                          </InputMask>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            type="text"
                            label={`Cidade${props.sellerUser ? "" : "*"}`}
                            color="secondary"
                            variant="outlined"
                            {...getFieldProps("city")}
                            error={Boolean(touched.city && errors.city)}
                            helperText={touched.city && errors.city}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            fullWidth
                            type="text"
                            label={`Estado${props.sellerUser ? "" : "*"}`}
                            color="secondary"
                            variant="outlined"
                            {...getFieldProps("state")}
                            error={Boolean(touched.state && errors.state)}
                            helperText={touched.state && errors.state}
                          />
                        </Grid>
                        <Grid item xs={12} md={10}>
                          <TextField
                            fullWidth
                            type="text"
                            label={`Logradouro${props.sellerUser ? "" : "*"}`}
                            {...getFieldProps("street")}
                            variant="outlined"
                            color="secondary"
                            error={Boolean(touched.street && errors.street)}
                            helperText={touched.street && errors.street}
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <TextField
                            fullWidth
                            type="text"
                            label={`Núm.${props.sellerUser ? "" : "*"}`}
                            {...getFieldProps("number")}
                            variant="outlined"
                            color="secondary"
                            error={Boolean(touched.number && errors.number)}
                            helperText={touched.number && errors.number}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            type="text"
                            label="Complemento"
                            {...getFieldProps("complement")}
                            variant="outlined"
                            color="secondary"
                            error={Boolean(
                              touched.complement && errors.complement
                            )}
                            helperText={touched.complement && errors.complement}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            type="text"
                            label={`Bairro${props.sellerUser ? "" : "*"}`}
                            {...getFieldProps("neighbourhood")}
                            variant="outlined"
                            color="secondary"
                            error={Boolean(
                              touched.neighbourhood && errors.neighbourhood
                            )}
                            helperText={
                              touched.neighbourhood && errors.neighbourhood
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : undefined}
                </Grid>
              ) : (
                <Typography sx={{ textAlign: "center", paddingY: "56px" }}>
                  Carregando...
                </Typography>
              )}
            </Container>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  );
}
