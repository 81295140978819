/* eslint-disable camelcase */
import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import ImageIcon from "@mui/icons-material/Image";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
import AlertMessage from "../../../../components/alert/alert";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../../../../components/Page";
import Scrollbar from "../../../../components/Scrollbar";
import ProductOptions from "../components/ProductOptions";
import ClientsListHead from "../components/ProductListHead";
import ClientsListToolbar from "../components/ProductListToolbar";
import SearchNotFound from "../../../../components/SearchNotFound";

import productService from "../services/productService";
import ProductModal from "../components/ProductModal";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "pictureFilepath_Lens", label: "Imagem", alignRight: true },
  { id: "name_Lens", label: "Produto", alignRight: false },
  { id: "", label: "Editar", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array: any, comparator: any, query: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_lens) =>
        _lens.name_Lens.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el: any) => el[0]);
}

export default function Products() {
  const [page, setPage] = useState(0);
  const [data, setData]: any = useState([]);
  const [order, setOrder] = useState("asc");
  const [showError, setError] = useState(false);
  const [orderBy, setOrderBy] = useState("name");
  const [modalData, setModalData] = useState("");
  const [filterName, setFilterName] = useState("");
  const [showSuccess, setSuccess] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  useEffect(() => {
    if (!modalOpen || showSuccess) {
      productService
        .getAll()
        .then((data: any) => {
          setData(data.data);
        })
        .catch((error: any) => {
          console.error(error);
        });
    }
  }, [modalOpen, showSuccess]);

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const handleFilterByName = (event: any) => {
    setFilterName(event.target.value);
  };

  const filteredLens: any = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );

  const isLensNotFound = filteredLens.length === 0;

  function openModalEmpty() {
    setModalOpen(true);
    setModalData("");
  }
  return (
    <Page title="Produtos | Optools">
      <Container>
        <ProductModal
          title="Novo Produto"
          open={modalOpen}
          confirmButtonText="Concluir"
          modalData={modalData}
          handler={() => setModalOpen(false)}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            Produtos
          </Typography>
        </Stack>

        <Stack direction={["column", "row"]} justifyContent="space-between">
          <ClientsListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Button
            to="#"
            sx={{
              height: "48px",
              borderRadius: "40px",
              background: "#004A78",
              marginTop: ["8px", "24px"],
              marginBottom: ["16px", 0],
              "&:hover": {
                bgcolor: "#226C9A",
              },
            }}
            color="secondary"
            variant="contained"
            component={RouterLink}
            onClick={() => openModalEmpty()}
            startIcon={<Icon icon={plusFill} />}
          >
            Cadastrar novo
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ClientsListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {data.length ? (
                    filteredLens
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any) => {
                        const { id_Lens, name_Lens, pictureFilepath_Lens } =
                          row;

                        return (
                          <TableRow
                            hover
                            key={id_Lens}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell>
                              <Avatar
                                alt={name_Lens}
                                variant="rounded"
                                src={pictureFilepath_Lens}
                                sx={{
                                  marginLeft: "18%",
                                  width: "72px",
                                  height: "72px",
                                }}
                              >
                                <ImageIcon />
                              </Avatar>
                            </TableCell>
                            <TableCell align="left">
                              <Typography
                                variant="subtitle2"
                                noWrap
                                sx={{ color: "#004A78", fontSize: "14px" }}
                              >
                                {name_Lens}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">
                              <ProductOptions
                                setModalData={(value: any) =>
                                  setModalData(value)
                                }
                                setSuccess={() => setSuccess(true)}
                                setError={() => setError(true)}
                                setModalOpen={(value: any) =>
                                  setModalOpen(value)
                                }
                                id={id_Lens}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Stack
                          width="100%"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Icon
                            icon={inboxOutline}
                            width="56px"
                            color="#909090"
                          />
                          <Typography
                            mt={1}
                            variant="body1"
                            noWrap
                            sx={{ color: "#909090" }}
                          >
                            Nenhum item encontrado...
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                {isLensNotFound && data.length ? (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : undefined}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            page={page}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[10, 20, 30]}
            labelRowsPerPage="Linhas por página:"
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={(rowsInfo) => {
              const totalPages = Math.ceil(
                rowsInfo.count / rowsPerPage
              ).toString();
              return (
                <>
                  {`Página: ${page + 1}${`/${
                    totalPages === "0" ? "1" : totalPages
                  }`}`}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {`Total: ${rowsInfo.count}`}
                </>
              );
            }}
          />
        </Card>
      </Container>
      {showSuccess && <AlertMessage open={true} message="Produto excluído" />}
      {showError && (
        <AlertMessage open={true} message="Erro ao excluir produto" />
      )}
    </Page>
  );
}
