import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

export default function Photo(props: any) {
  async function getPhoto(file: any) {
    const files: any[] = file.target.files;
    await props.setFileFire(files);
    const url: any = URL.createObjectURL(file.target.files[0]);
    props.setImageFire(url);
  }

  return props.imageFire === "" ? (
    <Box
      component="span"
      sx={{
        border: "3px dashed #C1BBEB",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Button
        fullWidth
        sx={{ textAlign: "center", py: 6, px: 2 }}
        variant="text"
        color="secondary"
        component="label"
      >
        + Adicionar Imagem
        <input accept="image/*" type="file" hidden onChange={getPhoto} />
      </Button>
    </Box>
  ) : (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <input
        accept="image/*"
        onChange={getPhoto}
        hidden
        id="icon-button-file"
        type="file"
      />
      <label htmlFor="icon-button-file">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
          sx={{ p: 0 }}
        >
          <Avatar
            variant={props.logo ? "rounded" : "circular"}
            sx={{ width: "128px", height: "128px" }}
            src={props.imageFire}
          />
        </IconButton>
      </label>
    </Box>
  );
}
