import { Icon } from "@iconify/react";
import { IconButton } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import downloadOutline from "@iconify/icons-eva/download-outline";

// import tagService from "../services/tagService";
import AlertMessage from "../../../../components/alert/alert";

// ----------------------------------------------------------------------

export default function TagsOptions(props: any) {
  const ref = useRef(null);
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] = useState(false);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  return (
    <>
      <IconButton ref={ref} onClick={() => window.open(props.link)}>
        <Icon icon={downloadOutline} width={20} height={20} />
      </IconButton>

      {showSuccess && <AlertMessage open={true} message="Download iniciado!" />}
      {showError && (
        <AlertMessage open={true} message="Falha ao obter arquivo" />
      )}
    </>
  );
}
