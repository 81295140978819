const firebaseConfig = {
  apiKey: "AIzaSyDsrf877nVtfSYC6gH22wDNDUSQrqsRMes",
  authDomain: "opto-tools-c7765.firebaseapp.com",
  projectId: "opto-tools-c7765",
  storageBucket: "opto-tools-c7765.appspot.com",
  messagingSenderId: "696129427223",
  appId: "1:696129427223:web:bdae6937e9ffe71e3dc952",
  measurementId: "G-19XCNEYJH5"
};

 export default firebaseConfig;