/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import * as Yup from "yup";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useLocation } from "react-router";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Card,
  Grid,
  Radio,
  TextField,
  CardHeader,
  Typography,
  RadioGroup,
  CardContent,
  FormControlLabel,
} from "@mui/material";

import Photo from "../../../../components/Photo";
import { useData } from "../../../../contexts/client";

export const COMPANIES = [
  { name: "PA - Porto Alegre", value: "2" },
  { name: "NE - Fortaleza", value: "3" },
  { name: "SP - São Paulo", value: "4" },
];

export const ROLES = [
  { name: "Administrador", value: "1" },
  { name: "Operacional", value: "3" },
  { name: "Financeiro", value: "4" },
  { name: "Comercial", value: "5" },
  { name: "Vendedor", value: "6" },
];

export default function FormDataCollab(props: any) {
  const { state }: any = useLocation();
  const [imageFire, setImageFire] = useState("");
  const [fileFire, setFileFire] = useState<any[]>([]);
  const { handleColabData } = useData();

  const RegisterSchema = Yup.object().shape({
    // Client Data
    cpf_User: Yup.string()
      .required("Cpf é obrigatório")
      .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "CPF Inválido"),
    name_User: Yup.string().required("Nome é obrigatório"),
    email_User: Yup.string()
      .email("Email inválido!")
      .required("E-mail é obrigatório"),
    phone_User: Yup.string()
      .required("Telefone é obrigatório")
      .matches(/^\(\d{2}\)\d{5}-\d{4}$/, "Número Inválido"),

    idCompany_User: Yup.string().required("Unidade da Opto é obrigatório"),
    idRole_User: Yup.string().required("Unidade da Opto é obrigatório"),
  });

  var formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      // Client Data
      name_User: state ? state.name_User : "",
      phone_User: state
        ? state.phone_User?.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1)$2-$3")
        : "",
      email_User: state ? state.email_User : "",
      cpf_User: state
        ? state.cpf_PrivatePerson?.replace(
            /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
            "$1.$2.$3-$4"
          )
        : "",
      idCompany_User: state ? state.company?.id?.toString() : "",
      idRole_User: state ? state.role?.id?.toString() : "",

      pictureFilename_Customer: state ? state.pictureFilename_User : "",
      pictureFilepath_Customer: state ? state.pictureFilepath_User : "",
    },
    validateOnChange: true,
    // isInitialValid: false,
    validateOnMount: true,
    validationSchema: RegisterSchema,
    onSubmit: () => {
      // TODO: CADASTRAR LENTE
    },
  };

  const formik = useFormik(formikValues);

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (formik.isValid) {
      props.setValid(true);
      handleColabData(values, fileFire[0]);
    } else if (props.isValid) props.setValid(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isValid, values, imageFire, fileFire]);

  useEffect(() => {
    setImageFire(values.pictureFilepath_Customer);
  }, [values.pictureFilepath_Customer]);

  return (
    <Card>
      <CardHeader
        sx={{
          background: "#004A78",
          color: "#fff",
          paddingLeft: "30px",
          paddingBottom: "10px",
          paddingTop: "10px",
        }}
        title="Dados do Colaborador"
      />
      <CardContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={3} sx={{ marginTop: "0.5rem" }}>
                <Photo
                  imageFire={imageFire}
                  setImageFire={(value: any) => setImageFire(value)}
                  setFileFire={(value: any[]) => setFileFire(value)}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="text"
                      fullWidth={true}
                      color="secondary"
                      variant="outlined"
                      label="Nome Completo*"
                      {...getFieldProps("name_User")}
                      helperText={touched.name_User && errors.name_User}
                      error={Boolean(touched.name_User && errors.name_User)}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputMask
                      {...getFieldProps("cpf_User")}
                      mask="999.999.999-99"
                    >
                      {() => (
                        <TextField
                          type="text"
                          label="CPF*"
                          fullWidth={true}
                          color="secondary"
                          variant="outlined"
                          {...getFieldProps("cpf_User")}
                          error={Boolean(touched.cpf_User && errors.cpf_User)}
                          helperText={touched.cpf_User && errors.cpf_User}
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="text"
                      label="E-mail*"
                      {...getFieldProps("email_User")}
                      variant="outlined"
                      color="secondary"
                      fullWidth={true}
                      error={Boolean(touched.email_User && errors.email_User)}
                      helperText={touched.email_User && errors.email_User}
                      inputProps={{ style: { textTransform: "uppercase" } }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputMask
                      {...getFieldProps("phone_User")}
                      mask="(99)99999-9999"
                    >
                      {() => (
                        <TextField
                          type="text"
                          label="Telefone*"
                          {...getFieldProps("phone_User")}
                          variant="outlined"
                          color="secondary"
                          fullWidth={true}
                          error={Boolean(
                            touched.phone_User && errors.phone_User
                          )}
                          helperText={touched.phone_User && errors.phone_User}
                        />
                      )}
                    </InputMask>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid sx={{ marginTop: ["-32px", "0"] }} container spacing={5}>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={5}>
                <Typography variant="subtitle1" gutterBottom>
                  Tipo de Acesso *
                </Typography>
                <RadioGroup {...getFieldProps("idRole_User")}>
                  <Grid sx={{ marginTop: "-0.2rem" }} container>
                    {ROLES.map((item) => (
                      <Grid key={item.value} item xs={12} md={5}>
                        <FormControlLabel
                          value={item.value}
                          control={<Radio color="secondary" />}
                          label={item.name}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RadioGroup>
              </Grid>
              <Grid item xs={12} md={3}>
                <Typography variant="subtitle1" gutterBottom>
                  Unidade Opto *
                </Typography>
                <RadioGroup
                  sx={{ marginTop: "-0.2rem" }}
                  {...getFieldProps("idCompany_User")}
                >
                  {COMPANIES.map((item) => (
                    <FormControlLabel
                      key={item.value}
                      value={item.value}
                      control={<Radio color="secondary" />}
                      label={item.name}
                    />
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </CardContent>
    </Card>
  );
}
