import { useState } from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import calendarIcon from "@iconify/icons-ic/round-calendar-today";
// material
import { styled } from "@mui/system";
import {
  Box,
  Toolbar,
  Button,
  Typography,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  float: "left",
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 0, 0, 0),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }: any) => ({
  height: 48,
  float: "left",
  borderRadius: "40px",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const DatePicker = styled(OutlinedInput)(({ theme }: any) => ({
  height: 48,
  float: "left",
  borderRadius: "40px",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ReportListToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterDate: PropTypes.func,
};

export default function ReportListToolbar({
  filterName,
  onFilterName,
  onFilterDate,
}: any) {
  const [input1Type, setInput1Type] = useState("text");
  const [inputType, setInputType] = useState("text");
  const [today, setToday] = useState("");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");

  useEffect(() => {
    let datetime: any = new Date();
    let dd = String(datetime.getDate()).padStart(2, "0");
    let mm = String(datetime.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = datetime.getFullYear();

    setToday(yyyy + "-" + mm + "-" + dd);
  }, [setToday, today]);

  return (
    <RootStyle sx={{ height: ["", 96], flexDirection: ["column", "row"] }}>
      <SearchStyle
        sx={{
          width: ["100%", 256],
          margin: [0, "24px 8px auto -24px"],
        }}
        type="text"
        value={filterName}
        placeholder="Pesquisar..."
        inputProps={{ style: { textTransform: "uppercase" } }}
        onChange={(element: any) => onFilterName(element.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <Box
              component={Icon}
              icon={searchFill}
              sx={{ color: "text.disabled" }}
            />
          </InputAdornment>
        }
      />

      <DatePicker
        sx={{
          width: ["100%", 198],
          margin: ["10px 0", "auto 8px auto 4px"],
        }}
        id="date"
        value={date1}
        type={inputType}
        placeholder="Data inicial"
        onFocus={() => setInputType("date")}
        onBlur={() => date1 === "" && setInputType("text")}
        onChange={(element: any) => {
          const value = element.target.value;
          setDate1(value);
          if (date2 !== "") {
            if (value !== "") onFilterDate(`${value} ${date2}`);
            else onFilterDate(`&${date2}`);
          } else onFilterDate(value);
        }}
        endAdornment={
          inputType === "text" && (
            <InputAdornment position="end">
              <Icon
                style={{ marginRight: "3px" }}
                width="18px"
                icon={calendarIcon}
                color="#000"
              />
            </InputAdornment>
          )
        }
      />

      <DatePicker
        sx={{
          width: ["100%", 198],
          margin: ["0 0 10px 0", "auto 8px auto 4px"],
        }}
        id="date"
        value={date2}
        type={input1Type}
        placeholder="Data final"
        onFocus={(input: any) => setInput1Type("date")}
        onChange={(element: any) => {
          const value = element.target.value;
          setDate2(element.target.value);
          if (date1 !== "") {
            if (value !== "") onFilterDate(`${date1} ${value}`);
            else onFilterDate(date1);
          } else {
            if (value !== "") onFilterDate(`&${value}`);
            else onFilterDate("");
          }
        }}
        onBlur={(input: any) => date2 === "" && setInput1Type("text")}
        endAdornment={
          input1Type === "text" && (
            <InputAdornment position="end">
              <Icon
                style={{ marginRight: "3px" }}
                width="18px"
                icon={calendarIcon}
                color="#000"
              />
            </InputAdornment>
          )
        }
      />

      <Button
        sx={{ ml: [0, 2], mb: [2, 0] }}
        color="inherit"
        onClick={() => {
          setDate1("");
          setDate2("");
          setInputType("text");
          setInput1Type("text");
          onFilterName("");
          onFilterDate("");
        }}
      >
        <HighlightOffIcon sx={{ color: "#A9A9A9" }} />
        <Typography variant="body2" color="#878787" sx={{ ml: "3px" }}>
          LIMPAR FILTROS
        </Typography>
      </Button>
    </RootStyle>
  );
}
