import * as Yup from "yup";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
import jwt_decode from "jwt-decode";
// Material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";

import { LoadingButton } from "@mui/lab";

import authService from "../../authService";

import AlertMessage from "../../../../components/alert/alert";

export default function LoginForm() {
  const navigate = useNavigate();

  const isMounted = useRef(true);
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Erro ao efetuar o login");

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  });

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Endereço de e-mail inválido")
      .required("E-mail é obrigatório"),
    password: Yup.string().required("Senha é obrigatória"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (values, actions) => {
      authService
        .authentication(values)
        .then((data: any) => {
          const { token } = data.data;
          const decoded = jwt_decode(token);
          localStorage.setItem("user", JSON.stringify(decoded));
          localStorage.setItem("token", token);
          setSuccess(true);
          setTimeout(() => {
            navigate("/dashboard/app");
          }, 1500);
        })
        .catch((error) => {
          if (
            error.response.data.message === "Email not found!" ||
            error.response.data.message === "Incorrect password!"
          )
            setErrorMessage("Email ou senha incorretos!");
          else setErrorMessage("Erro ao efetuar o login");
          actions.setSubmitting(false);
          setError(true);
        });
    },
  });

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Usuário"
            {...getFieldProps("email")}
            variant="filled"
            color="secondary"
            sx={{
              background: "#E2E2E2",
            }}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Senha"
            {...getFieldProps("password")}
            variant="filled"
            color="secondary"
            sx={{
              background: "#E2E2E2",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent="flex-end"
          sx={{
            fontSize: "16px",
            marginTop: "16px",
            color: "#004A78",
            marginBottom: "52px",
          }}
        ></Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          color="secondary"
          variant="contained"
          loading={isSubmitting}
          sx={{ borderRadius: "30px" }}
        >
          Login
        </LoadingButton>
      </Form>
      {showSuccess && <AlertMessage open={true} message="Seja Bem Vindo!" />}
      {showError && <AlertMessage open={true} message={errorMessage} />}
    </FormikProvider>
  );
}
