import * as Yup from "yup";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import adminService from "../services/administrationService";
import AlertMessage from "../../../../components/alert/alert";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Stack,
  TextField,
  CardActions,
  CardContent,
  Typography,
  Modal,
  Card,
} from "@mui/material";

// import clientService from '../services/clientService';

interface ModalProps {
  open: boolean;
  handler: () => void;
}

export default function IpsModal(props: ModalProps) {
  const [showError, setError] = useState(false);
  const [onUpdate, setOnUpdate] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const RegisterSchema = Yup.object().shape({
    number_Ip1: Yup.string().max(30).required("O IP é obrigatório"),
    number_Ip2: Yup.string().max(30).required("O IP é obrigatório"),
    number_Ip3: Yup.string().max(30).required("O IP é obrigatório"),
    number_Ip4: Yup.string().max(30).required("O IP é obrigatório"),
    id_Ip1: Yup.number(),
    id_Ip2: Yup.number(),
    id_Ip3: Yup.number(),
    id_Ip4: Yup.number(),
  });

  var formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      number_Ip1: "",
      number_Ip2: "",
      number_Ip3: "",
      number_Ip4: "",
      id_Ip1: "",
      id_Ip2: "",
      id_Ip3: "",
      id_Ip4: "",
    },
    onSubmit: () => {
      setSubmitting(true);

      adminService
        .changeIps(values, onUpdate)
        .then((res: any) => {
          setSubmitting(false);
          setSuccess(true);
          formik.resetForm();
          props.handler();
        })
        .catch((error: any) => {
          setSubmitting(false);
          setError(true);
        });
    },
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: RegisterSchema,
  };

  const formik = useFormik(formikValues);

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  useEffect(() => {
    if (props.open) {
      adminService
        .getIp()
        .then((res: any) => {
          if (res.data.length) {
            setOnUpdate(true);
            const sortedIps = [
              res.data.find((item: any) => item.initials_Company === "SP"),
              res.data.find((item: any) => item.initials_Company === "NE"),
              res.data.filter((item: any) => item.initials_Company === "PA")[0],
              res.data.filter((item: any) => item.initials_Company === "PA")[1],
            ];
            setFieldValue("number_Ip1", sortedIps[0].number_Ip);
            setFieldValue("number_Ip2", sortedIps[1].number_Ip);
            setFieldValue("number_Ip3", sortedIps[2].number_Ip);
            setFieldValue("number_Ip4", sortedIps[3].number_Ip);

            setFieldValue("id_Ip1", sortedIps[0].id_Ip);
            setFieldValue("id_Ip2", sortedIps[1].id_Ip);
            setFieldValue("id_Ip3", sortedIps[2].id_Ip);
            setFieldValue("id_Ip4", sortedIps[3].id_Ip);
          } else setOnUpdate(false);
        })
        .catch((error) => setOnUpdate(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          formik.resetForm();
          props.handler();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ minWidth: "30vw" }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <CardContent>
                <Typography
                  variant="h4"
                  marginBottom={4}
                  color="#004A78"
                  gutterBottom
                >
                  IPs das Unidades
                </Typography>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    IP de SP - São Paulo
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    color="secondary"
                    variant="outlined"
                    placeholder="000.000.0.000"
                    {...getFieldProps("number_Ip1")}
                    error={Boolean(touched.number_Ip1 && errors.number_Ip1)}
                    helperText={touched.number_Ip1 && errors.number_Ip1}
                  />
                </Stack>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    IP de NE - Fortaleza
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    color="secondary"
                    variant="outlined"
                    placeholder="000.000.0.000"
                    {...getFieldProps("number_Ip2")}
                    error={Boolean(touched.number_Ip2 && errors.number_Ip2)}
                    helperText={touched.number_Ip2 && errors.number_Ip2}
                  />
                </Stack>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    IP 1 de PA - Porto Alegre
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    color="secondary"
                    variant="outlined"
                    placeholder="000.000.0.000"
                    {...getFieldProps("number_Ip3")}
                    error={Boolean(touched.number_Ip3 && errors.number_Ip3)}
                    helperText={touched.number_Ip3 && errors.number_Ip3}
                  />
                </Stack>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    IP 2 de PA - Porto Alegre
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    color="secondary"
                    variant="outlined"
                    placeholder="000.000.0.000"
                    {...getFieldProps("number_Ip4")}
                    error={Boolean(touched.number_Ip4 && errors.number_Ip4)}
                    helperText={touched.number_Ip4 && errors.number_Ip4}
                  />
                </Stack>
              </CardContent>
              <CardActions>
                <LoadingButton
                  fullWidth
                  type="submit"
                  loading={isSubmitting}
                  disabled={!formik.isValid}
                  variant="contained"
                  sx={{
                    m: 1,
                    height: "48px",
                    borderRadius: "30px",
                    backgroundColor: "#CDCDCD",
                    color: "#004A78",
                    "&:hover": {
                      bgcolor: "#004A78",
                      color: "#FEFEFE",
                    },
                  }}
                  autoFocus
                >
                  SALVAR
                </LoadingButton>
              </CardActions>
            </Form>
          </FormikProvider>
        </Card>
      </Modal>
      {showSuccess && (
        <AlertMessage open={true} message="IPs cadastrados com sucesso!" />
      )}
      {showError && (
        <AlertMessage open={true} message="Erro ao efetuar cadastro" />
      )}
    </>
  );
}
