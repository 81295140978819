import http from "../../../services/http-common";
import firebase from "firebase/app";
import "firebase/storage";

class ProfileService {
  // eslint-disable-next-line class-methods-use-this
  async saveProfile(data: any) {
    const parse: any = localStorage.getItem("user");
    const user = JSON.parse(parse);
    let urlDownload = "";
    try {
      if (data.file) {
        const storage = firebase.storage();

        const filePath = `clientes/${data.name_User} - ${data.cpf_User}`;

        const storageRef = await storage.ref(filePath);
        await storageRef.put(data.file);

        urlDownload = await storageRef.getDownloadURL();
      } else {
        urlDownload = data.pictureFilepath_Customer;
      }

      const customer = {
        name_Customer: data.name_Customer,
        cpf_Customer: data.cpf_Customer?.replace(".", "").replace(".", "").replace("-", ""),
        contact_Customer: data.contact_Customer,
        email_Customer: data.email_User,
        phone_Customer: data.phone_User?.replace(/[^0-9]/g, ""),

        address: {
          street_Address: data.street,
          number_Address: data.number,
          complement_Address: data.complement,
          zipcode_Address: data.zipcode,
          neighbourhood_Address: data.neighbourhood,
          city_Address: data.city,
          state_Address: data.state,
        },

        billingPlace_Customer: data.billingPlace_Customer,
        pictureFilename_User: `${data.name_Customer} - ${data.cpf_Customer}`,
        pictureFilepath_User: urlDownload,
      };

      return http.put(`/customer/update/${user?.idUser}/${user?.idCustomer}`, customer);
    } catch (error) {
      console.log(error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getData() {
    const parse: any = localStorage.getItem("user");
    const user = JSON.parse(parse);
    return http.get(`customer/profile/${user?.idCustomer}`);
  }

  getSelect(type: string) {
    return http.get(`/${type}/find`);
  }

  getById(id: string) {
    return http.get(`/order/${id}`);
  }

  getActions(id: string) {
    return http.get(`/order/actions/${id}`);
  }
}

export default new ProfileService();
