import * as Yup from "yup";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import arrowDown from "@iconify/icons-eva/arrow-ios-downward-outline";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardActions,
  CardContent,
  Typography,
  Modal,
  Card,
  Grid,
  Checkbox,
  Accordion,
  Container,
  FormControlLabel,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import actionService from "../services/actionService";

interface ModalProps {
  data: any;
  open: boolean;
  code_Order: string;
  handler: () => void;
  setError: (value: boolean) => void;
  setSuccess: (value: boolean) => void;
}

export default function OrderModal(props: ModalProps) {
  const [disabled, setDisabled] = useState(true);
  const [expanded, setExpanded]: any = useState();
  const [orderDown, setOrderDown] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const RegisterSchema = Yup.object().shape({
    lensesAmount_OS: Yup.number(),
    idRework_OR: Yup.number(),
    idMessage_OS: Yup.number(),
    nameContinueProcess_OS: Yup.string(),
  });

  var formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      lensesAmount_OS: "",
      idRework_OR: "",
      idMessage_OS: "",
      nameContinueProcess_OS: "",
    },
    onSubmit: () => {},
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: RegisterSchema,
  };

  const formik = useFormik(formikValues);

  const { errors, touched, values, handleSubmit, getFieldProps, resetForm } =
    formik;

  const saveAction = () => {
    setSubmitting(true);
    actionService
      .saveAction({
        ...values,
        code_Order: props.code_Order,
        idStage_OS: expanded,
      })
      .then(() => {
        setSubmitting(false);
        props.setSuccess(true);
        props.handler();
      })
      .catch((error) => {
        console.error(error);
        setSubmitting(false);
        props.setError(true);
      });

    setExpanded("");
  };

  const handleChange = (panel: number) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const checkValid = () => {
    if (expanded === 3 || expanded === 6) {
      if (values.lensesAmount_OS !== "" && values.idMessage_OS !== "")
        setDisabled(false);
      else setDisabled(true);
    } else if (expanded === 4) {
      if (
        values.idRework_OR !== "" &&
        values.lensesAmount_OS !== "" &&
        values.idMessage_OS !== ""
      )
        setDisabled(false);
      else setDisabled(true);
    } else if (expanded === 8) {
      if (values.nameContinueProcess_OS !== "" && values.lensesAmount_OS !== "")
        setDisabled(false);
      else setDisabled(true);
    } else if (orderDown) setDisabled(false);
    else setDisabled(true);
  };

  useEffect(() => {
    if (expanded) {
      resetForm();
      setOrderDown(false);
    }
    setDisabled(true);
  }, [expanded, resetForm]);

  useEffect(() => {
    if (values) {
      checkValid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, orderDown]);

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          resetForm();
          props.handler();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Container maxWidth="md">
          <Card>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <CardContent>
                  <Typography variant="h4" color="#004A78">
                    Nova Ação
                  </Typography>
                  <Typography variant="body1" marginBottom={3}>
                    Inserir nova ação
                  </Typography>

                  <Accordion
                    expanded={expanded === 4}
                    onChange={handleChange(4)}
                    sx={{
                      border: "1px solid #c9c9c9",
                      borderRadius: "6px 6px 0 0",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<Icon icon={arrowDown} color="#FFF" />}
                      sx={{
                        bgcolor: "#004A78",
                        color: "#FFF",
                        borderRadius: "4px 4px 0 0",
                      }}
                    >
                      <Typography variant="h5">Retrabalho</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: "24px" }}>
                      <Grid container columnSpacing={3} rowSpacing={3}>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel color="secondary" id="message">
                              Mensagem*
                            </InputLabel>
                            <Select
                              fullWidth
                              label="Mensagem*"
                              labelId="message"
                              color="secondary"
                              {...getFieldProps("idMessage_OS")}
                              error={Boolean(
                                touched.idMessage_OS && errors.idMessage_OS
                              )}
                            >
                              {/* {
                                (() => {
                                  console.log(props)
                                  return null
                                })()
                              } */}
                              {props?.data?.message?.map?.((item: any) => {
                                if (item.type === "Retrabalho") {
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={9}>
                          <FormControl fullWidth>
                            <InputLabel color="secondary" id="rework">
                              Retrabalho*
                            </InputLabel>
                            <Select
                              fullWidth
                              label="Retrabalho*"
                              labelId="rework"
                              color="secondary"
                              {...getFieldProps("idRework_OR")}
                              error={Boolean(
                                touched.idRework_OR && errors.idRework_OR
                              )}
                            >
                              {props.data?.rework?.map((item: any) => {
                                return (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            fullWidth
                            type="number"
                            color="secondary"
                            variant="outlined"
                            label="Quantidade*"
                            {...getFieldProps("lensesAmount_OS")}
                            helperText={
                              touched.lensesAmount_OS && errors.lensesAmount_OS
                            }
                            error={Boolean(
                              touched.lensesAmount_OS && errors.lensesAmount_OS
                            )}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === 3}
                    onChange={handleChange(3)}
                    sx={{
                      border: "1px solid #c9c9c9",
                      borderRadius: "0 !important",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<Icon icon={arrowDown} color="#FFF" />}
                      sx={{ bgcolor: "#004A78", color: "#FFF" }}
                    >
                      <Typography variant="h5">Perda</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: "24px" }}>
                      <Grid container columnSpacing={3} rowSpacing={3}>
                        <Grid item xs={7}>
                          <FormControl fullWidth>
                            <InputLabel color="secondary" id="message">
                              Mensagem*
                            </InputLabel>
                            <Select
                              fullWidth
                              label="Mensagem*"
                              labelId="message"
                              color="secondary"
                              {...getFieldProps("idMessage_OS")}
                              error={Boolean(
                                touched.idMessage_OS && errors.idMessage_OS
                              )}
                            >
                              {props?.data?.message?.map?.((item: any) => {
                                if (item.type === "Perda") {
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            type="number"
                            color="secondary"
                            variant="outlined"
                            label="Quantidade*"
                            {...getFieldProps("lensesAmount_OS")}
                            helperText={
                              touched.lensesAmount_OS && errors.lensesAmount_OS
                            }
                            error={Boolean(
                              touched.lensesAmount_OS && errors.lensesAmount_OS
                            )}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === 7}
                    onChange={handleChange(7)}
                    sx={{
                      border: "1px solid #c9c9c9",
                      borderRadius: "0 !important",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<Icon icon={arrowDown} color="#FFF" />}
                      sx={{ bgcolor: "#004A78", color: "#FFF" }}
                    >
                      <Typography variant="h5">O.S Cancelada</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        paddingTop: "24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          margin: 0,
                          display: "flex",
                          justifyContent: "center",
                        }}
                        control={
                          <Checkbox
                            color="secondary"
                            disableTouchRipple
                            checked={orderDown}
                            onChange={() => setOrderDown(!orderDown)}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={
                          <Typography
                            variant="body1"
                            lineHeight={1.2}
                            sx={{
                              color: "#707070",
                              fontSize: "20px",
                              width: "60%",
                            }}
                          >
                            Confirmo a solicitação de cancelamento por parte do
                            cliente
                          </Typography>
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === 6}
                    onChange={handleChange(6)}
                    sx={{
                      border: "1px solid #c9c9c9",
                      borderRadius: "0 !important",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<Icon icon={arrowDown} color="#FFF" />}
                      sx={{ bgcolor: "#004A78", color: "#FFF" }}
                    >
                      <Typography variant="h5">
                        Reprovada no Recebimento
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: "24px" }}>
                      <Grid container columnSpacing={3} rowSpacing={3}>
                        <Grid item xs={7}>
                          <FormControl fullWidth>
                            <InputLabel color="secondary" id="message">
                              Mensagem*
                            </InputLabel>
                            <Select
                              fullWidth
                              label="Mensagem*"
                              labelId="message"
                              color="secondary"
                              {...getFieldProps("idMessage_OS")}
                              error={Boolean(
                                touched.idMessage_OS && errors.idMessage_OS
                              )}
                            >
                              {props?.data?.message?.map?.((item: any) => {
                                if (item.type === "Reprovada") {
                                  return (
                                    <MenuItem key={item.id} value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            type="number"
                            color="secondary"
                            variant="outlined"
                            label="Quantidade*"
                            {...getFieldProps("lensesAmount_OS")}
                            helperText={
                              touched.lensesAmount_OS && errors.lensesAmount_OS
                            }
                            error={Boolean(
                              touched.lensesAmount_OS && errors.lensesAmount_OS
                            )}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === 8}
                    onChange={handleChange(8)}
                    sx={{
                      border: "1px solid #c9c9c9",
                      borderRadius: "0 !important",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<Icon icon={arrowDown} color="#FFF" />}
                      sx={{ bgcolor: "#004A78", color: "#FFF" }}
                    >
                      <Typography variant="h5">Continuar Processo</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: "24px" }}>
                      <Grid container columnSpacing={3} rowSpacing={3}>
                        <Grid item xs={7}>
                          <TextField
                            fullWidth
                            type="text"
                            label="Nome*"
                            color="secondary"
                            variant="outlined"
                            {...getFieldProps("nameContinueProcess_OS")}
                            helperText={
                              touched.nameContinueProcess_OS &&
                              errors.nameContinueProcess_OS
                            }
                            error={Boolean(
                              touched.nameContinueProcess_OS &&
                                errors.nameContinueProcess_OS
                            )}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            type="number"
                            color="secondary"
                            variant="outlined"
                            label="Quantidade*"
                            {...getFieldProps("lensesAmount_OS")}
                            helperText={
                              touched.lensesAmount_OS && errors.lensesAmount_OS
                            }
                            error={Boolean(
                              touched.lensesAmount_OS && errors.lensesAmount_OS
                            )}
                          />
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === 1}
                    onChange={handleChange(1)}
                    sx={{
                      border: "1px solid #c9c9c9",
                      borderRadius: "0 0 4px 4px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<Icon icon={arrowDown} color="#FFF" />}
                      sx={{
                        bgcolor: "#004A78",
                        color: "#FFF",
                        borderRadius: "0 0 4px 4px",
                      }}
                    >
                      <Typography variant="h5">Baixa</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        paddingTop: "24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          margin: 0,
                          display: "flex",
                          justifyContent: "center",
                        }}
                        control={
                          <Checkbox
                            color="secondary"
                            disableTouchRipple
                            checked={orderDown}
                            onChange={() => setOrderDown(!orderDown)}
                            inputProps={{ "aria-label": "primary checkbox" }}
                          />
                        }
                        label={
                          <Typography
                            variant="body1"
                            lineHeight={1.2}
                            sx={{ color: "#707070", fontSize: "20px" }}
                          >
                            Confirmo a baixa na ordem de serviço
                          </Typography>
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                </CardContent>
                <CardActions
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <LoadingButton
                    onClick={saveAction}
                    loading={isSubmitting}
                    disabled={disabled}
                    variant="contained"
                    sx={{
                      mr: 2,
                      mb: 2,
                      height: "48px",
                      paddingX: "32px",
                      borderRadius: "30px",
                      backgroundColor: "#CDCDCD",
                      color: "#004A78",
                      "&:hover": {
                        bgcolor: "#004A78",
                        color: "#FEFEFE",
                      },
                    }}
                    autoFocus
                  >
                    Confirmar
                  </LoadingButton>
                </CardActions>
              </Form>
            </FormikProvider>
          </Card>
        </Container>
      </Modal>
    </>
  );
}
