import http from "../../../services/http-common";

class StageService {


  // eslint-disable-next-line class-methods-use-this
  getAll() {
    return http.get(`stage/find/`);
  }
  

}

export default new StageService();
