import http from "../../../services/http-common";

class ActionService {
  // eslint-disable-next-line class-methods-use-this
  async saveAction(data: any) {
    try {
      let action: any = {
        code_Order: data.code_Order,
        idStage_OS: data.idStage_OS,
        lensesAmount_OS: data.lensesAmount_OS === "" ? 2 : Number.parseInt(data.lensesAmount_OS),
      };

      if (data.idStage_OS === 4) {
        action["idRework_OR"] = data.idRework_OR;
        action["idMessage_OS"] = data.idMessage_OS;
      } else if (data.idStage_OS === 3 || data.idStage_OS === 6) action["idMessage_OS"] = data.idMessage_OS;
      else if (data.idStage_OS === 8) action["nameContinueProcess_OS"] = data.nameContinueProcess_OS;

      return http.post("/order/action", action);
    } catch (error) {
      console.log(error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getAll(code: string) {
    return http.get(`order/actions/${code}`);
  }
  

  getRework() {
    return http.get(`/rework/find`);
  }

  getMessage() {
    return http.get(`/message/find`);
  }
}

export default new ActionService();
