import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Stack,
  TextField,
  CardActions,
  CardContent,
  Typography,
  Modal,
  Card,
} from "@mui/material";

import financialService from "../services/financialService";

interface ModalProps {
  title: string;
  open: boolean;
  handler: () => void;
  setError: () => void;
  setSuccess: () => void;
  confirmButtonText: string;
}

export default function FinancialModal(props: ModalProps) {
  const [isSubmitting, setSubmitting] = useState(false);

  const RegisterSchema = Yup.object().shape({
    initialSP: Yup.number().required("O número inicial é obrigatório"),
    initialPA: Yup.number().required("O número inicial é obrigatório"),
    initialNE: Yup.number().required("O número inicial é obrigatório"),
  });
  var formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      initialSP: "",
      initialPA: "",
      initialNE: "",
    },
    onSubmit: () => {
      setSubmitting(true);
      try {
        financialService.billingClose(values);
        props.setSuccess();
      } catch (error) {
        props.setError();
      }

      resetForm();
      setSubmitting(false);
      props.handler();
    },
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: RegisterSchema,
  };

  const formik = useFormik(formikValues);

  const { errors, touched, values, handleSubmit, getFieldProps, resetForm } =
    formik;

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          resetForm();
          props.handler();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ minWidth: "30vw" }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <CardContent>
                <Typography
                  variant="h4"
                  marginBottom={4}
                  color="#004A78"
                  gutterBottom
                >
                  Gerar Fechamento
                </Typography>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    Número Inicial - SP
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth={true}
                    color="secondary"
                    variant="outlined"
                    {...getFieldProps("initialSP")}
                    placeholder="Digite aqui o número inicial..."
                    helperText={touched.initialSP && errors.initialSP}
                    error={Boolean(touched.initialSP && errors.initialSP)}
                  />
                </Stack>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    Número Inicial - PA
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth={true}
                    color="secondary"
                    variant="outlined"
                    placeholder="Digite aqui o número inicial..."
                    {...getFieldProps("initialPA")}
                    error={Boolean(touched.initialPA && errors.initialPA)}
                    helperText={touched.initialPA && errors.initialPA}
                  />
                </Stack>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    Número Inicial - NE
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth={true}
                    color="secondary"
                    variant="outlined"
                    placeholder="Digite aqui o número inicial..."
                    {...getFieldProps("initialNE")}
                    error={Boolean(touched.initialNE && errors.initialNE)}
                    helperText={touched.initialNE && errors.initialNE}
                  />
                </Stack>
              </CardContent>
              <CardActions>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!formik.isValid}
                  sx={{
                    m: 1,
                    height: "48px",
                    borderRadius: "30px",
                    backgroundColor: "#CDCDCD",
                    color: "#004A78",
                    "&:hover": {
                      bgcolor: "#004A78",
                      color: "#FEFEFE",
                    },
                  }}
                  autoFocus
                >
                  {props.confirmButtonText}
                </LoadingButton>
              </CardActions>
            </Form>
          </FormikProvider>
        </Card>
      </Modal>
    </>
  );
}
