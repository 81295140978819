//import { motion } from 'framer-motion';
// import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from "@mui/system";
import { Typography, Container, Stack, Box } from "@mui/material";
// components
//import { MotionContainer, varBounceIn } from '../components/animate';
import Page from "../components/Page";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  background: "#004A78",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Maintenance() {
  const date = "14/12";
  const hour = "20h";
  return (
    <RootStyle title="Manutenção | Optools">
      <Container sx={{ background: "#004A78" }}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="h2" color="white">
            Estamos em manutenção
          </Typography>
          <Typography variant="h4" color="white">
            Previsão para voltar: {date} às {hour}
          </Typography>
          <Typography color="white">Agradecemos a sua compreensão</Typography>
          <Box
            sx={{
              height: "400px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img
              src="/static/illustrations/undraw_maintenance.svg"
              alt="maintenance"
              width="300"
              height="300"
            />
          </Box>
        </Stack>
      </Container>
    </RootStyle>
  );
}
