/* eslint-disable camelcase */
import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import editFill from "@iconify/icons-eva/edit-fill";
import lockFill from "@iconify/icons-eva/lock-fill";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import SearchNotFound from "../../../../components/SearchNotFound";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Grid,
  Card,
  Stack,
  Avatar,
  Button,
  Container,
  Typography,
  CardActions,
  CardContent,
  IconButton,
} from "@mui/material";
// components
import Page from "../../../../components/Page";
import ClientsListToolbar from "../components/ClientsListToolbar";

import sellerService from "../services/sellerService";
import AlertMessage from "../../../../components/alert/alert";
import ClientDialog from "../components/ClientDialog";
import PasswordModalPage from "../components/PasswordModal";

function descendingComparator(a: any, b: any, orderBy: any) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order: any, orderBy: any) {
  return order === "desc"
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array: any, comparator: any, query: any) {
  const stabilizedThis = array.map((el: any, index: any) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name_User.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el: any) => el[0]);
}

export default function Sellers() {
  const [page, setPage] = useState(0);
  const [data, setData]: any = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentUserPhone, setCurrentUserPhone] = useState(20);
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [idUser, setIdUser] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [urlPass, setUrlPass] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setDefault();

    sellerService
      .getAll()
      .then((data) => {
        setData(data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  const setDefault = () => {
    setPage(0);
    setOrder("asc");
    setOrderBy("name");
    setRowsPerPage(10);
  };

  function editColab(id: any) {
    sellerService.getById(id).then((dataUser) => {
      navigate("/dashboard/colaboradores/cadastrar", {
        state: { ...dataUser.data, id_User: id },
      });
    });
  }

  const handleFilterByName = (event: any) => {
    setFilterName(event.target.value);
  };

  function removeColab(id: any) {
    setIdUser(id);
    setIsDeleted(true);
  }

  const removeAlert = () => {
    setIsDeleted(false);
    sellerService
      .removeColab(idUser)
      .then(() => {
        setSuccess(true);
        sellerService
          .getAll()
          .then((data) => {
            setData(data.data);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((err) => {
        setError(true);
        console.error(err);
      });
  };

  const filteredUsers: any = applySortFilter(
    data,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const passwordRecovery = (userId: any) => {
    sellerService
      .getById(userId.toString())
      .then((userData: any) => {
        setCurrentUserPhone(userData.data.phone_User);
        sellerService
          .passwordRecover(userData.data.email_User)
          .then((res: any) => {
            setUrlPass(res.data.url);
            setModalOpen(true);
          })
          .catch((err: any) => {
            console.error(err);
          });
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  return (
    <Page title="Colaboradores | Optools">
      <PasswordModalPage
        open={modalOpen}
        url={urlPass}
        title="Número de páginas"
        confirmButtonText="Copiar"
        whatsapp={currentUserPhone}
        handler={() => setModalOpen(false)}
      />
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            Colaboradores
          </Typography>
        </Stack>

        <Stack direction={["column", "row"]} justifyContent="space-between">
          <ClientsListToolbar
            prepaid={undefined}
            customerType={false}
            onPrepaidChange={() => {}}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Button
            sx={{
              height: "48px",
              borderRadius: "40px",
              marginTop: [0, "18px"],
              background: "#004A78",
              marginBottom: ["24px", 0],
              "&:hover": {
                backgroundColor: "#226C9A",
              },
            }}
            color="secondary"
            variant="contained"
            component={RouterLink}
            startIcon={<Icon icon={plusFill} />}
            to="/dashboard/colaboradores/cadastrar"
          >
            Cadastrar novo
          </Button>
        </Stack>

        <Grid container spacing={4}>
          {data.length ? (
            filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: any) => {
                const {
                  id_User,
                  name_User,
                  role,
                  name_Company,
                  pictureFilepath_User,
                  avatarUrl,
                } = row;

                return (
                  <Grid key={id_User} item xs={12} sm={6} md={4} lg={3}>
                    <Card
                      sx={{ justifyContent: "center", alignItems: "center" }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          paddingBottom: "5px",
                        }}
                      >
                        {pictureFilepath_User !== "" ? (
                          <Avatar
                            alt={name_User}
                            src={pictureFilepath_User}
                            sx={{ width: "128px", height: "128px" }}
                          />
                        ) : (
                          <Avatar
                            alt={name_User}
                            src={avatarUrl}
                            sx={{ width: "128px", height: "128px" }}
                          />
                        )}

                        <Typography
                          mt={1}
                          variant="h4"
                          noWrap
                          sx={{ color: "#303972" }}
                        >
                          {name_User}
                        </Typography>
                        <Typography
                          variant="body1"
                          noWrap
                          sx={{ color: "#A098AE" }}
                        >
                          {role}
                        </Typography>
                        <Typography
                          variant="body1"
                          noWrap
                          sx={{ color: "#A098AE" }}
                        >
                          {name_Company}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "12px",
                        }}
                      >
                        <IconButton
                          color="primary"
                          onClick={() => {
                            editColab(id_User);
                          }}
                          sx={{
                            marginRight: "4px",
                            background: "#004A78",
                            color: "#FFF",
                            "&:hover": {
                              bgcolor: "#115B89",
                            },
                          }}
                        >
                          <Icon icon={editFill} />
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            removeColab(id_User);
                          }}
                          sx={{
                            marginRight: "4px",
                            background: "#004A78",
                            color: "#FFF",
                            "&:hover": {
                              bgcolor: "#115B89",
                            },
                          }}
                        >
                          <Icon icon={trash2Outline} />
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            passwordRecovery(id_User);
                          }}
                          sx={{
                            marginRight: "4px",
                            background: "#004A78",
                            color: "#FFF",
                            "&:hover": {
                              bgcolor: "#115B89",
                            },
                          }}
                        >
                          <Icon icon={lockFill} />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })
          ) : (
            <Stack
              mt={12}
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Icon icon={inboxOutline} width="56px" color="#909090" />
              <Typography
                mt={1}
                variant="body1"
                noWrap
                sx={{ color: "#909090" }}
              >
                Nenhum item encontrado...
              </Typography>
            </Stack>
          )}
        </Grid>
        {isUserNotFound && data.length ? (
          <Stack
            mt={5}
            py={4}
            sx={{ backgroundColor: "#FFF", borderRadius: 1 }}
          >
            <SearchNotFound searchQuery={filterName} />
          </Stack>
        ) : undefined}
        {isDeleted && (
          <ClientDialog
            open={true}
            id={idUser}
            title="Remover colaborador"
            description="Tem certeza que deseja remover este colaborador?"
            cancelButtonText="Cancelar"
            confirmButtonText="Remover"
            action={removeAlert}
            handler={() => setIsDeleted(false)}
          />
        )}
        {showSuccess && (
          <AlertMessage open={true} message="Sucesso ao remover colaborador!" />
        )}
        {showError && (
          <AlertMessage open={true} message="Erro ao efetuar remoção" />
        )}
      </Container>
    </Page>
  );
}
