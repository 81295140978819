/* eslint-disable camelcase */
import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink } from "react-router-dom";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
import AlertMessage from "../../../../components/alert/alert";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../../../../components/Page";
import GroupOptions from "../components/GroupOptions";
import GroupListHead from "../components/GroupListHead";
import Scrollbar from "../../../../components/Scrollbar";
import GroupListToolbar from "../components/GroupListToolbar";

import GroupModal from "../components/GroupModal";
import groupService from "../services/groupService";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "name_Team", label: "Grupo", alignRight: false },
  { id: "id_Team", label: "Código", alignRight: false },
  { id: "", label: "Editar" },
];

// ----------------------------------------------------------------------

export default function Groups() {
  const [page, setPage] = useState(0);
  const [data, setData]: any = useState([]);
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [updateData, setUpdateData] = useState(false);
  const [params, setParams]: any = useState({ value: "" });
  const [notFirstQuery, setNotFirstQuery] = useState(false);
  const [queryStartEnd, setQueryStartEnd] = useState([
    "0",
    rowsPerPage.toString(),
  ]);

  useEffect(() => {
    groupService
      .getAll(queryStartEnd[0], queryStartEnd[1], params)
      .then((data: any) => setData(data.data))
      .catch((error: any) => console.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData, queryStartEnd]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        notFirstQuery
          ? groupService
              .getAll(queryStartEnd[0], queryStartEnd[1], params)
              .then((data: any) => setData(data.data))
              .catch((error: any) => console.error(error))
          : setNotFirstQuery(true),
      500
    );

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.value]);

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  const handleChangePage = (event: any, newPage: any) => {
    const finalPage = newPage > 0 ? page + 1 : page - 1;
    setQueryStartEnd([
      ((finalPage + 1) * rowsPerPage - rowsPerPage).toString(),
      rowsPerPage.toString(),
    ]);
    setPage(finalPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const finalRowsPerPage = parseInt(event.target.value);
    setQueryStartEnd([
      ((page + 1) * finalRowsPerPage - finalRowsPerPage).toString(),
      finalRowsPerPage.toString(),
    ]);
    setRowsPerPage(finalRowsPerPage);
    setPage(0);
  };

  return (
    <Page title="Grupos | Optools">
      <Container>
        <GroupModal
          idClient={0} // TODO: Check this
          open={modalOpen}
          title="Número de páginas"
          confirmButtonText="Concluir"
          setError={() => setError(true)}
          handler={() => setModalOpen(false)}
          setSuccess={() => setSuccess(true)}
        />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h4" gutterBottom>
            Grupos
          </Typography>
        </Stack>

        <Stack direction={["column", "row"]} justifyContent="space-between">
          <GroupListToolbar
            filterName={params.value}
            onFilterName={(event: any) => {
              setPage(0);
              setQueryStartEnd(["0", rowsPerPage.toString()]);
              setParams({ ...params, value: event.target.value });
            }}
          />

          <Button
            sx={{
              height: "48px",
              borderRadius: "40px",
              background: "#004A78",
              marginTop: ["8px", "24px"],
              marginBottom: ["16px", 0],
              "&:hover": {
                bgcolor: "#226C9A",
              },
            }}
            variant="contained"
            component={RouterLink}
            to="/dashboard/grupos/cadastrar"
            color="secondary"
            startIcon={<Icon icon={plusFill} />}
          >
            Cadastrar novo
          </Button>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <GroupListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {data.length ? (
                    data.slice(0, rowsPerPage).map((row: any) => {
                      const { id_Team, name_Team } = row;
                      return (
                        <TableRow
                          hover
                          key={id_Team}
                          tabIndex={-1}
                          role="checkbox"
                        >
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ color: "#004A78", fontSize: "14px" }}
                            >
                              {name_Team}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{ color: "#004A78", fontSize: "14px" }}
                            >
                              {id_Team}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <GroupOptions
                              id={id_Team}
                              updateData={updateData}
                              setError={() => setError(true)}
                              setSuccess={() => setSuccess(true)}
                              setUpdateData={(res: any) => setUpdateData(res)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Stack
                          width="100%"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Icon
                            icon={inboxOutline}
                            width="56px"
                            color="#909090"
                          />
                          <Typography
                            mt={1}
                            variant="body1"
                            noWrap
                            sx={{ color: "#909090" }}
                          >
                            Nenhum item encontrado...
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            page={0}
            component="div"
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[20, 40, 60]}
            backIconButtonProps={{ disabled: !page }}
            labelRowsPerPage="Linhas por página:"
            nextIconButtonProps={{
              disabled:
                (page + 1) * rowsPerPage >= data[0]?.total_Team ||
                data.length < rowsPerPage,
            }}
            labelDisplayedRows={(rowsInfo) => {
              const totalPages = Math.ceil(
                rowsInfo.count / rowsPerPage
              ).toString();
              return (
                <>
                  {`Página: ${page + 1}${
                    !params.value.length
                      ? `/${totalPages === "0" ? "1" : totalPages}`
                      : ""
                  }`}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {!params.value.length ? `Total: ${rowsInfo.count}` : ""}
                </>
              );
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={data?.length ? data[0].total_Team : 0}
          />
        </Card>
      </Container>
      {showSuccess && (
        <AlertMessage open={true} message="Sucesso ao remover grupo!" />
      )}
      {showError && (
        <AlertMessage open={true} message="Erro ao remover grupo" />
      )}
    </Page>
  );
}
