import http from "../../../../services/http-common";

class GroupService {
  // eslint-disable-next-line class-methods-use-this
  async saveGroup(data: any) {
    try {
      const group = { ...data, name_Team: data.name_Team.toUpperCase() };
      delete group.priceAdd;
      for (let i = 0; i < group.price.length; i++) {
        delete group.price[i].nameLens;
      }
      return http.post("/team", group);
    } catch (error) {
      console.log(error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async updateGroup(id_Team: string, data: any) {
    try {
      const group = { ...data, name_Team: data.name_Team.toUpperCase() };
      delete group.priceAdd;
      for (let i = 0; i < group.price.length; i++) {
        delete group.price[i].nameLens;
        delete group.price[i].date;
      }
      return http.put(`/team/update/${id_Team}`, group);
    } catch (error) {
      console.log(error);
    }
  }

  getById(id: string) {
    return http.get(`team/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  getAll(offset: string, limit: string, params: any): any {
    return http.get(`team/find/${offset}/${limit}`, {
      params: params,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getLens() {
    return http.get("/lens/find");
  }

  async removeGroup(idTeam: any) {
    return http.delete(`team/delete/${idTeam}`);
  }

  buildQr(idClient: any, numPage: any) {
    return http.get(`order/code/generator/${idClient}/${numPage}/`);
  }
}

export default new GroupService();
