import http from "../../../../services/http-common";
import firebase from "firebase/app";
import "firebase/storage";

class SellerService {
  // eslint-disable-next-line class-methods-use-this
  async saveCollaborator(data: any) {
    let urlDownload = "";
    try {
      if (data.file) {
        const storage = firebase.storage();

        const filePath = `colaboradores/${data.name_User} - ${data.cpf_User}`;

        const storageRef = await storage.ref(filePath);
        await storageRef.put(data.file);

        urlDownload = await storageRef.getDownloadURL();
      } else {
        urlDownload = data.pictureFilepath_Customer;
      }

      const collaborator = {
        name_User: data.name_User.toUpperCase(),
        phone_User: data.phone_User.replace(/[^0-9]/g, ""),
        email_User: data.email_User.toUpperCase(),
        pictureFilename_User: `${data.name_User} - ${data.cpf_User}`,
        pictureFilepath_User: urlDownload,
        idCompany_User: Number.parseInt(data.idCompany_User),
        idRole_User: Number.parseInt(data.idRole_User),
        cpf_User: data.cpf_User
          .replace(".", "")
          .replace(".", "")
          .replace("-", ""),
      };

      return http.post("/user", collaborator);
    } catch (error) {
      console.log(error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async updateCollaborator(idUser: string, data: any) {
    let urlDownload = "";
    try {
      if (data.file) {
        const storage = firebase.storage();
        const filePath = `colaboradores/${data.name_User} - ${data.cpf_User}`;

        const storageRef = await storage.ref(filePath);
        await storageRef.put(data.file);

        urlDownload = await storageRef.getDownloadURL();
      } else {
        urlDownload = data.pictureFilepath_Customer;
      }

      const collaborator = {
        name_User: data.name_User.toUpperCase(),
        phone_User: data.phone_User
          .replace("(", "")
          .replace(")", "")
          .replace("-", ""),
        email_User: data.email_User.toUpperCase(),
        pictureFilename_User: `${data.name_User} - ${data.cpf_User}`,
        pictureFilepath_User: urlDownload,
        idCompany_User: Number.parseInt(data.idCompany_User),
        idRole_User: Number.parseInt(data.idRole_User),
        cpf_User: data.cpf_User
          .replace(".", "")
          .replace(".", "")
          .replace("-", ""),
      };

      return http.put(`/user/update/${idUser}`, collaborator);
    } catch (error) {
      console.log(error);
    }
  }

  removeColab(idClient: any) {
    return http.delete(`user/delete/${idClient}`);
  }

  // eslint-disable-next-line class-methods-use-this
  getById(id: string) {
    return http.get(`user/profile/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  getAll() {
    return http.get(`user/find`);
  }

  // eslint-disable-next-line class-methods-use-this
  getLens() {
    return http.get("lens/find");
  }

  passwordRecover(email: any) {
    return http.patch(`/user/forgot/password`, { email: email });
  }
}

export default new SellerService();
