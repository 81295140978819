import { Stack, Container, Typography } from "@mui/material";
import { styled } from "@mui/system";

import Page from "../../../../components/Page";
import { MHidden } from "../../../../components/@material-extend";
import RequestPasswordForm from "../components/RequestPasswordForm";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 700,
  display: "flex",
  textAlign: "center",
  background: "#004A78",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(0, 0, 0, 0),
  img: {
    marginTop: "0%",
    display: "flex",
    maxWidth: "100%",
    marginLeft: "35%",
    textAlign: "center",
    justifyContent: "center",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 350,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  background: "#f3f4ff",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(0, 0),
}));

// ----------------------------------------------------------------------

export default function RequestPassword() {
  return (
    <RootStyle title="Esqueceu a senha | Optools">
      <MHidden width="mdDown">
        <SectionStyle>
          <img
            src="/static/logowhite.svg"
            alt="login"
            width="200"
            height="100"
          />
          <Typography
            variant="h4"
            sx={{ px: 5, mt: 20, mb: 30, color: "#ffffff" }}
          >
            Bem Vindo de volta!
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#ffffff", fontWeight: "bold" }}
          >
            Optools - Sistema Operacional
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: "#ffffff", fontWeight: "normal", fontSize: "0.9rem" }}
          >
            Desenvolvido por Braint Tech
          </Typography>
        </SectionStyle>
      </MHidden>

      <Container sx={{ background: "#f3f4ff", width: "100%" }}>
        <ContentStyle>
          <Stack
            sx={{
              mb: 5,
              justifyContent: "center",
              textAlign: "center",
              fontSize: "2.0rem",
            }}
          >
            <Typography variant="h3" sx={{ color: "#004A78" }} gutterBottom>
              Esqueceu sua senha?
            </Typography>
          </Stack>

          <RequestPasswordForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
