import { Icon } from "@iconify/react";
import GroupDialog from "./GroupDialog";
import { useNavigate } from "react-router-dom";
import editFill from "@iconify/icons-eva/edit-fill";
import { useState, useEffect, useRef } from "react";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreHorizontalFill from "@iconify/icons-eva/more-horizontal-fill";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import groupService from "../services/groupService";

// ----------------------------------------------------------------------

export default function GroupOptions(props: any) {
  const navigate = useNavigate();

  const ref = useRef(null);
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    groupService
      .getById(props.id)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [props.id]);

  const alertRemove = () => {
    setIsDeleted(true);
    setIsOpen(false);
  };

  const groupRemove = () => {
    setIsDeleted(false);
    groupService
      .removeGroup(props.id)
      .then((res) => {
        props.setSuccess();
        props.setUpdateData(!props.updateData);
      })
      .catch((err) => {
        props.setError();
        console.error(err);
      });
  };

  const edit = () => {
    navigate("/dashboard/grupos/cadastrar", {
      state: { ...data, id_Team: props.id },
    });
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreHorizontalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={edit} sx={{ color: "text.secondary" }}>
          <ListItemIcon>
            <Icon icon={editFill} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Editar"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem sx={{ color: "text.secondary" }} onClick={alertRemove}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Deletar"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>

      <GroupDialog
        id={props.id}
        open={isDeleted}
        title="Remover Grupo"
        cancelButtonText="Cancelar"
        confirmButtonText="Remover"
        action={() => groupRemove()}
        handler={() => setIsDeleted(false)}
        description="Tem certeza que deseja remover este grupo?"
      />
    </>
  );
}
