import * as Yup from "yup";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import adminService from "../services/administrationService";
import AlertMessage from "../../../../components/alert/alert";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Stack,
  TextField,
  CardActions,
  CardContent,
  Typography,
  Modal,
  Card,
} from "@mui/material";
import InputMask from "react-input-mask";
// import clientService from '../services/clientService';

interface ModalProps {
  open: boolean;
  handler: () => void;
  id: any;
}

export default function CompanyModal(props: ModalProps) {
  const [showError, setError] = useState(false);
  const [onUpdate, setOnUpdate] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  var formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      name_Company: "",
      initials_Company: "",
      phone_Company: "",
      whatsapp_Company: "",
    },
    onSubmit: () => {
      setSubmitting(true);

      adminService
        .editCompany(props.id, values)
        .then((res: any) => {
          console.log(res);
          setSubmitting(false);
          setSuccess(true);
          formik.resetForm();
          props.handler();
        })
        .catch((error: any) => {
          setSubmitting(false);
          setError(true);
        });
    },
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
  };

  const formik = useFormik(formikValues);

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  useEffect(() => {
    if (props.open) {
      adminService
        .getCompany(props.id)
        .then((res: any) => {
          if (res.data) {
            setOnUpdate(true);
            setFieldValue(
              "name_Company",
              res.data.name_Company ? res.data.name_Company : ""
            );
            setFieldValue(
              "initials_Company",
              res.data.initials_Company ? res.data.initials_Company : ""
            );
            setFieldValue(
              "phone_Company",
              res.data.phone_Company ? res.data.phone_Company : ""
            );
            setFieldValue(
              "whatsapp_Company",
              res.data.whatsapp_Company ? res.data.whatsapp_Company : ""
            );
          } else setOnUpdate(false);
        })
        .catch((error) => setOnUpdate(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          formik.resetForm();
          props.handler();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ minWidth: "30vw" }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <CardContent>
                <Typography
                  variant="h4"
                  marginBottom={4}
                  color="#004A78"
                  gutterBottom
                >
                  IPs das Unidades
                </Typography>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    Nome da empresa
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    color="secondary"
                    variant="outlined"
                    placeholder="NOME DA EMPRESA"
                    {...getFieldProps("name_Company")}
                  />
                </Stack>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    Iniciais da empresa
                  </Typography>
                  <TextField
                    fullWidth
                    type="text"
                    color="secondary"
                    variant="outlined"
                    placeholder="INICIAIS DA EMPRESA"
                    {...getFieldProps("initials_Company")}
                  />
                </Stack>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    Telefone
                  </Typography>
                  <InputMask
                    {...getFieldProps("phone_Company")}
                    mask="(99) 9999-9999"
                  >
                    {() => (
                      <TextField
                        fullWidth
                        type="text"
                        color="secondary"
                        variant="outlined"
                        placeholder="(99) 9999-9999"
                        {...getFieldProps("phone_Company")}
                      />
                    )}
                  </InputMask>
                </Stack>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    Whatsapp
                  </Typography>
                  <InputMask
                    {...getFieldProps("whatsapp_Company")}
                    mask="(99)9 9999-9999"
                  >
                    {() => (
                      <TextField
                        fullWidth
                        type="text"
                        color="secondary"
                        variant="outlined"
                        placeholder="(99)9 9999-9999"
                        {...getFieldProps("whatsapp_Company")}
                      />
                    )}
                  </InputMask>
                </Stack>
              </CardContent>
              <CardActions>
                <LoadingButton
                  fullWidth
                  type="submit"
                  loading={isSubmitting}
                  disabled={!formik.isValid}
                  variant="contained"
                  sx={{
                    m: 1,
                    height: "48px",
                    borderRadius: "30px",
                    backgroundColor: "#CDCDCD",
                    color: "#004A78",
                    "&:hover": {
                      bgcolor: "#004A78",
                      color: "#FEFEFE",
                    },
                  }}
                  autoFocus
                >
                  SALVAR
                </LoadingButton>
              </CardActions>
            </Form>
          </FormikProvider>
        </Card>
      </Modal>
      {showSuccess && (
        <AlertMessage
          open={true}
          message="Informações da empresa atualizados com sucesso!"
        />
      )}
      {showError && (
        <AlertMessage open={true} message="Erro ao efetuar cadastro" />
      )}
    </>
  );
}
