import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
// material
import { styled } from "@mui/system";
import {
  Box,
  Select,
  Toolbar,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  float: "left",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 0, 0, 0),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }: any) => ({
  height: 48,
  float: "left",
  borderRadius: "40px",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ClientsListToolbar.propTypes = {
  prepaid: PropTypes.bool,
  situation: PropTypes.bool,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  customerType: PropTypes.bool,
  onPrepaidChange: PropTypes.func,
  onSituationChange: PropTypes.func,
};

export default function ClientsListToolbar({
  prepaid,
  situation,
  filterName,
  onFilterName,
  customerType,
  onPrepaidChange,
  onSituationChange,
}: any) {
  return (
    <RootStyle sx={{ height: ["", 96], display: ["block", "flex"] }}>
      <SearchStyle
        sx={{
          width: ["100%", 350],
          marginLeft: ["0", "-24px"],
        }}
        value={filterName}
        onChange={onFilterName}
        placeholder="Pesquisar..."
        inputProps={{ style: { textTransform: "uppercase" } }}
        startAdornment={
          <InputAdornment position="start">
            <Box
              component={Icon}
              icon={searchFill}
              sx={{ color: "text.disabled" }}
            />
          </InputAdornment>
        }
      />
      {customerType ? (
        <>
          <FormControl
            sx={{
              width: ["100%", "200px"],
              marginLeft: ["0", "18px"],
              marginTop: ["18px", ""],
              marginBottom: ["0", "16px"],
            }}
          >
            <InputLabel id="type" color="secondary">
              Tipo de Cliente
            </InputLabel>
            <Select
              fullWidth
              labelId="type"
              color="secondary"
              label="Tipo de Cliente"
              sx={{ borderRadius: "20px", height: 48 }}
              value={prepaid !== undefined ? (prepaid ? 1 : 2) : 0}
              onChange={(event) => onPrepaidChange(event.target.value)}
            >
              <MenuItem value={0}>Todos</MenuItem>
              <MenuItem value={1}>Pré-pagos</MenuItem>
              <MenuItem value={2}>Pós-pagos</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{
              width: ["100%", "200px"],
              marginLeft: ["0", "18px"],
              marginTop: ["18px", ""],
              marginBottom: ["0", "16px"],
            }}
          >
            <InputLabel id="checked" color="secondary">
              Situação
            </InputLabel>
            <Select
              fullWidth
              label="Situação"
              labelId="checked"
              color="secondary"
              sx={{ borderRadius: "20px", height: 48 }}
              value={situation !== undefined ? (situation ? 1 : 2) : 0}
              onChange={(event) => onSituationChange(event.target.value)}
            >
              <MenuItem value={0}>Todos</MenuItem>
              <MenuItem value={1}>Verificado</MenuItem>
              <MenuItem value={2}>Não Verificado</MenuItem>
            </Select>
          </FormControl>
        </>
      ) : undefined}
    </RootStyle>
  );
}
