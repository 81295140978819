import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useData } from "../../../contexts/client";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Stack,
  TextField,
  CardActions,
  CardContent,
  Typography,
  Modal,
  Card,
} from "@mui/material";
import orderService from "../services/orderService";
import AlertMessage from "../../../components/alert/alert";

// import clientService from '../services/clientService';

interface ModalProps {
  title: string;
  open: boolean;
  handler: () => void;
  confirmButtonText: string;
}

export default function OrderModal(props: ModalProps) {
  const navigate = useNavigate();
  const { handleOrderData } = useData();
  const [showError, setError] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const RegisterSchema = Yup.object().shape({
    OSCode: Yup.string().required("O código da OS é obrigatorio"),
  });
  var formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      OSCode: "",
    },
    onSubmit: () => {
      setSubmitting(true);

      const OSclient: any = window.localStorage.getItem("user");
      console.log(
        JSON.parse(OSclient),
        values.OSCode.substr(0, 3).toUpperCase()
      );
      if (
        JSON.parse(OSclient).initials !==
        values.OSCode.substr(0, 3).toUpperCase()
      ) {
        setError(true);
        setSubmitting(false);
        formik.setFieldValue("OSCode", "");
        return;
      }
      orderService
        .validate(Number.parseInt(values.OSCode.split("-")[1]).toString())
        .then((res) => {
          if (res.data.available) {
            handleOrderData({
              code_Order: Number.parseInt(values.OSCode.split("-")[1]),
            });
            navigate("/dashboard/pedidos/cadastrar", { replace: true });
          } else {
            setError(true);
            setSubmitting(false);
            formik.setFieldValue("OSCode", "");
          }
        })
        .catch((error) => {
          setError(true);
          setSubmitting(false);
          formik.setFieldValue("OSCode", "");
          console.log(error);
        });
    },
    validateOnMount: true,
    validateOnChange: true,
    enableReinitialize: true,
    validationSchema: RegisterSchema,
  };

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
  }, [showError]);

  const formik = useFormik(formikValues);

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          formik.resetForm();
          props.handler();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ minWidth: "30vw" }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <CardContent>
                <Typography
                  variant="h4"
                  marginBottom={4}
                  color="#004A78"
                  gutterBottom
                >
                  {props.title}
                </Typography>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    Código da OS*
                  </Typography>
                  <TextField
                    fullWidth
                    autoFocus
                    type="text"
                    color="secondary"
                    variant="outlined"
                    placeholder="Obrigatório"
                    {...getFieldProps("OSCode")}
                    error={Boolean(touched.OSCode && errors.OSCode)}
                    helperText={touched.OSCode && errors.OSCode}
                    inputProps={{
                      maxLength: 9,
                      style: { textTransform: "uppercase" },
                    }}
                  />
                </Stack>
              </CardContent>
              <CardActions>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={!formik.isValid}
                  sx={{
                    m: 1,
                    height: "48px",
                    borderRadius: "30px",
                    backgroundColor: "#CDCDCD",
                    color: "#004A78",
                    "&:hover": {
                      bgcolor: "#004A78",
                      color: "#FEFEFE",
                    },
                  }}
                  autoFocus
                >
                  {props.confirmButtonText}
                </LoadingButton>
              </CardActions>
            </Form>
          </FormikProvider>
        </Card>
      </Modal>
      {showError && (
        <AlertMessage open={true} message="Código de OS não disponível" />
      )}
    </>
  );
}
