import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect, useRef } from "react";
import clientService from "../services/clientService";
import AlertMessage from "../../../../components/alert/alert";
import {
  useFormik,
  Form,
  FormikProvider,
  FormikConfig,
  FormikValues,
} from "formik";
import {
  Stack,
  TextField,
  CardActions,
  CardContent,
  Typography,
  Modal,
  Card,
} from "@mui/material";

// import clientService from '../services/clientService';

interface ModalProps {
  title: string;
  open: boolean;
  idClient: any;
  handler: () => void;
  confirmButtonText: string;
}

export default function ClientModal(props: ModalProps) {
  const isMounted = useRef(true);
  const [showError, setError] = useState(false);
  const [showSuccess, setSuccess] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [numPagesData, setNumPagesData] = useState(0);

  const RegisterSchema = Yup.object().shape({
    numPage: Yup.number().required("O número de paginas é obrigatório"),
  });
  var formikValues: FormikConfig<FormikValues> = {
    initialValues: {
      numPage: 1,
    },
    onSubmit: () => {},
    validateOnMount: true,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: RegisterSchema,
  };

  const formik = useFormik(formikValues);

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  useEffect(() => {
    if (showError) setTimeout(() => setError(false), 2000);
    if (showSuccess) setTimeout(() => setSuccess(false), 2000);
  }, [showError, showSuccess]);

  useEffect(() => {
    if (formik.isValid && isMounted.current) {
      setNumPagesData(values.numPage);
    }
  }, [formik.isValid, values]);

  const buildQrcode = () => {
    setSubmitting(true);
    clientService
      .buildQr(props.idClient, numPagesData)
      .then((res: any) => {
        const url = res.data.filepath;
        window.open(url, "_blank");
        setSubmitting(false);
        setSuccess(true);
        props.handler();
      })
      .catch((error: any) => {
        setSubmitting(false);
        setError(true);
      });
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          formik.resetForm();
          props.handler();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card sx={{ minWidth: "30vw" }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <CardContent>
                <Typography
                  variant="h4"
                  marginBottom={4}
                  color="#004A78"
                  gutterBottom
                >
                  Número de páginas
                </Typography>
                <Stack spacing={1} marginTop={3}>
                  <Typography variant="h6" component="h2">
                    Coloque o número de páginas que você quer gerar
                  </Typography>
                  <TextField
                    type="number"
                    fullWidth={true}
                    color="secondary"
                    variant="outlined"
                    placeholder="Digite aqui o número de páginas..."
                    {...getFieldProps("numPage")}
                    error={Boolean(touched.numPage && errors.numPage)}
                    helperText={touched.numPage && errors.numPage}
                  />
                </Stack>
              </CardContent>
              <CardActions>
                <LoadingButton
                  fullWidth
                  onClick={buildQrcode}
                  loading={isSubmitting}
                  disabled={!formik.isValid}
                  variant="contained"
                  sx={{
                    m: 1,
                    height: "48px",
                    borderRadius: "30px",
                    backgroundColor: "#CDCDCD",
                    color: "#004A78",
                    "&:hover": {
                      bgcolor: "#004A78",
                      color: "#FEFEFE",
                    },
                  }}
                  autoFocus
                >
                  {props.confirmButtonText}
                </LoadingButton>
              </CardActions>
            </Form>
          </FormikProvider>
        </Card>
      </Modal>
      {showSuccess && (
        <AlertMessage open={true} message="Produto cadastrado com sucesso!" />
      )}
      {showError && (
        <AlertMessage open={true} message="Erro ao efetuar cadastro" />
      )}
    </>
  );
}
