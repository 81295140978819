import React, { createContext, useState, useContext } from "react";

interface SignedContextData {
  clientDataFis: any;
  clientDataJur: any;
  groupData: any;
  productData: any;
  profileData: any;
  colabData: any;
  orderData: any;
  handleClientDataFis(data: any, file: any): void;
  handleClientDataJur(data: any, file: any): void;
  handleProductData(data: any, file: any): void;
  handleProfileData(data: any, file: any): void;
  handleColabData(data: any, file: any): void;
  handleOrderData(data: any): void;
  handleGroupData(data: any): void;
}

const SignedContext = createContext<SignedContextData>({} as SignedContextData);

export const SignedProvider: React.FC = ({ children }) => {
  const [clientDataFis, setClientDataFis]: any = useState({});
  const [clientDataJur, setClientDataJur]: any = useState({});
  const [productData, setProductData]: any = useState({});
  const [profileData, setProfileData]: any = useState({});
  const [groupData, setGroupData]: any = useState({});
  const [colabData, setColabData]: any = useState({});
  const [orderData, setOrderData]: any = useState({});

  function handleClientDataFis(data: any, file: any) {
    setClientDataFis({ ...data, file: file });
  }

  function handleClientDataJur(data: any, file: any) {
    setClientDataJur({ ...data, file: file });
  }

  function handleProductData(data: any, file: any) {
    setProductData({ ...data, file: file });
  }

  function handleProfileData(data: any, file: any) {
    setProfileData({ ...data, file: file });
  }

  function handleColabData(data: any, file: any) {
    setColabData({ ...data, file: file });
  }

  function handleGroupData(data: any) {
    setGroupData({ ...data });
  }

  function handleOrderData(data: any) {
    setOrderData({ ...data });
  }

  return (
    <SignedContext.Provider
      value={{
        clientDataFis,
        clientDataJur,
        productData,
        profileData,
        colabData,
        orderData,
        groupData,
        handleClientDataFis,
        handleGroupData,
        handleClientDataJur,
        handleProductData,
        handleProfileData,
        handleColabData,
        handleOrderData,
      }}
    >
      {children}
    </SignedContext.Provider>
  );
};

export function useData() {
  const context = useContext(SignedContext);
  return context;
}
