import { useState } from "react";
import PropTypes from "prop-types";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// material
import { styled } from "@mui/system";
import {
  Toolbar,
  Button,
  Stack,
  Typography,
  OutlinedInput,
} from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  float: "left",
  justifyContent: "space-between",
  padding: theme.spacing(0, 0, 0, 0),
}));

const DatePicker = styled(OutlinedInput)(({ theme }: any) => ({
  height: 48,
  float: "left",
  borderRadius: "40px",
  backgroundColor: "#fff",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

FinancialListToolbar.propTypes = {
  onFilterDate: PropTypes.func,
};

export default function FinancialListToolbar({ onFilterDate }: any) {
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");

  return (
    <RootStyle
      sx={{
        height: ["", 96],
        display: ["block", "flex"],
        marginBottom: [2, 1],
      }}
    >
      <Stack>
        <Typography
          variant="subtitle2"
          color="secondary"
          sx={{ marginLeft: ["8px", "-18px"] }}
        >
          Data inicial:
        </Typography>
        <DatePicker
          sx={{
            width: ["100%", 198],
            marginLeft: [0, "-20px"],
            marginRight: [0, "30px"],
          }}
          type="date"
          value={date1}
          onChange={(element: any) => {
            const value = element.target.value;

            setDate1(value);
            onFilterDate(
              value.split("-").reverse().join("/"),
              date2.split("-").reverse().join("/")
            );
          }}
        />
      </Stack>

      <Stack>
        <Typography
          variant="subtitle2"
          color="secondary"
          sx={{ marginLeft: ["8px", "-18px"], marginTop: ["10px", 0] }}
        >
          Data final:
        </Typography>
        <DatePicker
          sx={{
            width: ["100%", 198],
            marginLeft: [0, "-20px"],
            marginRight: [0, "30px"],
          }}
          type="date"
          value={date2}
          onChange={(element: any) => {
            const value = element.target.value;

            setDate2(value);
            onFilterDate(
              date1.split("-").reverse().join("/"),
              value.split("-").reverse().join("/")
            );
          }}
        />
      </Stack>

      <Button
        sx={{ ml: ["26%", -2], mt: 2 }}
        color="inherit"
        onClick={() => {
          setDate1("");
          setDate2("");
          onFilterDate("", "");
        }}
      >
        <HighlightOffIcon sx={{ color: "#A9A9A9" }} />
        <Typography variant="body2" color="#878787" sx={{ ml: "3px" }}>
          LIMPAR FILTROS
        </Typography>
      </Button>
    </RootStyle>
  );
}
