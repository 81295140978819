/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { Icon } from "@iconify/react";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import OrderModal from "../components/OrderModal";
import plusFill from "@iconify/icons-eva/plus-fill";
import inboxOutline from "@iconify/icons-eva/inbox-outline";
import {
  Card,
  Stack,
  Button,
  Typography,
  CardHeader,
  CardContent,
} from "@mui/material";

import actionService from "../services/actionService";

import {
  Timeline,
  TimelineItem,
  TimelineDot,
  TimelineContent,
  TimelineConnector,
  TimelineSeparator,
  TimelineOppositeContent,
} from "@mui/lab";
import orderService from "../services/orderService";

export default function ActionCard(props: any) {
  const { state }: any = useLocation();
  const [ip, setIp]: any = useState();
  const [data, setData]: any = useState();
  const [query, setQuery]: any = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [actionsDisabled, setActionsDisabled] = useState(false);
  const [warrantyAvailable, setWarrantyAvailable] = useState(false);

  useEffect(() => {
    getSelects();
    orderService.getIp().then((res: any) => {
      if (Array.isArray(res)) setIp([res[0].number_Ip, res[1].number_Ip]);
      else setIp(res);
    });
  }, []);

  useEffect(() => {
    if (state) reloadActions();
    if (state.actions && state.actions.length >= query.length) {
      setQuery(state.actions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (!modalOpen) {
      reloadActions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  useEffect(() => {
    if (Array.isArray(query)) {
      setActionsDisabled(
        query.some(
          (obj: any) =>
            obj?.type === "BAIXA" ||
            obj?.type === "CANCELADA" ||
            obj?.type === "REPROVADA NO RECEBIMENTO"
        )
      );

      setWarrantyAvailable(query.some((obj: any) => obj?.type === "BAIXA"));
    }
  }, [query]);

  const reloadActions = async () => {
    orderService.getActions(props.code_Order).then((res) => {
      setQuery(res.data);
    });
  };

  const getSelects = async () => {
    const reworkData = await actionService.getRework();
    const messageData = await actionService.getMessage();
    setData({ rework: reworkData.data, message: messageData.data });
  };

  return (
    <>
      <OrderModal
        data={data}
        open={modalOpen}
        code_Order={props.code_Order}
        handler={() => setModalOpen(false)}
        setError={(value: boolean) => props.setError(value)}
        setSuccess={(value: boolean) => {
          props.setSuccess(value);
        }}
      />
      <Card sx={{ marginTop: "32px" }}>
        <CardHeader
          sx={{
            background: "#004A78",
            color: "#fff",
            paddingLeft: "30px",
            paddingBottom: "10px",
            paddingTop: "10px",
          }}
          title="Ações"
        />
        <CardContent>
          <Stack
            direction={["column", "row"]}
            justifyContent="flex-start"
            marginBottom={query.length && !!query[0]?.date ? 2 : -2}
          >
            <Button
              sx={{
                height: "48px",
                lineHeight: "18px",
                borderRadius: "40px",
                background: "#004A78",
                "&:hover": {
                  bgcolor: "#226C9A",
                },
              }}
              disabled={(query.length && !query[0]?.date) || actionsDisabled}
              color="secondary"
              variant="contained"
              onClick={() => setModalOpen(true)}
              startIcon={<Icon icon={plusFill} />}
            >
              Nova Ação
            </Button>
            <Button
              sx={{
                height: "48px",
                paddingX: "32px",
                lineHeight: "18px",
                marginTop: ["12px", 0],
                marginLeft: [0, "18px"],
                borderRadius: "40px",
                background: "#004A78",
                "&:hover": {
                  bgcolor: "#226C9A",
                },
              }}
              color="secondary"
              variant="contained"
              disabled={!warrantyAvailable}
              onClick={() => {
                const url = `http://${
                  Array.isArray(ip) ? ip[0] : ip
                }/impressora/?data=${state?.serverRegisterDate_Order}&numero=${
                  state?.code_Order?.split("-")[1]
                }&os=${state?.code_Order.replace("-", "")}&esfericoD=${
                  state?.rightSpherical_Order
                    ?.replace(".", "")
                    .replace(",", "") || ""
                }&cilindricoD=${
                  state?.rightCylindrical_Order
                    ?.replace(".", "")
                    .replace(",", "") || ""
                }&eixoD=${
                  state?.rightAxis_Order?.replace(".", "").replace(",", "") ||
                  ""
                }&adicaoD=${
                  state?.rightAddition_Order
                    ?.replace(".", "")
                    .replace(",", "") || ""
                }&esfericoE=${
                  state?.leftSpherical_Order
                    ?.replace(".", "")
                    .replace(",", "") || ""
                }&cilindricoE=${
                  state?.leftCylindrical_Order
                    ?.replace(".", "")
                    .replace(",", "") || ""
                }&eixoE=${
                  state?.leftAxis_Order?.replace(".", "").replace(",", "") || ""
                }&adicaoE=${
                  state?.leftAddition_Order
                    ?.replace(".", "")
                    .replace(",", "") || ""
                }`;

                window.open(url, "_blank");
              }}
            >
              Gerar Garantia{Array.isArray(ip) ? " (IMPRESSORA 1)" : ""}
            </Button>
            {Array.isArray(ip) ? (
              <Button
                sx={{
                  height: "48px",
                  paddingX: "32px",
                  marginTop: ["12px", 0],
                  marginLeft: [0, "18px"],
                  borderRadius: "40px",
                  background: "#004A78",
                  "&:hover": {
                    bgcolor: "#226C9A",
                  },
                }}
                color="secondary"
                variant="contained"
                disabled={query[query.length - 1]?.type !== "BAIXA"}
                onClick={() => {
                  const url = `http://${ip[1]}/impressora/?data=${
                    state?.serverRegisterDate_Order
                  }&numero=${state?.code_Order?.split("-")[1]}&os=${
                    state?.code_Order
                  }&esfericoD=${
                    state?.rightSpherical_Order
                      ?.replace(".", "")
                      .replace(",", "") || ""
                  }&cilindricoD=${
                    state?.rightCylindrical_Order
                      ?.replace(".", "")
                      .replace(",", "") || ""
                  }&eixoD=${
                    state?.rightAxis_Order?.replace(".", "").replace(",", "") ||
                    ""
                  }&adicaoD=${
                    state?.rightAddition_Order
                      ?.replace(".", "")
                      .replace(",", "") || ""
                  }&esfericoE=${
                    state?.leftSpherical_Order
                      ?.replace(".", "")
                      .replace(",", "") || ""
                  }&cilindricoE=${
                    state?.leftCylindrical_Order
                      ?.replace(".", "")
                      .replace(",", "") || ""
                  }&eixoE=${
                    state?.leftAxis_Order?.replace(".", "").replace(",", "") ||
                    ""
                  }&adicaoE=${
                    state?.leftAddition_Order
                      ?.replace(".", "")
                      .replace(",", "") || ""
                  }`;

                  window.open(url, "_blank");
                }}
              >
                Gerar Garantia (IMPRESSORA 2)
              </Button>
            ) : undefined}
          </Stack>
          {query.length && !!query[0]?.date ? (
            <Timeline sx={{ ml: ["-35%", "-80%"] }}>
              {query.map((item: any, index: number) => {
                let dateTime = item.date.split(" ");

                return (
                  <TimelineItem key={index}>
                    <TimelineOppositeContent>
                      <Typography color="textSecondary">
                        {dateTime[1].split(":").slice(0, -1).join(":")}
                      </Typography>
                      <Typography lineHeight={1} color="textSecondary">
                        {query.length && dateTime[0].replace("/20", "/")}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot
                        sx={{
                          width: "28px",
                          height: "28px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        color="secondary"
                      >
                        <Typography>{index + 1}</Typography>
                      </TimelineDot>
                      {index + 1 !== query.length && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent sx={{ pt: 0.2 }}>
                      <Typography variant="h5">{item.type}</Typography>
                      <Typography variant="body1" lineHeight="12px">
                        {item.description ||
                          (item.nameContinueProcess
                            ? `Autorizado por: ${item.nameContinueProcess}`
                            : "")}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
          ) : (
            <Stack
              paddingY="12px"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Icon icon={inboxOutline} width="56px" color="#909090" />
              <Typography
                mt={1}
                variant="body1"
                noWrap
                sx={{ color: "#909090" }}
              >
                Nada registrado...
              </Typography>
            </Stack>
          )}
        </CardContent>
      </Card>
    </>
  );
}
